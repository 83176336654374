export default {
  namespaced: true,
  state: {
    serialProducts: null, // 只有在当前组件里面用到的数据收敛到组件里面来
  },
  mutations: {
    updateSerialProducts(state, payload) {
      state.serialProducts = payload
    },
  },
  actions: {
    // 套装详情
    getSerialProducts({ commit, rootState, rootGetters }) {
      const serial_no = sessionStorage.getItem('serial_no')
      if (!serial_no) {
        commit('updateSerialProducts', null)
        return
      }
      const fromSwitchColor = rootState.newProductDetail.common.fromSwitchColor
      const getTheLookInfo = rootGetters['newProductDetail/getTheLook']
      const detail = rootState.newProductDetail.coldModules.productInfo.detail || {}

      let item = {}
      const serialProductsCache = JSON.parse(sessionStorage.getItem('serialProductsCache') || '{}')

      if (fromSwitchColor) {
        // 切换颜色不刷新
        item = serialProductsCache
      } else {
        item = getTheLookInfo?.find((item) => item.series_no === serial_no)
        sessionStorage.removeItem('cacheSerialGoodsId')
      }

      if (item) {
        if (item.series_no != serialProductsCache?.series_no) {
          const data = []
          const target = []
          item?.related_goods?.forEach((i) => {
            if (i.goods_id != detail.goods_id) {
              data.push(i)
            } else {
              target.push(i)
            }
          })
          item.related_goods = target.concat(data)
          commit('updateSerialProducts', item)
          sessionStorage.setItem('serialProductsCache', JSON.stringify(item))
        } else {
          commit('updateSerialProducts', serialProductsCache)
        }
      } else {
        commit('updateSerialProducts', null)
        sessionStorage.removeItem('serialProductsCache')
      }
      sessionStorage.removeItem('serial_no')
    },
  },
  getters: {
    serialProductsBase(state, getters, rootState, rootGetters) {
      const { language } = rootState.newProductDetail.coldModules.pageInfo
      const { langPath, GB_cssRight } = rootState.newProductDetail.coldModules.commonInfo
      const { detail = {} } = rootState.newProductDetail.coldModules.productInfo
      const mallCode = rootGetters['newProductDetail/common/mallCode']
      return {
        detail,
        mallCode,
        langPath,
        GB_cssRight,
        language,
      }
    },
    // module_id === 2的数据展示入口提前到LOOKBOOK详情页
    getMatchingStylesInfo(state, getters, rootState, rootGetters) {
      const { IS_RW } = rootState.newProductDetail.coldModules.commonInfo
      if (IS_RW) return []
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      if (!fsAbt?.DetailFamilyOutfits?.param?.DetailFamilyOutfits) return []
      const { getTheLook = [] } = rootState.newProductDetail.hotModules.getTheLook
      if (!getTheLook.length) return []
      let result = []
      getTheLook.forEach((item) => {
        const isNotAllSoldOut = item.related_goods.some((goodItem) => {
          return goodItem?.is_on_sale || goodItem?.stock
        })
        // 主图下搭配受 module_id === 2
        if (item.module_id === 2 && isNotAllSoldOut && result.length <= 3) {
          result.push(item)
        }
      })
      return result
    },
    showSerialProducts(state, getters) {
      const matchingStylesInfo = getters.getMatchingStylesInfo
      const matchingStylesRelatedGoods =
        (matchingStylesInfo.length && matchingStylesInfo[0].related_goods) || []
      return Boolean(
        state.serialProducts?.related_goods?.length || matchingStylesRelatedGoods.length
      )
    },
  },
}
