import schttp from 'public/src/services/schttp'
import abtInfo from './abtInfo'
import DETAIL_API_LOCATION from '../../../api'
import { useItemConfig, EItemConfigType } from 'public/src/pages/goods_detail/RecommendRelatives/ProductConfigManager/index'
import recommendExposureListInstance from 'public/src/pages/goods_detail/utils/recommendExposureList.js'
import { useProductCardUtils } from 'public/src/pages/goods_detail_v2/utils/recommend.js'
import { EWishsimilarType, RecQueryType } from 'public/src/pages/goods_detail_v2/innerComponents/drawer/RecommendDrawer/constants.js'

export default {
  namespaced: true,
  state: {
    combobuy_data: [], // test
    ccc_feed_back: null,
    show_outfit_alone: false,
  },
  getters: {
    ...abtInfo,
    get_the_lookA_info(state, getters, rootState, rootGetters) {
      const outfits_info = rootGetters['productDetail/MainPicture/outfits_info']
      return outfits_info.gtlInfo || {}
    },
    /**
   * 店铺推荐商品id集合, 用,分隔
   * todo_del
   */
    shop_rec_product_ids(state, getters, rootState) {
      return rootState.newProductDetail.common.storeRecommendInfo || ''
    },
    /**
     * 商卡客户端预处理bff依赖数据
     */
    product_bff_depend_data(state, getters, rootState, rootGetters) {
      const product_items_language = rootGetters['productDetail/common/product_items_language']
      const isPaid = !!rootGetters['productDetail/common/is_paid_user']
      const compliance_mode = rootGetters['productDetail/common/compliance_mode']
      const compliance_tips_mode = rootGetters['productDetail/common/compliance_tips_mode']
      const compliance_mode_de = rootGetters['productDetail/common/compliance_mode_de']

      // 当前站点合规配置 'normal', 'special','special_de'
      const suggestedSaleType = (() => {
        if (compliance_mode_de) return 'special_de'
        if (compliance_tips_mode) return 'special'
        if (compliance_mode) return 'normal'
      })()

      const lang = (typeof gbCommonInfo !== 'undefined' && gbCommonInfo.lang) || 'us'

      return {
        lang,
        language: product_items_language,
        isPaid,
        suggestedSaleType,
      }
    },
    // 多属性关联模块
    under_photo_products(state, getters, rootState, rootGetters) {
      let multiPropertyProducts = rootState.productDetail.hotModules.multiPropertyProducts || []
      // return [...mock, ...mock, ...mock, ...mock, ...mock, ...mock, ...mock, ...mock, ...mock]
      const result = multiPropertyProducts?.[0]?.relateSkcAttrList?.slice(0, 30) || []
      return result
    },
    // 多属性关联模块
    under_size_products(state, getters, rootState, rootGetters) {
      let multiPropertyProducts = rootState.productDetail.asyncModules.multiPropertyProducts || []
      // return [...mock, ...mock]
      const result = multiPropertyProducts?.[0]?.multiPropertyProductsList?.slice(0, 30) || []
      return result
    },
    has_multi_property_products(state, getters, rootState, rootGetters){
      return Boolean(getters.under_photo_products?.length > 1 || getters.under_size_products?.length) 
    },
    under_photo_multi_property_products(state, getters, rootState, rootGetters){
      const fsAbt = rootGetters['productDetail/common/fs_abt']
      const abtText = fsAbt?.similarrelate?.param?.similarrelate || 'notshow' 
      // const MAIN_BFF_APOLLO = rootState.productDetail.MAIN_BFF_APOLLO || {}
      // return true
      return Boolean(getters.under_photo_products?.length > 1 && abtText === 'underphoto')
    },
    under_size_multi_property_products(state, getters, rootState, rootGetters){
      const fsAbt = rootGetters['productDetail/common/fs_abt']
      const abtText = fsAbt?.similarrelate?.param?.similarrelate || 'notshow' 
      // const MAIN_BFF_APOLLO = rootState.productDetail.MAIN_BFF_APOLLO || {}
      // return true
      return Boolean(getters.under_size_products?.length && abtText === 'undersize')
    },
    multi_property_products_goodsIds(state, getters, rootState, rootGetters){
      if(getters.under_photo_multi_property_products){
        return getters.under_photo_products.map(item => item.goodsId)
      }else if(getters.under_size_multi_property_products){
        return getters.under_size_products.map(item => item.goods_id)
      }
      return []
    }
  },
  actions: {
    get_ccc_feed_back: async ({ state, commit, rootGetters }) => {
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      // 当实验命中pop时，根据拓展弹窗在CCC配置的内容展示（含仅有找相似、仅有负反馈、同时有找相似和负反馈），如果CCC未配置拓展弹窗，则不展示
      if (fs_abt?.ProductDetailYouMayAlsoLikeExpandPop?.p !== 'pop') return
      // 加载成功不重复请求
      if (state.ccc_feed_back) return state.ccc_feed_back
      try {
        const res = await schttp({
          url: DETAIL_API_LOCATION.CCC_FEED_BACK,
          method: 'GET',
          useBffApi: true,
        })

        const info = res?.info?.extendConfigDetail?.find(item => {
          return item.pageStyleKey === 'page_detail_you_may_also_like' && item.sceneKey === 'TWO_IN_A_ROW' // 详情页推荐 一行二图
        })

        if (info && info.effectTime && info.endTime) {
          const nowTime = Date.parse(new Date()) / 1000
          if (nowTime > info.effectTime && nowTime < info.endTime && ((info.negFeedbackShow == 1 && info.negFeedbackInfo.length > 0) || info.similarShow == 1)) {
            commit('update_ccc_feed_back', info)
            return info
          }
        }

        commit('update_ccc_feed_back', null)
        return null
      } catch(err) {
        console.error('get_ccc_feed_back', err)
        return null
      }
    },
    /**
   * 打开推荐弹窗 
   * @param {*} name 弹窗场景名 (wish-收藏成功弹窗)
   * @param {*} extendConfig
   */
    async open_detail_recommend_drawer({ dispatch, commit, getters, rootGetters }, payload) {
      const [RecommendDrawerInstance, config] = await Promise.all([
        import('public/src/pages/goods_detail_v2/innerComponents/drawer/RecommendDrawer/index.js').then(res => res.default),
        new Promise((resolve) => {
          const { name, extendConfig } = payload || {}
          const _language = rootGetters['productDetail/common/language_v2']
          const product_items_language = rootGetters['productDetail/common/product_items_language']
          const language = {
            ...(_language || {}),
            ...(product_items_language || {})
          }

          const isHitComplianceMode = rootGetters['productDetail/common/is_hit_compliance_mode']
          const goodsId = rootGetters['productDetail/common/goods_id']
          const fsAbt = rootGetters['productDetail/common/fs_abt']
          const productBffDependData = getters.product_bff_depend_data
  
          const wishsimilartype = EWishsimilarType[fsAbt?.wishsimilar?.param?.wishsimilartype] || EWishsimilarType.big
          const _isWishTypeMedium = wishsimilartype === EWishsimilarType.medium
          const _isWishTypeSmall = wishsimilartype === EWishsimilarType.small
  
          const { itemConfig: itemConfigPic2 } = useItemConfig({
            name: 'DetailRecommendDrawerPic2',
            type: EItemConfigType.Pic2,
            mergeConfig: {
              useOwnClickAddBagAnalysis: true,
            }
          })
  
          const getProductItemsConfig = () => {
            if (_isWishTypeMedium) {
              const { itemConfig } = useItemConfig({
                name: 'DetailRecommendDrawerPic3',
                type: EItemConfigType.Pic3,
              })
              return itemConfig.value
            }
            if (_isWishTypeSmall) {
  
              const { itemConfig } = useItemConfig({
                name: 'DetailRecommendDrawerSPic3OnlyPrice',
                type: EItemConfigType.sPic3OnlyPrice,
              })
              return itemConfig.value
            }
            // 一行二图推荐场景
            return itemConfigPic2.value
          }
  
          const productItemsConfig = { ...getProductItemsConfig() }
          const MAX_COUNT = 3
  
          const config = {
            styleType: wishsimilartype,
            goodsId,
            language,
            showQuickAddEstimatedPrice: !isHitComplianceMode,
            clearRepeatArr: recommendExposureListInstance.getList(),
            productItemsConfig,
            productItemsListConfig: { ...itemConfigPic2.value },
            addCartCallback: {
              handleChangeLocalCountry: (value) => {
                // 数据联动控制
                commit('newProductDetail/common/updateCurrentLocalCountry', value)
              }
            },
            ...(extendConfig || {}),
          }

          if (name === 'wish') {
            config.listAnalysisData = {
              'active-from': 'collection_success',
              poskey: 'SimilarItem',
            }
  
            config.productItemsConfig.sa = config.productItemsListConfig.sa = {
              activity_from: 'collection_success'
            }
  
            if (typeof sessionStorage !== 'undefined') {
              const _KEY = 'productDetail_addWishRecommendCount'
              const resetCountFn = () => {
                sessionStorage.setItem(_KEY, 0)
              }
              const count = Number(sessionStorage.getItem(_KEY))
              if (count >= MAX_COUNT) {
                resolve(null)
              }
              sessionStorage.setItem(_KEY, count + 1)
  
              config.openQuickAfter = resetCountFn
              config.clickItemAfter = resetCountFn
              config.clickViewMoreAfter = resetCountFn
              config.clickViewAllAfter = resetCountFn
            }

            // 数据处理
            config.integrateFetchRecommend = async (params) => {
              const { similarItemRecProducts = [], hasNextPage = '0' } = await dispatch('get_recommend_collect_similar', {
                queryType: RecQueryType.similarItem,
                ...params
              })
              
              // BFF 商卡数据转换
              const { goodsTransformationProductsWithSetup } = useProductCardUtils(productBffDependData)
              const products = goodsTransformationProductsWithSetup(similarItemRecProducts) || []

              return {
                products,
                hasNextPage
              }
            }
          }
          resolve(config)
        })
      ])
      const { name } = payload || {}
      if (!config) return false
  
      return RecommendDrawerInstance.open(name, config)
    },
    /**
     * 商详收藏后弹出similar推荐
     * Doc: post_product_detail_recommend_collect_similar/doc
     */
    get_recommend_collect_similar: ({ rootGetters }, { queryType, pageNum = 1, limit = 20 }) => {
      return new Promise(resolve => {
  
        const goodsId = rootGetters['productDetail/common/goods_id'] || ''
        const cateId = rootGetters['productDetail/common/cat_id'] || ''
        const fs_abt = rootGetters['productDetail/common/fs_abt'] || ''
  
        const { rec_ver_s143_match = '', rec_ver_s143_rank = '', rec_ver_s143_rerank = '' } = fs_abt?.SimilarItem?.p || {}
  
        const params = {
          abtParamsSimilarItem: {
            rec_ver_s143_match,
            rec_ver_s143_rank,
            rec_ver_s143_rerank,
          },
          pageQueryParam: {
            page: pageNum,
            limit,
            queryType,                          // 查询类型 1-similarItem 2-组合购 3-oftenBoughtWith 4-quickShip推荐
          },
          attribute: '',
          filterCateId: '',
          cateId,
          goodsId,
          isAddCart: '0',                       // 是否加车 
          isOnlyNeedProductCardPrice: '0',      // 是否只需要商卡价格 1：是。其他否
        }
  
        schttp({
          url: DETAIL_API_LOCATION.RECOMMEND_COLLECT_SIMILAR,
          method: 'POST',
          data: params,
          useBffApi: true
        }).then(({ info }) => {
          return resolve(info || {})
        })
      })
    },
  },
  mutations: {
    update_show_outfit_alone(state, payload = false) {
      state.show_outfit_alone = payload
    },
    update_ccc_feed_back(state, payload) {
      state.ccc_feed_back = payload
    }
  },
}
