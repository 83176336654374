import { promotionCenter } from 'public/src/pages/common/promotion'
import schttp from 'public/src/services/schttp'
import recommendExposureListInstance from 'public/src/pages/goods_detail/utils/recommendExposureList.js'
import { abtUserAnalysis } from '@shein-aidc/basis-abt-router'
import { detailTPMHandle } from '../utils/detailTPM.js'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { getDetailRecPreTreat } from 'public/src/pages/goods_detail/utils/productDetail.js'
import { cloneDeep } from 'lodash'
import { Toast } from '@shein/sui-mobile'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getComboBuyRecommendList, MIN_COMBOBUY_RECOMMEND_NUM } from 'public/src/pages/goods_detail/RecommendRelatives/ComboBuy/utils.js'
import { getMainGoodsPrice } from 'public/src/pages/goods_detail/RecommendRelatives/CommonCart/utils.js'
// import { extendsComplianceModeConfig } from 'public/src/pages/goods_detail/RecommendRelatives/constants.js'
import { useItemConfig, EItemConfigType } from 'public/src/pages/goods_detail/RecommendRelatives/ProductConfigManager/index'
import { emitClickProductCardInGoodsDetail } from 'public/src/pages/mounting/eventBus/eventLib/eventEmit.js'
import { openAddonItemDrawer } from 'public/src/pages/goods_detail/RecommendRelatives/AddBagRec/utils.js'
import { getQueryString, template } from '@shein/common-function'
import pageContextCallBack from 'public/src/pages/goods_detail/utils/pageContextCallBack.js'
import switchColorCache from 'public/src/pages/goods_detail/utils/switchColorCache'
import { getNewCompanionConfig } from 'public/src/pages/product_app/store/modules/product_detail/utils/getNewCompanionConfig'

const RECOMMEND_SCENE_ID_MAP = new Map([
  [143, 'SimilarItem'],
  // [144, 'FrequentBoughtTogether'],
  [147, 'PersonalizedRecommend'],
  [152, 'addnewhotJson'],
  [131, 'ProductDetailRecommend'],
  [255, 'addbagpopupgoods'],
  [256, 'addbagpopupcates'],
])

export default {
  async fetchPromotionWithCenter({ rootState, commit, rootGetters }) {
    let skcPromotionInfo = rootGetters['newProductDetail/common/promotionInfo'] || []
    let skuAllInfo = cloneDeep(rootGetters['newProductDetail/SizeBox/skuMapCompose']?.skuAllInfo || {})
    let promotionInfo = cloneDeep(skcPromotionInfo)
    // 合并 skc 和 sku 活动给 promotion center 处理
    if (Object.keys(skuAllInfo).length) {
      let temp1 = Object.values(skuAllInfo).map(item => item.promotionInfo)
      let skuPromotionInfo = []
      for (let i of temp1) {
        for (let j of i) {
          if (!skuPromotionInfo.find(item => item.id === j.id )) {
            skuPromotionInfo.push(j)
          }
        }
      }
      skuPromotionInfo.forEach(item => {
        if (!promotionInfo.find(item2 => item2.id === item.id)) promotionInfo.push(item)
      })
    }

    if (!promotionInfo.length) {
      commit('updatePromotionInfoWithCenter', [])
      return
    }
    const detail = rootState.newProductDetail.coldModules.productInfo?.detail || {}
    const currentMallDetail = rootGetters['newProductDetail/common/currentMallDetail']
    const { goods_id, goods_sn } = detail
    if (!goods_id) return
    const { items } = await promotionCenter.assignItems({
      items: [
        {
          ...currentMallDetail,
          promotionInfo,
          goods_id,
          goods_sn
        }
      ],
      isGetVipPrice: true,
      isGetNewFlashPromotion: true,
      scence: 'detail',
      caller: 'GoodsDetail'
    })
    commit('updatePromotionInfoWithCenter', items[0]?.promotionModule.rawModule || [])
  },
  async fetchPdeGetTheLookData ({ rootState, commit, rootGetters, state }, payload = {}) {
    const hideOldGtlAndOutfit = rootGetters['newProductDetail/common/hideOldGtlAndOutfit']
    if (hideOldGtlAndOutfit) return
    const { noUpdateRecommend = false } = payload || {}
    // 1. 站外商详不请求 + TSP = 60002672 不请求 + abt不展示推荐位不请求
    const { isOutSite } = rootState.newProductDetail?.common || {}
    const tsp = rootState.newProductDetail.coldModules.tsp?.tsp || {}
    if (isOutSite || tsp['60002672']) {
      commit('updatePdeGetTheLookData', {})
      return 
    }
    const { productInfo } = rootState.newProductDetail.coldModules
    const { goods_sn: sku, goods_id, cat_id: cate_id, goods_img } = productInfo?.detail || {}
    const data = { sku, goods_id, cate_id, goods_img }
    const result = await schttp({ 
      url: '/api/productInfo/getPdeGetTheLook/get',
      method: 'post',
      data
    })
    if (noUpdateRecommend && state.getTheLookPdeInfo?.length) return
    commit('updatePdeGetTheLookData', result?.nums ? result : {})
  },
  async fetchRelationGoodsWithCenter({ rootState, commit }) {
    const relationGoods = rootState.newProductDetail.coldModules.productInfo?.relationGoods || []
    if (!relationGoods.length) return commit('updateRelationGoodsWithCenter', [])
    let { items } = await promotionCenter.assignItems({
      items: relationGoods,
      isGetLable: true,
      scence: 'detail'
    })
    commit('updateRelationGoodsWithCenter', items)
  },
  /**
   * @name getStoreRecommendInfo 获取 店铺/品牌/系列 推荐商品 （根据店招获取 店铺或品牌或系列 的商品）
   */
  async getStoreRecommendInfo({ state, commit, rootGetters }, payload = {}) {
    const { noUpdateRecommend = false } = payload || {}

    /**
     * 请求过不更新
     */
    if (noUpdateRecommend && state.storeBrandSeriesRecommendGroup?.loaded) return

    const signBoardStyle = rootGetters['newProductDetail/common/signBoardStyle']
    const seriesMapInfo = rootGetters['newProductDetail/common/seriesMapInfo']
    const brandMapInfo = rootGetters['newProductDetail/common/brandMapInfo']
    const pageCommonInfo = rootGetters['newProductDetail/common/pageCommonInfo']
    const isRWSeriesRecommend = pageCommonInfo.IS_RW || !seriesMapInfo?.isSeriesRecommend
    const fsAbt = rootGetters['newProductDetail/fsAbt'] || {}

    const style = signBoardStyle?.style || 'default'
    if (style == 'default') return
    // 获取店铺露出商品
    const { cat_id = '', goods_id = '' } = state.coldModules.productInfo.detail || {}
    const mall_code = rootGetters['newProductDetail/common/mallCode'] || ''

    const { ymalshopseriesbrand } = fsAbt
    // is_pde=3&rule_id_122=rec_ver:S122M2.0.5
    const ruleResult = (ymalshopseriesbrand?.param || '').match(/rule_id_(\d+)=([^&]+)/)
    const ruleId = ruleResult?.[2]?.split(',')?.[0]
    // 统一请求数量，在页面去截取数量
    const limit = 20
    const params = {
      rule_id: ruleId,
      cat_id,
      mall_code,
      goods_id,
      limit
    }

    /**
     * 请求数据
     */
    const { storeCode } = state.coldModules.store.storeInfo || {}
    if (storeCode) {
      params.store_code = storeCode
      
      // 是否请求是闪购推荐数据
      const isStoreBusinessBrand = rootGetters['newProductDetail/common/isStoreBusinessBrand']
      const isInFashionStore = rootGetters['newProductDetail/common/isInFashionStore']
      const isQualityStore = rootGetters['newProductDetail/common/isQualityStore'] // 是否是品质店铺
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      let storeflashAbt = fsAbt?.storeflash?.p?.storeflash !== 'none' && !!fsAbt?.storeflash?.p?.storeflash
      if((isStoreBusinessBrand || isInFashionStore || isQualityStore) && storeflashAbt){
        params.is_flash_sale = 1
        params.is_flash_sale_top = fsAbt?.storeflash?.p?.flashfirst === 'first' ? 1 : 0
      }
    }
    params.brand_id = brandMapInfo?.uid || ''
    params.series_id = seriesMapInfo?.uid || ''
    params.series_group_id = seriesMapInfo?.series_group_id || ''

    const { code, data } = await schttp({ url: '/api/productInfo/signList/get', params })

    if (code !== 0) return

    const _data = {
      loaded: true,
      store: undefined,
      brandSeries: undefined,
    }

    let _preLoadProducts = []
    if (storeCode) {
      const { products, recommendFilterGoodsIds, recommendAdp } = data?.store_list || {}
      _preLoadProducts = products?.length ? _preLoadProducts.concat(products) : _preLoadProducts
      _data.store = {
        listType: 'store',
        boardList: products,
        recommendFilterGoodsIds,
        recommendAdp,
        storeFlashSaleInfo: data?.storeFlashSaleInfo || {}
      }
    }

    const { brand_list, series_list, series_group_list } = data || {}
    let listType = ''
    if (brand_list?.products?.length && isRWSeriesRecommend) {
      listType = 'brand'
    } else if (series_group_list?.products?.length) {
      listType = 'series_group'
    } else if (series_list?.products?.length) {
      listType = 'series'
    }

    if (listType) {
      // 推荐有数据
      const {
        products = [],
        recommendFilterGoodsIds,
        recommendAdp
      } = listType === 'brand' ? brand_list : listType === 'series_group'
        ? series_group_list
        : series_list

      _data.brandSeries = {
        listType,
        boardList: products,
        recommendFilterGoodsIds,
        recommendAdp
      }
      _preLoadProducts = products?.length ? _preLoadProducts.concat(products) : _preLoadProducts
    }

    if (_preLoadProducts.length) {
      await getDetailRecPreTreat({
        products: _preLoadProducts
      })
    }

    commit('updateStoreBrandSeriesRecommendGroup', _data)
  },
  async openDetailRecommendDrawer({ getters, dispatch, commit, rootGetters }, payload) {
    const [RecommendDrawerInstance, config] = await Promise.all([
      import('public/src/pages/goods_detail/components/drawer/innerComponents/RecommendDrawer/index.js').then(res => res.default),
      new Promise((resolve) => {
        const { name, extendConfig } = payload || {}
        const language = getters['common/language'] || {}
        const productItemsLanguage = getters['common/productItemsLanguage'] || {}
        const estimatedDrawerInfo = getters['common/estimatedDrawerInfo']
        const isHitComplianceMode = rootGetters['newProductDetail/common/isHitComplianceMode']
        const goodsId = getters['common/goodsId']
        const cateNodeId = getters['common/cateNodeId']
        const fsAbt = rootGetters['newProductDetail/fsAbt']

        const _language = {
          ...productItemsLanguage,
          ...language,
        }

        const EWishsimilarType = {
          /**
           * 原来的大半屏弹窗
           * */
          'big': 'big',
          /**
           * 一行商卡样式
           */
          'medium': 'medium',
          /**
           * 一行小图样式
           */
          'small': 'small',
        }

        const wishsimilartype = EWishsimilarType[fsAbt?.wishsimilar?.param?.wishsimilartype] || EWishsimilarType.big
        // const wishsimilartype = EWishsimilarType.small
        // const wishsimilartype = EWishsimilarType.medium
        // const wishsimilartype = EWishsimilarType.big

        // const _isWishTypeBig = wishsimilartype === EWishsimilarType.big
        const _isWishTypeMedium = wishsimilartype === EWishsimilarType.medium
        const _isWishTypeSmall = wishsimilartype === EWishsimilarType.small


        const { itemConfig: itemConfigPic2 } = useItemConfig({
          name: 'DetailRecommendDrawerPic2',
          type: EItemConfigType.Pic2,
          mergeConfig: {
            useOwnClickAddBagAnalysis: true,
          }
        })

        const getProductItemsConfig = () => {
          if (_isWishTypeMedium) {
            const { itemConfig } = useItemConfig({
              name: 'DetailRecommendDrawerPic3',
              type: EItemConfigType.Pic3,
            })
            return itemConfig.value
          }
          if (_isWishTypeSmall) {

            const { itemConfig } = useItemConfig({
              name: 'DetailRecommendDrawerSPic3OnlyPrice',
              type: EItemConfigType.sPic3OnlyPrice,
            })
            return itemConfig.value
          }
          // 一行二图推荐场景
          return itemConfigPic2.value
        }

        const productItemsConfig = { ...getProductItemsConfig() }

        // const recommendConfig = { // 推荐个性化配置，在服务端用的
        //   hideSalesLabel: isHitComplianceMode
        // }

        const MAX_COUNT = 3

        const integrateFetchRecommend = async (params) => {
          return await dispatch('integrateFetchRecommend', params)
        }
        const isNewSizeLocal = getters['common/isNewSizeLocal']

        // if (!productItemsConfig.showDetailRecNewCard && isHitComplianceMode) {
        //   extendsComplianceModeConfig(productItemsConfig)
        // }

        const config = {
          styleType: wishsimilartype,
          goodsId,
          language: _language,
          showQuickAddEstimatedPrice: !isHitComplianceMode,
          clearRepeatArr: recommendExposureListInstance.getList(),
          integrateFetchRecommend,
          productItemsConfig,
          productItemsListConfig: { ...itemConfigPic2.value },
          addCartCallback: {
            handleChangeLocalCountry: (value) => {
              // 数据联动控制
              isNewSizeLocal && commit('common/updateCurrentLocalCountry', value)
            }
          },
          ...(extendConfig || {}),
        }
        if (name === 'addCart') {
          config.extendInfo = estimatedDrawerInfo || {}
          config.recommendConfig = estimatedDrawerInfo?.showEstimatedAddMore ? {
            sceneId: 152,
            limit: 40,
            maxNum: 100,
            extraParams: {
              add_on_type: 2,
              // 过滤售罄商品
              filterSoldOutConfig: {
                on: true,
              }
            },
            extraContextParams: {
              include_tsp_id: estimatedDrawerInfo?.include_tsp_id || '',
              exclude_tsp_id: estimatedDrawerInfo?.exclude_tsp_id || '',
            }
          } : {
            sceneId: 131,
            node_id: cateNodeId,
            limit: 40,
            maxNum: 100,
            extraContextParams: {
              // 重叠类目id暂不接入，传空值
              overlap_id: '',
            }
          }
          config.productItemsConfig.sa = {
            activity_from: 'addcart_success'
          }
          config.listAnalysisData = {
            'active-from': 'addcart_success',
            poskey: estimatedDrawerInfo?.showEstimatedAddMore ? 'addnewhotJson' : 'ProductDetailRecommend',
            'is-add-more': estimatedDrawerInfo?.showEstimatedAddMore ? 1 : 0,
          }

          if (typeof sessionStorage !== 'undefined') {
            const _KEY = 'productDetail_addCartRecommendCount'
            const resetCountFn = () => {
              sessionStorage.setItem(_KEY, 0)
            }
            const count = Number(sessionStorage.getItem(_KEY))
            if (count >= MAX_COUNT) {
              resolve(null)
            }
            sessionStorage.setItem(_KEY, count + 1)

            config.openQuickAfter = resetCountFn
            config.clickItemAfter = resetCountFn
          }
        }else if (name === 'wish') {
          config.listAnalysisData = {
            'active-from': 'collection_success',
            poskey: 'SimilarItem',
          }

          config.productItemsConfig.sa = config.productItemsListConfig.sa = {
            activity_from: 'collection_success'
          }

          if (typeof sessionStorage !== 'undefined') {

            const _KEY = 'productDetail_addWishRecommendCount'
            const resetCountFn = () => {
              sessionStorage.setItem(_KEY, 0)
            }
            const count = Number(sessionStorage.getItem(_KEY))
            if (count >= MAX_COUNT) {
              resolve(null)
            }
            sessionStorage.setItem(_KEY, count + 1)

            config.openQuickAfter = resetCountFn
            config.clickItemAfter = resetCountFn
            config.clickViewMoreAfter = resetCountFn
            config.clickViewAllAfter = resetCountFn
          }
        }
        resolve(config)
      })
    ])
    const { name } = payload || {}
    if (!config) return false

    return RecommendDrawerInstance.open(name, config)
  },
  initStoreFollowInfo({ commit, state }) {
    const { store } = state.hotModules
    const { storefollowCount, storeFollowState } = store || {}
    // const { fsAbt } = pageInfo || {}
    // const fsAbt = rootGetters['newProductDetail/fsAbt'] || {}
    // const localStoreInfo = state.coldModules.store?.storeInfo || {}
    // if (!localStoreInfo?.storeCode) return
    // 店铺关注信息回来后更新状态
    // commit('common/updateStoreAndBrandDataReady', true)
    // 不满足abt return
    // if (fsAbt?.storewishlist?.param?.storewishlist !== 'show') return
    const { quantity } = state.coldModules.store || {}
    commit('common/updateStoreFollowInfo', {
      // abt命中才展示
      show: true,
      // 用户关注状态是否变更中
      followStateChanging: false,
      // 店铺关注数量
      followCount: storefollowCount,
      // 店铺30日销量（tsp）
      salesVolume: quantity,
      // 用户关注状态，-1: 未登录，0: 未关注，1: 已关注
      userFollowState: storeFollowState?.info?.result === 1 ? 1 : 0,
      // 埋点信息
      abtAnaInfo: abtUserAnalysis({ posKeys: 'storewishlist' })?.sa,
    })
  },
  markTopContentAsReady({ commit, dispatch }) {
    commit('common/updateTopContentReady', true)
    // dispatch('goodsReadyCallBack')
    if (typeof window !== 'undefined') {
      Vue.nextTick(() => {
        requestAnimationFrame(() => {
          switchColorCache.flushBuffer()
        })
      })
    }
  },
  goodsReadyCallBack({ commit, state, rootGetters, rootState }) {
    if (typeof window !== 'undefined') {
      const timer = setTimeout(() => {
        clearTimeout(timer)
        if (typeof appEventCenter !== 'undefined') {
          appEventCenter.$emit('pageOnload', {
            isFullReload: !state.common?.fromSwitchColor,
            // 可能有bug,溯源暂时没发现有引用到json的地方
            json: {},
            noSaPv: true
          })
          // 可能有bug,溯源暂时没发现有引用到json的地方
          appEventCenter.$emit('detailFetchMoreInfo')
          appEventCenter.$emit('detailPageOnload', { json: {} })
        }
        commit('newProductDetail/common/updateDetailPageOnloadStatus', true, { root: true })
        commit('newProductDetail/common/updateDetailFetchMoreInfoStatus', true, { root: true })
        const asyncScrollStatus = rootState.newProductDetail.common?.asyncScrollStatus
        !asyncScrollStatus && commit('changeRootSLoadingStatus', { show: false }, { root: true })
        commit('imgLazyload', {}, { root: true })
        const { metaInfo = {} } = state.coldModules.pageInfo || {}
        commit('changeSeo', metaInfo, { root: true })
        const detail = rootGetters['newProductDetail/common/detail']
        const mallCode = rootGetters['newProductDetail/common/mallCode']
        const { currency = '' } = state.coldModules.commonInfo || {}
        // TPM事件
        detailTPMHandle({ detail, mallCode, currency })
        pageContextCallBack.get('changeColorReady')?.forEach?.(fn => fn())
        pageContextCallBack.clear('changeColorReady')
      })
    }
  },
  // 首次加载数据完成后的回调
  allDataReadyCallBack({ dispatch, rootGetters, rootState }) {
    if (typeof window === 'undefined') return
    const showNewGtlAndOutfit = rootGetters['newProductDetail/common/showNewGtlAndOutfit']
    const getTheLookInfo = rootGetters['newProductDetail/getTheLook']
    const { recommend } = rootState.newProductDetail.coldModules || {}
    const { outfitsImg } = recommend || {}
    // 加载新搭配购推荐数据
    dispatch('getNewGtlInfo')
    if (!showNewGtlAndOutfit && outfitsImg?.outfitImgUrlFail) {
      daEventCenter.triggerNotice({
        daId: '1-6-1-195'
      })
    }
  },
  // 后续场景需要更新realTime时执行的回调
  realTimeUpdateCallBack({ dispatch, state }) {
    if (!state.common.realTimeFirstReady) return
    // 加载新搭配购推荐数据
    dispatch('getNewGtlInfo')
  },
  // 列表跳商详，给列表回传的数据
  handleSendListData({ rootGetters }) {
    if(window.appRouteExtData?.direction === 0) {
      const detail = rootGetters['newProductDetail/common/detail']
      const { goods_id, cat_id } = detail
      emitClickProductCardInGoodsDetail({
        goods_id,
        cat_id
      })
    }
  },
  // 商详页推荐位统一初始化方法
  // * 对于推荐位布局的依赖有前置要求，涉及state中的属性和关联的getter属性
  // * unifiedRecommend
  async initRecommendRelativesInfo({ dispatch, commit, rootState, rootGetters, state }, payload = {}) {
    const { noUpdateRecommend = false } = payload || {}
    const recommends = []
    const { isOutSite } = rootState.newProductDetail?.common || {}
    const relation_goods = rootGetters['newProductDetail/common/relationGoods'] || {}
    const showSimilarItems = rootGetters['newProductDetail/common/showSimilarItems']
    // const showFrequentlyBought = rootGetters['newProductDetail/common/showFrequentlyBought']
    // const treatOtherOptionAsRecommend = rootGetters['newProductDetail/common/treatOtherOptionAsRecommend']
    // 站外商品不请求推荐位数据
    if (isOutSite) return

    // 0. Other Options / Matching Styles
    // 首先在去重列表中加入 relation_goods 商品
    await dispatch('nonRepeatRecPrdEntry', {
      sceneId: 'relation_goods',
      list: relation_goods,
      leastRetain: 0,
    })

    // 1. Similar Items
    const SimilarItemsSceneId = 143
    if (showSimilarItems) {
      recommends.push({
        sceneId: SimilarItemsSceneId,
        pageNum: 1,
        threeListStyle: true,
        extraParams: {
          filterSoldOutConfig: {
            on: true,
          },
        }
      })
    }

    // // 2. Frequent Bought Together
    // // * 某些abt实验分支下不请求数据直接隐藏组合购模块
    // const FrequentlyBoughtSceneId = 144
    // if (showFrequentlyBought) {
    //   recommends.push({
    //     sceneId: FrequentlyBoughtSceneId,
    //     pageNum: 1,
    //     threeListStyle: true,
    //   })
    // }

    const recommendsData = await dispatch(
      'integrateFetchRecommend',
      { 
        recommends,
      }
    )
    // const recommendsData = await dispatch('integrateFetchRecommend', { recommends }, { root: true })
    if (!recommendsData) return
    /**
     * return data process
     */
    // 1. Similar Items
    if (showSimilarItems) {
      const similarItemsResult = recommendsData[SimilarItemsSceneId]?.info?.products
      const similarItemsRecommend = Array.isArray(similarItemsResult)
        ? similarItemsResult
        : []
      // 已存在的推荐位数据不刷新
      if (!(noUpdateRecommend && state.common?.similarItemsRecommend?.length)) {
        commit('newProductDetail/common/updateSimilarItemsRecommend', similarItemsRecommend, { root: true })
      }
    }
  },
  // 打开加车推荐弹窗
  async openAddBagRecommendDrawer({ getters, dispatch, rootGetters, commit, state, rootState }, payload) {
    const { isDirect, isReview } = payload || {}
    const addBagRecommendConfig = rootGetters['newProductDetail/common/addBagRecommendConfig']
    const carouse_data = rootGetters['productDetail/MainPicture/carouse_data']  
    const carouselsInfo = rootGetters['newProductDetail/Carousels/carouselsInfo']
    const soldOutTips = rootGetters['newProductDetail/SaleAttrsFold/soldOutTips']
    const sizeStockTips = rootGetters['newProductDetail/SaleAttrsFold/sizeStockTips']
    const fsAbt = rootGetters['newProductDetail/fsAbt'] || {} 
    const { addbagRecQuickShow, addbagRecAllShow, addbagRecStyleOne, addbagRecStyleFour } = addBagRecommendConfig
    // 不展示加车后弹窗推荐 时机不满足
    if(!((addbagRecQuickShow && !isDirect) || addbagRecAllShow)) return false
    const fetchStatus = await state.common.fetchRecDataPromiseStatus
    if(fetchStatus === 'failure') {
      return false
    }
    const { detail } = rootState.newProductDetail.coldModules.productInfo || {}
    const { goods_id, cat_id } = detail


    const couponInfos = state.common.bffCouponInfo || []
    const noMeetCouponItems =  couponInfos.filter(item=>item.meetHigh == 0)
    const mainCouponItem = noMeetCouponItems?.[0]
    // 下滑ymal场景
    const cbSetGoodsDetailScrollFn = () => { dispatch('common/setGoodsDetailScroll') }

    let resetCountFn = () => {}
    if (typeof sessionStorage !== 'undefined' && !addbagRecStyleFour) {
      const _KEY = 'productDetail_addBagRecCount'
      resetCountFn = () => {
        sessionStorage.setItem(_KEY, 0)
      }
      const count = Number(sessionStorage.getItem(_KEY))
      if (count >= 3) {
        return false
      }
      sessionStorage.setItem(_KEY, count + 1)
    }
    const inventoryInfo = {
      soldOutTips,
      sizeStockTips,
      fsAbt,
      carouseData: carouse_data,
      carouselsInfo: carouselsInfo,
      addbagRecStyleOne
    }
    // 样式一 打开凑单弹窗 
    if(addbagRecStyleOne) {
      if(!mainCouponItem) return false
      const couponCodes = noMeetCouponItems.map(item=>item.couponCode)
      const queryInfo = {
        addOnType: 2,
        sceneId: 152, // 推荐场景id
        goodsIds: [goods_id],
        cateIds: [cat_id],
      }
      openAddonItemDrawer({
        mainCouponCode: couponCodes[0] || '',
        queryInfo,
        isReview,
        resetCountFn,
        cbSetGoodsDetailScrollFn,
        inventoryInfo,
        fsAbt,
        secondaryCoupon: couponCodes.slice(1)
      })
      return true
    } else if(addbagRecStyleFour){
      // 样式四（打开getTheLook）
      setTimeout(() => {
        dispatch('openGetTheLookNewPopup', { initCateTab: '', activeFrom: 'addbagoutfit' })
      }, 1000)
      return true
      
    } else {  // 样式二 样式三   拿交易中间层返回的物料给推荐
      // 请求商品列表
      const cccFeedback = await dispatch('productDetail/Recommend/get_ccc_feed_back', null, { root: true })
      const listStatus = await dispatch('getAddBagListData', { mainCouponItem })
      if(!listStatus) return false
      const addBagRecData = rootGetters['newProductDetail/common/getAddBagRecData']
      const isPaidUser = rootGetters['newProductDetail/isPaidUser']
      const [AddBagRecDrawerInstance, config] = await Promise.all([
        import('public/src/pages/goods_detail/RecommendRelatives/AddBagRec/main.js').then(res => res.default),
        new Promise((resolve) => {
          const language = getters['common/language'] || {}
          const productItemsLanguage = getters['common/productItemsLanguage'] || {}
          const { itemConfig } = useItemConfig({
            name: 'DetailRecommendDrawerPic2',
            type: EItemConfigType.Pic2,
            mergeConfig: {
              useOwnClickAddBagAnalysis: true,
              showFeedback: !!cccFeedback, // 展示负反馈
            }
          })
          // 请求推荐的方法
          const integrateFetchRecommend = async (params) => {
            return await dispatch('integrateFetchRecommend', params)
          }
          const isHitComplianceMode = rootGetters['newProductDetail/common/isHitComplianceMode'] 
          let config = {
            language,
            productItemsLanguage,
            addBagRecData,
            clearRepeatArr: recommendExposureListInstance.getList(),
            integrateFetchRecommend,
            isHitComplianceMode,
            addBagRecommendConfig,
            resetCountFn,
            cbSetGoodsDetailScrollFn,
            mainCouponItem,
            couponInfo: {
              goods_id,
              cat_id,
              mainCouponCode: mainCouponItem?.couponCode || '',
            },
            inventoryInfo,
            fsAbt,
            itemConfigPic2: { ...itemConfig.value },
            cccFeedback,
            isPaidUser,
            recommendConfig: {
              limit: 20,
              maxNum: 500,
              extraContextParams: {
                include_tsp_id: mainCouponItem?.recommendInfo?.directTag || '',
                exclude_tsp_id: mainCouponItem?.recommendInfo?.returnTag || '',
              }
            }
          }
          resolve(config)
        })
      ])
      if (!config) return false
      AddBagRecDrawerInstance.open(config)
      return true
    }
  },
  showOutfitContent({ state, rootGetters, commit, dispatch }) {
    const addBagRecommendConfig = rootGetters['newProductDetail/common/addBagRecommendConfig']
    const { addbagOutfitAlone, addbagOutfitInYmal } = addBagRecommendConfig
    const outfits_info = rootGetters['productDetail/MainPicture/outfits_info']
    const isHasOutfits = outfits_info.isHasOutfits && !!outfits_info.gtlInfo
    if(isHasOutfits){
      if(addbagOutfitInYmal){
        commit('productDetail/MainPicture/update_show_outfit_ymal', true, { root: true })
        console.log('===> 展示YMAL插坑outfit卡片')
      }else if(addbagOutfitAlone){
        console.log('===> 展示YMAL上方插入outfitd模块')
        commit('productDetail/Recommend/update_show_outfit_alone', true, { root: true })
      }
    }
  },
  async fetchRecDataActions({ state, rootGetters, commit, dispatch }) {
    const addBagRecommendConfig = rootGetters['newProductDetail/common/addBagRecommendConfig']
    const detail = rootGetters['newProductDetail/common/detail']
    const { goods_id } = detail
    const addOnitemGoodsId = state.common.addOnitemGoodsId
    const isSameGoods = goods_id === addOnitemGoodsId
    const { addbagRecStyleOne, addbagRecStyleFour } = addBagRecommendConfig
    let fetchRecDataPromise = null
    if(addbagRecStyleOne){ // 只有样式一
      fetchRecDataPromise = Promise.all([
        dispatch('getBffCouponInfo', { addbagRecStyleOne: true })
      ])
    } else if(addbagRecStyleFour) {
      const outfits_info = rootGetters['productDetail/MainPicture/outfits_info']
      const isHasOutfits = outfits_info.isHasOutfits && !!outfits_info.gtlInfo
      // todo
      if(isHasOutfits){
        commit('newProductDetail/common/updateFetchRecDataPromiseStatus', Promise.resolve('success'), { root: true })
      }else{
        commit('newProductDetail/common/updateFetchRecDataPromiseStatus', Promise.resolve('failure'), { root: true })
      }
      return
    } else{
      fetchRecDataPromise = Promise.all([
        dispatch('getBffCouponInfo', { addbagRecStyleOne: false }),
        dispatch('fetchAddBagOfbwCatData', { isSameGoods }),
        dispatch('fetchAddBagOfbwListData', { isSameGoods })
      ])
    }
    commit('newProductDetail/common/updateFetchRecDataPromiseStatus', fetchRecDataPromise, { root: true })
    const fiveSecondLimit = new Promise((resolve) => {  
      // 超过5秒fetchRecDataPromise 还没返回，则直接不弹窗了
      setTimeout(()=>{ resolve('failure') }, 5000)
    }) 
    const res = await Promise.race([fiveSecondLimit, fetchRecDataPromise])
    if(res === 'failure'){
      commit('newProductDetail/common/updateFetchRecDataPromiseStatus', Promise.resolve('failure'), { root: true })
    }
  },
  async getBffCouponInfo({ commit, rootGetters, dispatch }, { addbagRecStyleOne, }) {
    const { currency, appLanguage } = window?.gbCommonInfo || {}
    const mainCode = getQueryString({ key: 'mainCode' }) || ''
    const mainCouponCode = mainCode || rootGetters['newProductDetail/common/mainCouponCode']
    const secondaryCoupon = rootGetters['newProductDetail/common/secondaryCoupon']
    const subCouponCodes = secondaryCoupon.map(item=>item.coupon).join(',')
    if(!mainCouponCode && !subCouponCodes) return false
    try {
      const bffCouponInfo = await schttp({
        method: 'POST',
        url: '/coupon/addItemInfo',
        data: {
          activityFrom: 'detail_rec_coupon_add',
          couponCode: mainCouponCode,
          curCouponCode: mainCouponCode,
          sort: 1,
          subCouponCodes,
        },
        headers: {
          'AppCurrency': currency,
          'AppLanguage': appLanguage
        },
        useBffApi: 1,
      })
      const couponInfos = bffCouponInfo?.info?.newPromotionPopupInfo?.couponInfos || []
      const mainCouponItem = couponInfos.find(item=>item.meetHigh == 0)
      if(bffCouponInfo.code !== '0' || !mainCouponItem) { // 接口请求错误，没有不满足门槛的券
        commit('newProductDetail/common/updateBffCouponInfo', null, { root: true })
        return false
      } else {
        commit('newProductDetail/common/updateBffCouponInfo', couponInfos, { root: true })
        if(!addbagRecStyleOne) { // 拿交易中间层的物料请求推荐
          await dispatch('fetchAddBagCouponListData', { mainCouponItem })
        }
        return true
      }
    } catch (error) {
      return false
    }
  },
  async getAddBagListData({ state, getters, commit, rootState }, { mainCouponItem = null }) {

    const _withCCCFeedback = (list = []) => {
      const cccFeedback = rootState.productDetail?.Recommend?.ccc_feed_back
      if (!list?.length || !cccFeedback) return list
      return list.map(item => {
        return {
          ...item,
          cccFeedback,
        }
      })
    }
    const { itemConfig } = useItemConfig({
      name: 'DetailRecommendDrawerPic2',
      type: EItemConfigType.Pic2,
      mergeConfig: {
        useOwnClickAddBagAnalysis: true,
      }
    })
    const itemConfigPic2 = { ...itemConfig.value }
    const language = getters['common/language'] || {}
    const couponData = state.common.addBagRecCouponListData
    const ofbwCatData = state.common.ofbwCatData
    const ofbwListData = state.common.ofbwListData
    const addBagRecData = []
    if(ofbwListData?.length) {
      const listData = ofbwListData
      await getDetailRecPreTreat({
        products: listData,
        itemConfig: itemConfig
      })
      addBagRecData.push({
        item_name: language.SHEIN_KEY_PWA_32272 || 'all',
        item_id: 'all',
        firstPageFetch: true,
        list: _withCCCFeedback(listData)
      })
    } else {
      return false
    }
    if((couponData?.length || 0) > 10 && mainCouponItem) {
      const couponList = couponData
      const apply_for = mainCouponItem?.apply_for || 0
      const thresholds = mainCouponItem?.thresholds || []
      const isGoodsCoupon = [1, 2, 3, 7, 8].includes(+apply_for) // 商品券
      const isShipCoupon = [5, 6, 9].includes(+apply_for)
      let item_name = language.SHEIN_KEY_PWA_32276 || 'Coupon Discount'
      if(isGoodsCoupon && thresholds?.[0]?.couponTitle) {
        item_name = template(`<span class="font-orange">${thresholds[0].couponTitle}</span>`, `<span class="font-orange">${thresholds[0].thresholdPrice}</span>`, language.SHEIN_KEY_PWA_32274 || ' {0} OFF Over {1}')
      } else if(isShipCoupon) {
        item_name = language.SHEIN_KEY_PWA_32275 || 'Freeshipping Discount'
      }
      await getDetailRecPreTreat({
        products: couponList,
        itemConfig: {
          ...itemConfigPic2,
          showEstimatedPriceOnSale: false, // 到手价-占用原来的销售价showEstimatedPriceOnSale
          noNeedAnyEstimatedPriceTag: true, // 到手价不展示任何标签信息,配合showEstimatedPriceOnSale 使用
          showEstimateTag: false,
          showEstimatedPriceReachThreshold: false,
        }
      })
      addBagRecData.push({
        item_name: item_name,
        item_id: 'coupon',
        firstPageFetch: true,
        list: _withCCCFeedback(couponList)
      })
    }
    if((ofbwCatData.length || 0) > 4) {
      let catData = ofbwCatData
      catData = catData.slice(0, 8)
      catData.forEach(item => {
        addBagRecData.push({
          list: [],
          ...item,
          item_id: String(item.item_id),
        })
      })
    }
    commit('newProductDetail/common/updateAddBagRecData', addBagRecData, { root: true })
    return true
  },

  async fetchAddBagCouponListData({ dispatch, commit }, { mainCouponItem }) {
    const recommendInfo = mainCouponItem?.recommendInfo || {}
    const params = {
      sceneId: 152,
      limit: 40,
      maxNum: 500,
      extraParams: {
        add_on_type: '5',
        // 过滤售罄商品
        filterSoldOutConfig: {
          on: true,
        }
      },
      extraContextParams: {
        include_tsp_id: recommendInfo?.directTag || '',
        exclude_tsp_id: recommendInfo?.returnTag || '',
      }
    }
    const recommendsData = await dispatch('integrateFetchRecommend',
      {
        recommends: [params],
        limit: 40,
        clearRepeat: false,
        recommendConfig: {
          hideEstimatedPrice: true,
        }
      }
    )
    const couponListData = recommendsData?.[152]?.info?.products || []
    commit('newProductDetail/common/updateAddBagRecCouponListData', couponListData, { root: true })
    return recommendsData
  },
  async fetchAddBagOfbwCatData({ dispatch, commit, state }, { isSameGoods }) {
    const curOfbwCatData = state.common.ofbwCatData
    if(isSameGoods && curOfbwCatData?.length) {
      return curOfbwCatData
    }
    const params = {
      sceneId: 256,
      extraParams: {
        limit: 10,
      },
      extraContextParams: {
        location: 'addbagpopupcates',
      }
    }
    const recommendsData = await dispatch('integrateFetchRecommend',
      {
        recommends: [params],
        clearRepeat: false
      }
    )
    const ofbwCatData = recommendsData?.[256]?.info?.products || []
    commit('newProductDetail/common/updateOfbwCatData', ofbwCatData, { root: true })
    return recommendsData
  },
  async fetchAddBagOfbwListData({ dispatch, commit, state, rootGetters }, { isSameGoods }) {
    const curOfbwListData = state.common.ofbwCatData
    if(isSameGoods && curOfbwListData?.length) {
      return curOfbwListData
    }
    const params = {
      sceneId: 255,
      maxNum: 500,
      extraParams: {
        limit: 40,
        // 过滤售罄商品
        filterSoldOutConfig: {
          on: true,
        }
      },
      extraContextParams: {
        location: 'addbagpopupgoods',
      }
    }
    const recommendsData = await dispatch('integrateFetchRecommend',
      {
        recommends: [params],
        limit: 40,
        clearRepeat: false
      }
    )
    const ofbwListDataNum = recommendsData?.[255]?.info?.total || 0
    if(ofbwListDataNum < 20) {
      return false
    }
    const ofbwListData = recommendsData?.[255]?.info?.products || []
    commit('newProductDetail/common/updateOfbwListData', ofbwListData, { root: true })
    const detail = rootGetters['newProductDetail/common/detail']
    const { goods_id } = detail
    commit('newProductDetail/common/updateAddOnitemGoodsId', goods_id, { root: true })
    return recommendsData
  },
  // 拉取推荐数据
  async integrateFetchRecommend(
    { dispatch, rootState, rootGetters },
    {
      recommends,
      // 默认去重
      clearRepeat = true,
      // 默认保留可支持推荐模块滚动的数量
      leastRetain = 6,
      limit = 20,
      // 个性化配置
      recommendConfig = {}
    },
  ) {
    // 入参校验·
    if (!Array.isArray(recommends) || !recommends.length) return null
    const detail = rootGetters['newProductDetail/common/detail']
    const { attribute, mainAttribute } = rootState.newProductDetail?.common || {}
    if (!detail) return null
    // 公共入参
    const { goods_id, cat_id } = detail
    const pubReqParams = {
      limit,
      pageEnable: 1,
      req_num: 500,
      contextParams: {
        add_cart: 0,
        goods_ids: goods_id,
        cate_ids: cat_id,
        attribute: mainAttribute || attribute,
      },
    }

    const abtRules = await getUserAbtData()
    const reqRecommends = []
    recommends.forEach(recommend => {
      const { sceneId, pageNum = 1, extraParams, extraContextParams } = recommend
      const recPosKey = RECOMMEND_SCENE_ID_MAP.get(sceneId)
      if (!recPosKey) return
      const reqParams = {
        ...pubReqParams,
        location: recPosKey,
        scene_id: sceneId,
        pageNum,
        jsonRuleId: JSON.stringify(abtRules?.[recPosKey]?.param || {}),
        // atomFields: { cornerPropertyUpsell: true },
        // 3图
        subPageKey: recommend.threeListStyle ? 'other_recommend_3pic' : 'other_recommend_2pic',
        ...(extraParams || {})
      }
      Object.assign(reqParams.contextParams, extraContextParams || {})
      reqRecommends.push(reqParams)
    })
    if (!reqRecommends.length) return null

    const result = await schttp({
      url: '/api/recommend/integrateRecommend/query',
      method: 'POST',
      data: { 
        recommends: reqRecommends,
        recommendConfig
      },
    })

    if (result?.code === 0) {
      const resultRecommends = result.info?.recommends
      // 去重逻辑
      dispatch(
        'handleRecommendData',
        { clearRepeat, leastRetain, recommends: resultRecommends }
      )
      return resultRecommends
    }
    return null
  },
  /**
   * 加载新组合购推荐数据 
   * todo
   */
  async initComboBuyRecommendData({ commit, rootState, rootGetters }, payload = {}) {
    const isShowNewComboBuyAbt = rootGetters['newProductDetail/common/isShowNewComboBuyAbt']
    /**
     * 1.30版本，abt无不请求推荐数据
     */
    if (!isShowNewComboBuyAbt) return
    const detail = rootGetters['newProductDetail/common/detail']
    const mall_code = rootGetters['newProductDetail/common/mallCode']
    const fsAbt = rootGetters['newProductDetail/fsAbt'] || {}

    const { itemConfig } = useItemConfig({
      name: 'ComboBuy',
      useNewAbt: false,
      type: EItemConfigType.Pic3,
      mergeConfig: {
        showAddBagBtn: false,
        showAddBagBtnBottomRight: false,
        disableMainimgJump: true, // 点击禁止跳转
        showEstimateTag: false, // 无到手价
        showEstimatedPriceReachThreshold: false, // 无到手价
        showEstimatedPriceOnSale: false, // 无到手价
        showEstimatedPrice: false, // 无到手价
        showABPriceOnSale: true, // 显示AB测试价格
        showABPriceTag: true, // 显示AB测试价格
        noNeedAnyEstimatedPriceTag: true, // 不显示到手价tag
      }
    })

    /**
     * 如果不刷推荐链路，且已有数据，则不请求
     */
    const comboBuyRecommend = rootState.newProductDetail.common?.comboBuyRecommend
    if (payload.noUpdateRecommend && comboBuyRecommend.length) return

    // 先初始化数据
    commit('newProductDetail/common/updateComboBuyRecommend', {
      list: [],
      show: false
    }, { root: true })

    const { goods_id, cat_id } = detail

    const price_abt = {
      'abt-poskey': 'EstimatedPrice',
      'abt-expid': fsAbt.EstimatedPrice?.child?.[0]?.eid || '100070641',
      'abt-branchid': fsAbt.EstimatedPrice?.child?.[0]?.bid || '100172546',
      'abt-params': '',
      'abt-params-json': fsAbt.EstimatedPrice?.p
    }
    const data = await getComboBuyRecommendList({
      goods_id,
      cat_id,
      mall_code,
      itemConfig,
      price_abt
    })

    const hasValidData = data?.length >= MIN_COMBOBUY_RECOMMEND_NUM
    // /**
    //  * none分支如果能取到也要上报，只是上报，但不展示；取不到就不报）
    //   不展示的none分支，则在商详首屏就上报该事件
    //  */
    // if (!isShowNewComboBuyAbt && !hasValidData) {
    //   daEventCenter.triggerNotice({
    //     daId: '1-6-1-178'
    //   })
    // }

    commit('newProductDetail/common/updateComboBuyRecommend', {
      list: data,
      show: hasValidData
    }, { root: true })
  },
  // 推荐位接口数据处理，对应 /integrateRecommend/query 接口返回数据格式
  async handleRecommendData(
    { dispatch },
    { clearRepeat, leastRetain, recommends }
  ) {
    if (!clearRepeat || !recommends) return

    for (let sceneId in recommends) {
      const soleRecommend = recommends[sceneId]
      if (soleRecommend?.code !== 0 || !soleRecommend?.info) continue
      const { products = [] } = soleRecommend.info
      const processedProducts = await dispatch(
        'nonRepeatRecPrdEntry',
        {
          sceneId,
          list: products,
          leastRetain,
        },
      )
      soleRecommend.info.products = processedProducts
    }
  },
  // 获取推荐位去重数据
  // * 公共推荐位商品唯一入口，会自动更新去重列表
  nonRepeatRecPrdEntry({ commit, rootState }, { sceneId, list, leastRetain = 0 } = {}) {
    // const unifiedRecommend = rootGetters['newProductDetail/unifiedRecommend']
    const recommendGoodsIdMap = rootState.newProductDetail.common?.recommendGoodsIdMap
    // const { recommendGoodsIdMap } = unifiedRecommend || {}
    if (!sceneId || !Array.isArray(list)) return []

    const targetSet = Object.assign(
      Object.create(null), { ...(recommendGoodsIdMap || {}) }
    )
    const returnSet = []
    const repeatSet = []
    list.forEach(product => {
      const productCopy = { ...(product || {}) }
      const { goods_id } = productCopy
      if (!goods_id) return
      const goodsId = String(goods_id)

      // 重复商品
      if (targetSet[goodsId]) {
        return repeatSet.push(productCopy)
      }

      targetSet[goodsId] = {
        goodsId,
        sceneId,
      }
      returnSet.push(productCopy)
    })

    // 若设置了最小返回数且去重商品数量不足，则在最后拼接重复商品
    const returnSetLength = returnSet.length
    if ((leastRetain <= list.length) && (returnSetLength < leastRetain)) {
      const supplementNum = leastRetain - returnSetLength
      returnSet.push(...repeatSet.slice(0, supplementNum))
    }

    commit('newProductDetail/common/updateRecommendGoodsIdMap', targetSet, { root: true })
    return returnSet
  },
  /**
   * @param { String } type ymal | similar
   * @param { Array } targets 曝光元素集合
   * @param { Number } limit 存储个数
   */
  updateRecommendGoods({ commit, rootGetters, rootState }, { targets = [], type = 'ymal', limit = 20 }) {
    if (!targets?.length) return
    const { initExposeStatus, exposeGoods } = rootState.newProductDetail.common || {}
    const { goodsId } = initExposeStatus
    if (goodsId != rootGetters['newProductDetail/common/detail'].goods_id) return
    let result = []
    const recommend = exposeGoods?.[type] || []
    const targetGoods = targets.map(el => el.getAttribute('data-id') || el.getAttribute('data-goods-id'))
    if (recommend.length >= limit) {
      const attrs = recommend.splice(targets.length)
      result = attrs.concat(targetGoods)
    } else {
      result = recommend.concat(targetGoods)
    }
    commit('newProductDetail/common/updateExposeGoods', {
      ...exposeGoods,
      [type]: result,
    }, { root: true })
    return targetGoods
  },
  // 领券失败\登录状态切换更新到手价券列表
  async updateEstimatedCouponList({ commit, rootState, rootGetters }, payload = {}) {
    const { skuInfo } = rootState.newProductDetail?.common || {}
    const mallCode = rootGetters['newProductDetail/common/mallCode']
    const {
      detail: { goods_id }
    } = rootState.newProductDetail.coldModules?.productInfo
    const { isOldCouponList, noUpdateRealTime, noUpdateRecommend } = payload

    const MAIN_BFF_APOLLO = rootState.productDetail.MAIN_BFF_APOLLO
    const is_promotion_bff = MAIN_BFF_APOLLO?.v3
    // bff更新券列表 todo
    let _isUpt = false
    const _updateHot = () => {
      if (_isUpt) return
      _isUpt = true
      this.dispatch('newProductDetail/asyncHotModles', {
        goods_id: goods_id,
        mallCode: mallCode,
        noUpdateRecommend,
      })
    }
    // bff更新券列表调用实时链路，登录成功后不重复调用
    if (!noUpdateRealTime && is_promotion_bff) _updateHot()

    if (isOldCouponList) {
      if (noUpdateRealTime) return
      return _updateHot()
    }
    if (skuInfo.mall?.[mallCode]) {
      const result = await schttp({
        url: '/api/productInfo/attr/get',
        params: { id: goods_id, _t: Date.now() }
      })
      if (result.code != 0) return
      let curSkuCode = skuInfo.sku_code
      let originSkuInfo =
        result.info?.sale_attr_list?.[goods_id]?.sku_list?.find(
          item => item.sku_code === curSkuCode
        ) || {}
      let newSkuInfo = cloneDeep(skuInfo)
      let newCouponList = (
        originSkuInfo.mall_price?.find?.(item => item.mall_code === mallCode) || []
      ).coupons
      if (newSkuInfo.mall?.[mallCode]?.coupons) newSkuInfo.mall[mallCode].coupons = newCouponList
      commit('newProductDetail/common/updateSkuInfo', newSkuInfo, { root: true }) // 新数据流
      return
    }
    if (noUpdateRealTime) return
    return _updateHot()
  },
  /**
   * @function 自动领券，分为到手价券和免邮券、多张领取
  */
  async autoGetCouponAtAddBag(
    { dispatch, getters },
    { needToast: needToast = false, delayToast: delayToast = 1000, needToastFail: needToastFail = false }
  ) {

    if (!isLogin()) return
    let { getEstimatedInfo } = getters['Price/estimatedInfo']
    let { matchFreeShippingCoupons, isFreeShippingCouponForAutoGet } = getters['ShippingEnter/freeShippingInfo']
    const language = getters['common/language'] || {}
    
    const getEstimatedCoupon = () => {
      if (!getEstimatedInfo) return
      if (!getEstimatedInfo?.optimalCouponList?.length) return
      const { EstimatedPrice } = getEstimatedInfo
      if (!(EstimatedPrice?.p?.E_Price_Cal === 'Non_Threshold' || EstimatedPrice?.p?.E_Price_Cal === 'Have_Threshold')) return
      const couponCodes = getEstimatedInfo?.optimalCouponList?.filter(coupon => {
        const isBindCoupon = coupon?.is_bind == 1 // 是绑定券。是否是绑定类型,0-否，1-是
        const notReceived = coupon?.bind_status != 1 // 未领取。 1已领取 2未领取
        return isBindCoupon && notReceived
      })?.map(coupon => coupon?.coupon_code) || []
      return couponCodes
    }

    const getFreeShippingCoupons = () => {
      let result = []
      matchFreeShippingCoupons.forEach(coupon => coupon.is_bind == 1 && coupon.coupon_status != 1 && result.push(coupon.coupon))
      return result
    }

    let estimatedCouponCodes = getEstimatedCoupon() || []
    let freeShippingCoupons = isFreeShippingCouponForAutoGet ? getFreeShippingCoupons() : []

    let couponCodes = [...estimatedCouponCodes, ...freeShippingCoupons]
    if (!couponCodes.length) return 

    schttp({
      method: 'POST',
      url: '/api/productInfo/bindCoupon/post',
      data: {
        coupon_codes: couponCodes
      },
    }).then(res => {
      const failAllrange = !res || res.code != 0 || res.info?.failureList?.find?.(item => couponCodes.includes(item.couponCode))
      const successCodesList = res.info?.successList?.map(item => item.couponCode)
      const isEstimatedCouponSuccess = estimatedCouponCodes.length && estimatedCouponCodes.every(item => {
        return successCodesList?.includes(item)
      })
      if (isEstimatedCouponSuccess) {  // 最优券必须全部都领取成功，才会提示领取成功
        setTimeout(() => {
          needToast && Toast({
            content: language.SHEIN_KEY_PWA_25104 || 'We automatically help you to claim coupons',
            iconClass: 'suiiconfont sui_icon_coupon_discount_16px',
          })
        }, delayToast)
      }

      const failEstimated = estimatedCouponCodes.length && res.info?.failureList?.find?.(item => estimatedCouponCodes.includes(item.couponCode))
      if(failEstimated && needToast && needToastFail){ // 由促销弹窗点击“领券&加车”按钮过来时，最优券-部分失败/全部失败提示失败
        const errorCode = failEstimated?.code
        failEstimated && Toast({
          content: language[errorCode]
        })
      }
      dispatch('updateEstimatedCouponList')
      const abtest = abtUserAnalysis({ posKeys: 'EstimatedPrice' })?.sa
      daEventCenter.triggerNotice({
        daId: '1-6-4-42',
        extraData: {
          coupon_id: couponCodes.join(`,`),
          status: failAllrange ? 0 : 1,
          abtest
        }
      })
    })
  },
  // 打开登录弹窗
  showLoginModal({ dispatch }, payload) {
    if (typeof window !== 'undefined' && typeof SHEIN_LOGIN !== 'undefined') {
      const { params } = payload || {}
      return SHEIN_LOGIN.show({
        show: true,
        cb: (status) => {
          dispatch('loginModalSucCb', {
            ...(payload || {}),
            status,
          })
        },
        ...(params || {}),
      })
    }
  },
  // 登录成功后的回调
  async loginModalSucCb({ commit, dispatch, rootGetters, state }, payload) {
    // 登录不成功不走后续回调
    if (!isLogin() || state.common?.isLoginChange) return
    const { cb, status } = payload || {}
    if (typeof SaPageInfo !== 'undefined') {
      SaPageInfo.start_time = Date.now()
      sa('set', 'setPageData', SaPageInfo)
      sa('send', 'pageEnter', { start_time: Date.now() })
    }
    if (cb) {
      await cb(status)
    }
    const goods_id = rootGetters['newProductDetail/common/goodsId']
    const mallCode = rootGetters['newProductDetail/common/mallCode']
    dispatch('asyncHotModles', {
      goods_id,
      mallCode,
      noUpdateRecommend: true,
    })
    const isOldCouponList = !rootGetters['newProductDetail/common/isShowEstimatedStyle']
    dispatch('updateEstimatedCouponList', { isOldCouponList, noUpdateRealTime: true })
    commit('newProductDetail/common/updateIsLoginChange', true, { root: true })
    dispatch('BagData/initAddBagCountOnTheDay')
  },
  async getNewGtlInfo({ rootState, commit, getters, rootGetters }) {
    const showNewGtlAndOutfit = rootGetters['newProductDetail/common/showNewGtlAndOutfit']
    const needBuyBoxSellers = rootGetters['newProductDetail/common/needBuyBoxSellers']

    const isPaidUser = rootGetters['newProductDetail/isPaidUser']
    const { detail, goods_imgs } = rootState.newProductDetail.coldModules.productInfo || {}
    const oneClickPayState = rootState.newProductDetail.common.oneClickPayState
    const cateNodeId = getters['common/cateNodeId']
    const isOutfitShowYmalSource = rootGetters['newProductDetail/common/isOutfitShowYmalSource']
    // 精减请求体
    const goodsImgs = {}
    if (goods_imgs) {
      const { detail_image, main_image } = goods_imgs || {}
      goodsImgs.detail_image = detail_image?.map?.((item) => {
        return {
          thumbnail: item.thumbnail,
        }
      }) || null
      goodsImgs.main_image = main_image?.thumbnail ? { thumbnail: main_image.thumbnail } : null
    }
    const { goods_id, cat_id, goods_sn } = detail
    const fileds = ['outfitImgUrlFail']
    const requestFileds = {}
    requestFileds.outfitsImg = 1
    requestFileds.getTheLook = 1
    if(needBuyBoxSellers) {
      fileds.push('buyBoxRecInfo')
      requestFileds.buyBoxRec = 1
    }
    if (isOutfitShowYmalSource) {
      fileds.push('ymalsCateInfo')
      requestFileds.ymalsCate = 1
    }
    const result = await schttp({ 
      url: '/api/productInfo/recommendGroup/query',
      method: 'post',
      data: {
        fileds,
        requestFileds,
        requestParams: {
          goods_id,
          cat_id,
          isPaidUser,
          goods_imgs: goodsImgs,
          goods_sn,
          node_id: cateNodeId,
          page_num: 1,
        },
        billno: oneClickPayState?.billInfo?.billno || '', // 一键购订单号
      },
    })

    // 没命中 ab，不更新数据，只为了前置的数据上报
    if (!showNewGtlAndOutfit && !needBuyBoxSellers) {
      commit('newProductDetail/common/updateNewGtlInfoReady', true, { root: true })
      return
    }
    const outfitImgUrlFail = result?.info?.outfitImgUrlFail || false
    const buyBoxRecInfo = result?.info?.buyBoxRecInfo || []
    const ymalsCateInfo = result?.info?.ymalsCateInfo?.products || null
    if(buyBoxRecInfo.length) {
      await getDetailRecPreTreat({ products: buyBoxRecInfo })
    }

    
    // outfit不匹配主图图片,曝光埋点
    if (outfitImgUrlFail) {
      daEventCenter.triggerNotice({
        daId: '1-6-1-195'
      })
    }
    

    commit('newProductDetail/common/updateBuyBoxRecInfo', buyBoxRecInfo, { root: true })
    ymalsCateInfo && commit('newProductDetail/common/updateYmalsCateInfo', ymalsCateInfo, { root: true })

    commit('newProductDetail/common/updateNewGtlInfoReady', true, { root: true })
    return result
  },
  async getOldOutfitInfo({ rootState, commit, getters, rootGetters }) {
    const isPaidUser = rootGetters['newProductDetail/isPaidUser']
    const { detail, goods_imgs } = rootState.newProductDetail.coldModules.productInfo || {}
    const oneClickPayState = rootState.newProductDetail.common.oneClickPayState
    const cateNodeId = getters['common/cateNodeId']
    // 精减请求体
    const goodsImgs = {}
    if (goods_imgs) {
      const { detail_image, main_image } = goods_imgs || {}
      goodsImgs.detail_image = detail_image?.map?.((item) => {
        return {
          thumbnail: item.thumbnail,
        }
      }) || null
      goodsImgs.main_image = main_image?.thumbnail ? { thumbnail: main_image.thumbnail } : null
    }
    const { goods_id, cat_id, goods_sn } = detail
    const fileds = ['newGtlAndOutfitInfo']
    const requestFileds = {}
    requestFileds.getTheLook = 1
    const { commonPageInfo } = rootState.productDetail.coldModules
    const companionLimitNum = getNewCompanionConfig(commonPageInfo.GOODS_DETAIL_NEW_COMPANION_LIMIT)

    let result = {}
    
    try {
      result = await schttp({ 
        url: '/api/productInfo/recommendGroup/query',
        method: 'post',
        data: {
          fileds,
          requestFileds,
          companionLimitNum,
          requestParams: {
            goods_id,
            cat_id,
            isPaidUser,
            goods_imgs: goodsImgs,
            goods_sn,
            node_id: cateNodeId,
            page_num: 1,
          },
          billno: oneClickPayState?.billInfo?.billno || '', // 一键购订单号
        },
      })
  
      const MAIN_BFF_APOLLO = rootState.productDetail.MAIN_BFF_APOLLO
      const newGtlAndOutfitInfo = result?.info?.newGtlAndOutfitInfo || []
      if (!MAIN_BFF_APOLLO?.v4 && newGtlAndOutfitInfo.length > 0) {
        daEventCenter.triggerNotice({
          daId: '1-6-1-237',
        })
      }
      const firstRecommend = newGtlAndOutfitInfo?.[0] || {}
      if (!firstRecommend?.related_goods?.length) {
        commit('newProductDetail/common/updateNewGtlInfoReady', true, { root: true })
        return
      }
      const products = []
      newGtlAndOutfitInfo?.forEach?.((item) => {
        products.push(...(item?.related_goods || []))
      })
      const params = firstRecommend.related_goods.map((item) => {
        return {
          mall_code: item.mall_code,
          goods_id: item.goods_id,
          skc: item.goods_sn,
        } 
      })
      const [bundlePrice] = await Promise.all([
        schttp({
          url: '/api/productInfo/getBundlePrice/get',
          method: 'POST',
          data: {
            params
          }
        }),
        getDetailRecPreTreat({ products }),
      ])
      newGtlAndOutfitInfo?.forEach?.((item) => {
        item?.related_goods || []
      })
      firstRecommend.bundlePrice = bundlePrice?.info || {}
      commit('newProductDetail/common/updateNewGtlInfo', newGtlAndOutfitInfo, { root: true })
      commit('newProductDetail/common/updateNewGtlInfoReady', true, { root: true })
    } catch (error) {
      commit('changeRootSLoadingStatus', { show: false }, { root: true })
      console.error(error)
    }
    return result
  },
  async openGetTheLookNewPopup({ rootState, getters, dispatch, rootGetters, commit }, { initCateTab = '', activeFrom = '', swiperIndex = 0 }) {
    const isloading = rootState.rootSLoading.show
    commit('changeRootSLoadingStatus', { show: true }, { root: true })
    if(isloading) return
    if(!rootState.newProductDetail.common.newGtlAndOutfitInfo?.length){
      await dispatch('getOldOutfitInfo')
    }
    const [GetTheLookPopupInstance, config] = await Promise.all([
      import('public/src/pages/goods_detail/RecommendRelatives/GetTheLookNew/main.js').then(res => res.default),
      (async () => {
        const baseLanguage = getters['common/language'] || {}
        const productItemsLanguage = getters['common/productItemsLanguage'] || {}
        const isPaidUser = rootGetters['newProductDetail/isPaidUser']
        const fsAbt = rootGetters['newProductDetail/fsAbt'] || {}
        const isHitComplianceMode = rootGetters['newProductDetail/common/isHitComplianceMode'] 
        const complianceMode = rootGetters['newProductDetail/common/complianceMode'] 
        const isOutfitShowYmalSource = rootGetters['newProductDetail/common/isOutfitShowYmalSource'] 
        const data = rootState.newProductDetail.common.newGtlAndOutfitInfo
        const ymalsCateInfo = rootState.newProductDetail.common.ymalsCateInfo
        const goodsId = rootGetters['newProductDetail/common/goodsId']
        const catId = rootGetters['newProductDetail/common/catId']
        const cateNodeId = rootGetters['newProductDetail/common/cateNodeId']
        const sku_code = rootState.newProductDetail.common.skuInfo?.sku_code || ''
        const showAddbagTips = activeFrom === 'addbagoutfit'
        const addToBagMainGoods = rootState.newProductDetail.common.addToBagMainGoods
        const mainGoodsAlone = ['addbagoutfit', 'outfitalone', 'outfitymal'].includes(activeFrom) && !!addToBagMainGoods?.skuCode
        const mainSkuCode = mainGoodsAlone ? addToBagMainGoods?.skuCode : sku_code
        const language = {
          ...baseLanguage,
          ...productItemsLanguage,
        }

        const mainGoodsPrice = getMainGoodsPrice({
          language,
          isPaidUser,
          salePrice: rootGetters['newProductDetail/Price/salePrice'],
          discountInfo: rootGetters['newProductDetail/Price/discountInfo'],
          getEstimatedInfo: rootGetters['newProductDetail/common/getEstimatedInfo'],
          price: rootGetters['newProductDetail/common/price'],
          sheinClubPromotionInfo: rootGetters['newProductDetail/common/sheinClubPromotionInfo'],
          config: {
            // complianceMode // 只勾选主商品，要计算到手价
          }
        })
        const isShowItemChange = fsAbt.newoutfit?.param?.outfitswitch !== 'none'
        const showCateRecommend = ['show-ymal', 'show'].includes(fsAbt.newoutfit?.param?.outfitsimilar)
        
        return {
          goodsId,
          catId,
          cateNodeId,
          mainSkuCode,
          data,
          language,
          initCateTab,
          swiperIndex,
          ymalsCateInfo,
          isPaidUser,
          mainGoodsPrice,
          isShowItemChange,
          isHitComplianceMode,
          config: { // 配置项要有专门的收口
            isOutfitShowYmalSource,
            showCateRecommend,
            isOutfitBigPic: true,
            showAddbagTips,
            mainGoodsAlone,
            addToBagMainGoods,
            activeFrom,
          },
          complianceMode,
          callback: {
            onAddCartSuccess: () => {
              // 更新购物车数量
              dispatch('fetchCartInfo', null, { root: true })
            },
            onUpdateCoupon: () => {
              dispatch('updateEstimatedCouponList', { noUpdateRecommend: true })
            },
            onGoodsDetailScroll: () => {
              dispatch('common/setGoodsDetailScroll')
            }
          }
        }
      })()
    ])
    commit('changeRootSLoadingStatus', { show: false }, { root: true })
    if (!config) return false
    return GetTheLookPopupInstance.open(config)
  },
  // 获取定制商品tsp店铺池
  async getCustomizationNewLinkTag({ state }){
    const { storeCode } = state.coldModules.store.storeInfo || {}
    const result = await schttp({ 
      url: '/api/productInfo/customizationTspTag/query',
      params: { store_code: storeCode }
    })
    return result?.isCustomizationNewLink
  },
}
