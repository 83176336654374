import price from 'public/src/pages/product_app/store/modules/product_detail/components/top/innerComponents/price.js'
import priceTips from 'public/src/pages/product_app/store/modules/product_detail/components/top/innerComponents/priceTips.js'
import MainSaleAttr from 'public/src/pages/product_app/store/modules/product_detail/components/top/innerComponents/MainSaleAttr.js'
import GoodsName from 'public/src/pages/product_app/store/modules/product_detail/components/top/innerComponents/GoodsName.js'
import coupon from 'public/src/pages/product_app/store/modules/product_detail/components/top/innerComponents/coupon.js'
import promotion from 'public/src/pages/product_app/store/modules/product_detail/components/top/innerComponents/promotion.js'
import promotionEnter from 'public/src/pages/product_app/store/modules/product_detail/components/top/innerComponents/promotionEnter.js'
import belt from './innerComponents/belt.js'
import promotionDrawer from './innerComponents/promotionDrawer.js'
import gift from './innerComponents/gift.js'
import MainPicture from 'public/src/pages/product_app/store/modules/product_detail/components/top/innerComponents/MainPicture.js'
import SerialProducts from 'public/src/pages/product_app/store/modules/product_detail/components/top/innerComponents/SerialProducts.js'
import SizeBox from './innerComponents/SizeBox.js'
import SizeAttrFold from './innerComponents/SizeAttrFold.js'

export default {
  price,
  priceTips,
  belt,
  MainSaleAttr,
  SizeBox,
  GoodsName,
  coupon,
  promotion,
  promotionEnter,
  promotionDrawer,
  gift,
  MainPicture,
  SerialProducts,
  SizeAttrFold
}
