const RIGHT_CATEGORY_WIDTH = 7.2267 - 0.32 * 2
const SIDE_RIGHT_CATEGORY_WIDTH = 8 - 0.32 * 2

const state = {
  pageCode: 'pwaCategoryPage',
  isNew: false, // 是否新接口
  isBff: false, // 是否bff
  isBsCat: false, // 是否下沉组件
  isPreview: false,
  locals: null,
  ssrPageType: '',
  language: {},
  bffLanguages: {},
  categoryCrowdInfo: {},
  allTabAbt: {},
  expandRearFirstLevel: '',
  selectedOneCate: {},
  crowdChannel: '', // 人群频道
  categoryData: {}, // category数据

  categoryDataListByOrder: {}, // category数据按照order排序
  categoryFreeshippingCcc: {},
}
export default state
