export default {
  namespaced: true,
  state: {
    detail_pic_fold: true
  },
  getters: {
    detail_pic_box(state, getters, rootState, rootGetters) {
      const language_v2 = rootGetters['productDetail/common/language_v2']
      const imgs = rootState.productDetail.asyncModules.currentSkcImgInfo.detailImage
      const { cccDetailsTemplate } = rootState.productDetail.hotModules.productInfo || {}
      const content = cccDetailsTemplate?.content?.[0] || {}

      const abtInfo = rootGetters['productDetail/product_detail_abt_info']
      const moredetails = abtInfo?.moredetails?.param?.moredetails || ''
      const isCccTemplate = !['A', 'B', 'C'].includes(moredetails)
      // if (content.detailImg == 1 || content.detailImg == 2 || !imgs?.length) {
      //   return {
      //     show: false
      //   }
      // }

      const text = {
        title: language_v2.SHEIN_KEY_PWA_15687,
        more: language_v2.SHEIN_KEY_PWA_15698,
        less: language_v2.SHEIN_KEY_PWA_16454
      }

      // CCC配置：detailImg = 0 独立楼层展示，detailImg = 1 细节图展示在主图，detailImg = 2 细节图不展示
      const isShowDetailBox = moredetails === 'C' || (isCccTemplate && content.detailImg != 1 && content.detailImg != 2)
      if(isShowDetailBox && imgs?.length) {
        return {
          show: true,
          text,
          imgs
        }
      }else{
        return {
          show: false
        }
      }
    }
  },
  mutations: {
    update_detail_pic_fold(state, payload) {
      state.detail_pic_fold = payload
    }
  }
}
