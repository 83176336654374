const CLICK_PRODUCT_CARD_IN_GOODS_DETAIL = 'click_product_card_in_goods_detail' // 商详页点击商卡
const CLICK_PRODUCT_CARD_IN_INFORMATION_FLOW = 'click_product_card_in_information_flow' // 信息流点击商卡
const CLICK_PRODUCT_CARD_IN_PICKED_LIST = 'click_product_card_in_picked_list' // 信息流落地页点击商卡
const OPEN_QUICK_CART_IN_PICKED_LIST = 'open_quick_cart_in_picked_list' // 信息流落地页点击商卡加车
const OPEN_QUICK_CART_RESULT_IN_PICKED_LIST = 'open_quick_cart_result_in_picked_list' // 信息流落地页点击商卡加车结果
const CLICK_PRODUCT_CARD_IN_LIST = 'click_product_card_in_list' // 列表页点击商卡
const OPEN_QUICK_CART_IN_LIST = 'open_quick_cart_in_list' // 列表页点击商卡加车
const OPEN_QUICK_CART_RESULT_IN_LIST = 'open_quick_cart_result_in_list' // 列表页点击商卡加车结果
const REENTER_IN_LIST = 'reenter_in_list' // 列表页重新展示
const REENTER_IN_INFORMATION_FLOW = 'reenter_in_information_flow' // 信息流落地页重新展示
const REENTER_IN_PICKED_LIST = 'reenter_in_picked_list' // 信息流落地页重新展示
const SHOW_PRODUCT_CARD_POPUP = 'show_product_card_popup' // 展示商卡弹窗

export {
  CLICK_PRODUCT_CARD_IN_GOODS_DETAIL,
  CLICK_PRODUCT_CARD_IN_INFORMATION_FLOW,
  CLICK_PRODUCT_CARD_IN_PICKED_LIST,
  OPEN_QUICK_CART_IN_PICKED_LIST,
  OPEN_QUICK_CART_RESULT_IN_PICKED_LIST,
  CLICK_PRODUCT_CARD_IN_LIST,
  OPEN_QUICK_CART_IN_LIST,
  OPEN_QUICK_CART_RESULT_IN_LIST,
  REENTER_IN_LIST,
  REENTER_IN_INFORMATION_FLOW,
  REENTER_IN_PICKED_LIST,
  SHOW_PRODUCT_CARD_POPUP,
}
