import { getLocalStorage, setLocalStorage, minus } from '@shein/common-function'

const ADD_BAG_COUNT_MAP_KEY = 'addBagCountMap'

function isInteger(value) {
  return /^[+]{0,1}(\d+)$/.test(value)
}

export default {
  namespaced: true,
  state: {
    addBagCountOnTheDay: 0, // 用户当天加车次数
    isAddCartInDetail: false, // 用户是否在商详页加车
    isFetchDataDone: false // 数据是否获取完
  },
  getters: {
    isRecommendAbove(state, getters, rootState, rootGetters) {
      const { commonInfo } = rootState.newProductDetail?.coldModules
      if (commonInfo?.IS_RW) return false
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      return fsAbt?.Cartentranloc?.p?.Cartentranloc === 'recommendabove'
    },

    isReviewBelow(state, getters, rootState, rootGetters) {
      const { commonInfo } = rootState.newProductDetail?.coldModules
      if (commonInfo?.IS_RW) return false
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      return fsAbt?.Cartentranloc?.p?.Cartentranloc === 'reviewbelow'
    },

    isShowCartEntry(state, getters){
      // 是否初始化购物车组件
      return getters.isRecommendAbove || getters.isReviewBelow
    },

    shouldScroll(state, getters, rootState, rootGetters) {
      // 如果是商详加车后才展示购物车组件，判断是否需要滚动到购物车组件
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const shouldAfterAddCart = fsAbt?.Cartentranloc?.p?.Cartentranaftercart === 'afteraddcart'
      return getters.isRecommendAbove && getters.shouldShow && shouldAfterAddCart
    },

    shouldInitAddCartCount(state, getters, rootState, rootGetters) {
      // 如果 Cartentranshowtiming abt 是数字，需要初始化用户当天加车次数
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const cartentranAbtParams = fsAbt?.Cartentranloc?.p
      const isIntegerNumber = isInteger(cartentranAbtParams?.Cartentranshowtiming)
      return isIntegerNumber && getters.isShowCartEntry
    },

    shouldShow(state, getters, rootState, rootGetters) {
      // 是否显示购物车组件
      if (!getters.isShowCartEntry) return false
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const cartentranAbtParams = fsAbt?.Cartentranloc?.p
      const isIntegerNumber = isInteger(cartentranAbtParams?.Cartentranshowtiming)
      // 如果是数字，判断用户当天加车次数大于 N 次，如果小于不展示
      if (isIntegerNumber && state.addBagCountOnTheDay < +cartentranAbtParams?.Cartentranshowtiming) return false
      const shouldAfterAddCart = cartentranAbtParams?.Cartentranaftercart === 'afteraddcart'
      // 只有在商详加车后，才会自动展示购物车组件
      if (shouldAfterAddCart) return state.isAddCartInDetail
      return true
    },
  },
  mutations: {
    updateIsAddCartInDetail(state, payload = true) {
      if (!this.getters['newProductDetail/BagData/isShowCartEntry']) return
      state.isAddCartInDetail = payload
    },

    updateAddBagCountOnTheDay(state, isInit = false) {
      try {
        let addBagCountMap = getLocalStorage(ADD_BAG_COUNT_MAP_KEY)
        const oneDay = 24 * 60 * 60 * 1000
        const now = Date.now()
        const { member_id } = window?.gbCommonInfo?.user || {}
        if (addBagCountMap) {
          addBagCountMap = JSON.parse(addBagCountMap)
          const memberValue = addBagCountMap?.[member_id]
          let shouldInitMember = false
          if (memberValue) {
            const { date = 0, count = 0 } = memberValue || {}
            if (+minus(now - date) <= oneDay) {
              addBagCountMap[member_id] = {
                date,
                count: isInit ? count : count + 1,
              }
            } else {
              shouldInitMember = true
            }
          } else {
            shouldInitMember = true
          }
          if (shouldInitMember) {
            addBagCountMap[member_id] = {
              date: now,
              count: isInit ? 0 : 1,
            }
          }
        } else {
          addBagCountMap = {
            [member_id]: {
              date: now,
              count: isInit ? 0 : 1,
            },
          }
        }
        state.addBagCountOnTheDay = addBagCountMap?.[member_id]?.count
        !isInit && setLocalStorage({
          key: ADD_BAG_COUNT_MAP_KEY,
          value: JSON.stringify(addBagCountMap),
          expires: now + oneDay,
        })
      } catch (error) {
        console.error(error)
      }
    },

    updateFetchDataDone(state, payload = true) {
      state.isFetchDataDone = payload
    }
  },

  actions: {
    initAddBagCountOnTheDay({ commit, getters }) {
      if (getters.shouldInitAddCartCount) {
        commit('updateAddBagCountOnTheDay', true)
      }
    },
  }
}
  
