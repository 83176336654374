/** 根据手机系统/系统版本号获取搭配套装配置 */
export const getNewCompanionConfig = (config) => {
  const userAgent = navigator.userAgent
  // 是否Iphone
  const isIphone = /iPhone/.test(userAgent)

  // 获取Iphone系统版本号
  const getIphoneVersion = () => {
    const regex = /CPU (?:iPhone )?OS (\d+_\d+(?:_\d+)?) like Mac OS X/
    const match = userAgent.match(regex);
    return match ? parseInt(match[1], 10) : null
  }

  // 获取Android系统版本号
  const getAndroidVersion = () => {
    const regex = /Android (\d+(\.\d+)*);/
    const match = userAgent.match(regex);
    return match ? parseInt(match[1], 10) : null
  }

  const DEFAULT_LIMIT = 6
  if (!config) return DEFAULT_LIMIT
  try {
    const { android = {}, ios = {} } = config || {}
    const osVersion = isIphone ? getIphoneVersion() : getAndroidVersion()
    const osConfig = isIphone ? ios : android
    if (!osVersion || !osConfig?.version) return osConfig?.min || DEFAULT_LIMIT
    return osVersion > +osConfig?.version ? +osConfig?.max : +osConfig?.min
  } catch (error) {
    return DEFAULT_LIMIT
  }
}