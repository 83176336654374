import { template } from '@shein/common-function'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import schttp from 'public/src/services/schttp'
import { getLocalStorage } from '@shein/common-function'

const setAddressCache = async ({ addressId, countryId, stateId, cityId, districtId, countryName, city, state, district }) => {
  const { SiteUID, user } = gbCommonInfo
  let createdTime = new Date().getTime()
  let addressCookie = {
    addressId,
    countryId,
    stateId,
    cityId,
    districtId,
    createdTime,
    memberId: user?.member_id || '',
    loginTimeLast: user?.login_time_last || '',
    isUserHandle: false,
    siteUid: SiteUID,
  }
  const getRealSet = (obj) => {
    let realSetData = {}
    Object.keys(obj).forEach(val => {
      if (obj[val]) {
        realSetData[val] = obj[val]
      }
    })
    return realSetData
  }
  const res = await schttp({
    method: 'POST',
    url: '/api/common/busineseCache/update',
    data: {
      cacheData: getRealSet(addressCookie),
      scanType: 'addrcache'
    }
  })
  if (res?.code == '0') localStorage.setItem('addressCookie', JSON.stringify(getRealSet(addressCookie)))

  let addressCache = {
    ...(countryId ? {[countryId]: countryName} : {}),
    ...(cityId ? {[cityId]: city} : {}),
    ...(stateId ? {[stateId]: state} : {}),
    ...(districtId ? {[districtId]: district} : {}),
  }
  if (JSON.stringify(addressCache) !== '{}') {
    localStorage.setItem('address_cache', JSON.stringify(getRealSet(addressCache)))
  } else {
    localStorage.removeItem('address_cache')
  }
}

export default {
  namespaced: true,
  state: {
    /* title */
    user_address_list: [],
    is_user_update_address: false,
    address_cache: {},
    address_cookie: {},
    drawer_shipping_info_open: false,
    /* title */

    /* cod retuen */
    cod_policy_data: null,
    return_policy_data: null,
    drawer_return_cod_open: 0,
    /* cod retuen */


    /* seller */
    detail_store_seller_info: null,
    drawer_report_open: false,
    drawer_report_us_open: false,
    drawer_report_form_status: false,
    /* seller */

    drawer_shipping_guarantee_open: false,

  },
  mutations: {
    update_drawer_shipping_guarantee_open(state, payload) {
      state.drawer_shipping_guarantee_open = payload
    },
    update_drawer_shipping_info_open(state, payload) {
      state.drawer_shipping_info_open = payload
    },
    update_drawer_return_cod_open(state, payload) {
      state.drawer_return_cod_open = payload
    },
    update_drawer_report_open(state, payload) {
      state.drawer_report_open = payload
    },
    update_drawer_report_us_open(state, payload) {
      state.drawer_report_us_open = payload
    },
    update_drawer_report_form_status(state, payload) {
      state.drawer_report_form_status = payload
    },
    update_user_address_list(state, payload) {
      state.user_address_list = payload
    },
    update_address_cache(state, payload) {
      let { address_cache, addressCookie } = payload || {}
      state.address_cache = address_cache
      state.address_cookie = addressCookie
    },
    update_is_user_update_address(state) {
      state.is_user_update_address = true
    },
    update_detail_store_seller_info(state, payload) {
      state.detail_store_seller_info = payload
    },
    update_return_cod_data(state, payload) {
      let { codPolicyData, returnPolicyData } = payload
      state.cod_policy_data = codPolicyData
      state.return_policy_data = returnPolicyData
    }
  },
  actions: {
    init({ getters, dispatch }) {
      let addressCookie = localStorage.getItem('addressCookie')
      let { shipInfo } = getters.common_info || {}
      if (!addressCookie) {
        if (!shipInfo.countryId) {
          // /bff-api/product/get_goods_detail_user_address
          setAddressCache({
            countryId: 226,
            countryName: 'United States'
          })
          setTimeout(() => {
            let { goodsId, mallCode }  = getters.common_info || {}
            dispatch('newProductDetail/asyncHotModles', {
              goods_id: goodsId,
              mallCode
            }, { root: true})
            dispatch('async_address_cache')
            dispatch('get_user_default_address')
          }, 500)
        } else {
          setAddressCache({
            countryId: shipInfo.countryId,
            countryName: shipInfo.shipping_countryname || 'United States'
          })
          setTimeout(() => {
            dispatch('async_address_cache')
            dispatch('get_user_default_address')
          }, 500)
        }
      } else {
        dispatch('async_address_cache')
        dispatch('get_user_default_address')
      }
    },
    async set_shipping_address_info_to_local_storage({state, dispatch}) {
      let { is_user_update_address, user_address_list } = state
      if (!is_user_update_address && user_address_list) {
        let userAddress = user_address_list?.find?.(item => item.isDefault == 1) || user_address_list[0] || null
        if (!userAddress) return
        let { addressId, countryId, stateId, cityId, districtId, countryName, city, state, district } = userAddress
        await setAddressCache({ addressId, countryId, stateId, cityId, districtId, countryName, city, state, district })
      } else {
        // @@seb todo 旧的shipping drawer 接入后，用户触发的更新地址在这里存入localStorage
      }
      dispatch('async_address_cache')
    },
    async_address_cache({ commit, dispatch }) {
      let address_cache = JSON.parse(localStorage.getItem('address_cache'))
      let addressCookie = JSON.parse(localStorage.getItem('addressCookie'))
      commit('update_address_cache', { address_cache, addressCookie })
      dispatch('get_return_and_cod_policy')
    },
    async get_user_default_address({ commit, dispatch, state, getters }) {
      if (!isLogin()) return
      const { info = {} } = await schttp({
        method: 'GET',
        url: '/api/user/addressbook/limitAddr/query',
        params: {
          isSiteLimit: 1
        },
      })
      const { address = [] } = info || {}
      commit('update_user_address_list', address)
      let addressId = getLocalStorage('addressCookie')?.addressId || {}
      if (!addressId) {
        let { goodsId, mallCode }  = getters.common_info || {}
        let userAddress = address?.find?.(item => item.isDefault == 1) || state.user_address_list[0] || null
        if (addressId != userAddress.addressId) {
          dispatch('set_shipping_address_info_to_local_storage')
          setTimeout(() => {
            dispatch('newProductDetail/asyncHotModles', {
              goods_id: goodsId,
              mallCode
            }, { root: true})
          }, 500)
        }
      } else {
        dispatch('set_shipping_address_info_to_local_storage')
      }
    },
    async get_detail_store_seller_info({ state, getters ,commit }) {
      if (state.detail_store_seller_info) return null
      const { businessModel, storeInfo } = getters.common_info || {}
      const { sellerSource, sellerId } = storeInfo
      const params = { business_model: businessModel, sellerSource, sellerId }
      const timeout = ms => new Promise((resolve) => setTimeout(() => resolve(), ms)) // 解决首次请求过快导致loading闪现问题
      let res = await Promise.all([schttp({
        method: 'GET',
        url: '/api/productInfo/sellerInfo/get',
        params,
      }), timeout(400)])
      commit('update_detail_store_seller_info', res[0])
    },
    async get_return_and_cod_policy({state, getters ,commit}) {
      const { goodsSn: skc, businessModel = 0, goodsId: goods_id, catId: cat_id, countryId: country_id, langPath, mallCode } = getters.common_info || {}
      const returnPolicyData = []
      let codPolicyData = {}
      const params = {
        skc,
        country_id,
        mall_code: mallCode,
        businessModel,
        goods_id,
        cat_id
      }
      const result = await schttp({
        method: 'GET',
        url: '/api/productInfo/returnAndCodPolicy/get',
        params,
      })
      // 存在COD
      if (result?.cod_policy && result?.cod_policy?.codTitle) {
        // 实时获取上下限金额
        const { info: codInfo = {} } = await schttp({
          url: '/api/productInfo/getCodConfigFeeCon/get',
          params: {
            countryId: country_id || '226', // 美国 226 兜底
          }
        })
        const { cod_service_fee = {}, cod_amount_limit = {} } = codInfo
        let codFeeInfo =  {
          orderMinimumAmount: cod_amount_limit?.min_amount?.amountWithSymbol || '',
          orderMaximumAmount: cod_amount_limit?.max_amount?.amountWithSymbol || '',
          serviceCharge: cod_service_fee?.amount?.amountWithSymbol || ''
        }
        const { codTitle, codDescription } = result?.cod_policy
        let codDescriptionRawText = codDescription.replace(/href=\"\//, `href=\"${langPath}\/`)
        const { orderMinimumAmount, orderMaximumAmount, serviceCharge } = codFeeInfo
        const placeholders = {
          orderMinimumAmount,
          orderMaximumAmount,
          serviceCharge
        }
        const codContentRes = codDescriptionRawText.replace(/\{([^}]+)\}/g, (match, placeholder) => placeholders[placeholder] || match)
        codPolicyData = {
          title: codTitle,
          content: codContentRes
        }
      }
      result?.delivery && returnPolicyData.push({ ...result?.delivery, type_id: 2 })
      result?.return_policy && returnPolicyData.push({ ...result?.return_policy, type_id: 1 })
      returnPolicyData.forEach(i => {
        if (i?.content) {
          i.content = i.content.replace(/href=\"\//, `href=\"${langPath}\/`)
        }
      })
      commit('update_return_cod_data', {codPolicyData, returnPolicyData})
    },
  },
  getters: {
    common_info(state, getters, rootState, rootGetters) {
      let { GB_cssRight, langPath, lang, SiteUID, host, user, GB_cssRight_rp } = gbCommonInfo || {}
      let fsAbt = rootGetters['productDetail/common/fs_abt']
      let language = rootGetters['productDetail/common/language_v2']

      let goodsSn = rootGetters['productDetail/common/goods_sn']
      let goodsId = rootGetters['productDetail/common/goods_id']
      let skuInfo = rootGetters['productDetail/common/sku_info']
      let skuList = rootGetters['productDetail/common/sku_list']
      let mallInfo = rootState.productDetail.coldModules.mallInfo
      const mallCode = rootGetters['productDetail/common/mall_code']
      let isPaidUser = rootGetters['productDetail/common/is_paid_user']
      let sheinClubPromotionInfo = rootGetters['productDetail/promotion/shein_club_promotion_info']

      let storeInfo = rootGetters['newProductDetail/ShippingEnter/storeInfo'] // @@seb old
      let labelList = rootGetters['newProductDetail/ShippingEnter/labelList'] // @@seb old
      let hasDetailStoreSellerInfo = rootGetters['newProductDetail/ShippingEnter/hasDetailStoreSellerInfo'] // @@seb old
      let businessModel = rootGetters['newProductDetail/ShippingEnter/business_model'] // @@seb old
      let catId = rootGetters['productDetail/common/cat_id']

      let shipInfo = rootGetters['productDetail/ship_info'] // @@sebtodo
      let countryId = state?.address_cookie?.countryId || '226'
      let isCustomization = rootGetters['newProductDetail/common/isCustomization'] // @@seb old
      
      return {
        GB_cssRight,
        GB_cssRight_rp,
        langPath,
        lang,
        SiteUID,
        host,
        user,
        fsAbt,
        language,
        goodsSn,
        goodsId,
        skuInfo,
        skuList,
        shipInfo,

        storeInfo,
        labelList,
        hasDetailStoreSellerInfo,
        businessModel,
        catId,
        countryId,
        isPaidUser,
        sheinClubPromotionInfo,
        mallInfo,
        mallCode,
        isCustomization
      }
    },
    quick_ship_content(state, getters, rootState, rootGetters){ // N日达 或者 qs的文案
      let { quickShipContent } = rootGetters['productDetail/ship_info'] || {}
      return quickShipContent
    },
    quick_ship_type(state, getters, rootState, rootGetters){ // N日达 或者 qs的文案
      let { quickShipType } = rootGetters['productDetail/ship_info'] || {}
      return quickShipType
    },
    quick_ship_date(state, getters, rootState, rootGetters){ // N日达 或者 qs的文案
      let { quickShipDate } = rootGetters['productDetail/ship_info'] || {}
      return quickShipDate
    },
    shipping_title(state, getters, rootState, rootGetters) {
      let { language, shipInfo, mallInfo, mallCode } = getters.common_info || {}
      let title = mallInfo?.mallInfoList?.length && mallInfo?.isShowMall == '1' ? template(mallInfo?.mallInfoList?.find?.(item => item.mall_code == mallCode)?.mall_name || '', language.SHEIN_KEY_PWA_20781) : language.SHEIN_KEY_PWA_16266
      let { address_cache, address_cookie, is_user_update_address, user_address_list } = state || {}
      let detail = ''
      if (isLogin() && !is_user_update_address && user_address_list && user_address_list.length) {
        let { district, city, state } = user_address_list?.find?.(item => item.isDefault == 1) || user_address_list[0] || {}
        detail = [district, city, state, shipInfo?.shipping_countryname].join(' ')
      } else {
        let { districtId, stateId, cityId, countryId } = address_cookie || {}
        detail = [address_cache?.[districtId] || '', address_cache?.[cityId] || '', address_cache?.[stateId] || '', shipInfo?.shipping_countryname || address_cache?.[countryId]].join(' ')
      }
      return {
        title,
        detail
      }
    },

    shipping_content(state, getters, rootState, rootGetters) {
      // title 下，邮费、时效、会员信息、延迟信息
      let { fsAbt, skuInfo, skuList, shipInfo, isPaidUser, sheinClubPromotionInfo } = getters.common_info || {}
      let { quickshipdetailshow, DetailPageQuickShipShow, itemfreeshipping, sheinclubprice, ShippingLogisticsTime } = fsAbt || {}
      let threePsellerdelivery = fsAbt?.['3Psellerdelivery']?.p?.['3Psellerdelivery'] || ''
      let { Quickshipdetailfloorshow } = quickshipdetailshow?.p || {}
      let skuLargeShip = skuInfo?.largeShip || 0
      let isSupportQuickShip = skuInfo ? skuInfo.isSupportQuickShip == 1 : !!skuList.find(item => item.isSupportQuickShip == 1)
      let isQuickShipAbtMatch = DetailPageQuickShipShow?.param == 'DetailShowQuick' && (Quickshipdetailfloorshow === 'quickship' || Number(Quickshipdetailfloorshow) > 0)

      /* icon */
      let ICON_MAP = {
        normal: {
          name: 'sui_icon_freeshipping_flat_24px',
          color: '#198055',
        },
        quick: {
          name: 'sui_icon_qucikship_flat_16px',
          color: '#198055',
        },
        land: {
          name: 'sui_icon_landship_flat_18px',
          color: '#198055',
        },
        sea: {
          name: 'sui_icon_seaship_flat_18px',
          color: '#198055',
        }
      }
      const getIcon = () => {
        if (skuLargeShip) {
          return skuLargeShip == 2 ? ICON_MAP.land : ICON_MAP.sea
        }
        if (isQuickShipAbtMatch && skuList?.find?.(item => item.isSupportQuickShip == 1)) {
          return ICON_MAP.quick
        }
        return ICON_MAP.normal
      }
      let icon = getIcon()
      /* icon */

      /* quick ship 描述 */
      let descQuickShipTimeHtml = ''
      if (
        isSupportQuickShip &&
        !(Quickshipdetailfloorshow === 'quickship' || Number(Quickshipdetailfloorshow) > 0) &&
        DetailPageQuickShipShow?.param == 'DetailShowQuick'
      ) descQuickShipTimeHtml = shipInfo?.quickShipDesc
      /* quick ship 描述 */

      /* 费用相关 */
      const getShipPriceHtml = () => {
        if (shipInfo?.isOutExposedShippingThreshold != 1) return ''
        // qs ⬇️
        if(isQuickShipAbtMatch && shipInfo?.hasQuickShipTime == 1) {
          if (skuInfo) {
            if (skuInfo.isSupportQuickShip == 1) {
              return shipInfo?.shippingContentTipsOfQuickShip
            }
            // 大件物流 ⬇️
            if (skuLargeShip && !descQuickShipTimeHtml) {
              let largeRes = shipInfo?.largeShipInfoList?.find?.(item => item?.type == skuLargeShip)?.tips
              if (largeRes) return largeRes
            }
            return shipInfo?.shippingContentTipsByCountryId
          } else {
            let supportQuickShipSkus = skuList.filter(item => item.isSupportQuickShip == 1)
            if (supportQuickShipSkus.length) {
              if (supportQuickShipSkus.length == skuList.length) {
                return shipInfo?.shippingContentTipsOfQuickShip
              } else {
                let { shippingContentOfPostage } = shipInfo || {}
                return `${shipInfo?.shippingContentTipsOfQuickShip}${shippingContentOfPostage ? `<p>${shippingContentOfPostage}</p>` : ''}`
              }
            } else {
              return shipInfo?.shippingContentTipsByCountryId
            }
          }
        }

        // 大件物流 ⬇️
        if (skuLargeShip && !descQuickShipTimeHtml) {
          let largeRes = shipInfo?.largeShipInfoList?.find?.(item => item?.type == skuLargeShip)?.tips
          if (largeRes) return largeRes
        }

        // 普通物流 ⬇️
        if (shipInfo?.allNotSupportFreeShippingTips) return shipInfo?.allNotSupportFreeShippingTips
        return shipInfo?.shippingContentTipsByCountryId
      }
      let shipPriceHtml = getShipPriceHtml()
      /* 费用相关 */

      /* 时效 */
      let hasDayPercentsAnalyze = null
      const getShipTimeHtml = () => {
        if (shipInfo?.isOutExposedShippingTime != 1) return ''

        let { shippingDayPercentDesc, hasDayPercents, shipping_time_information } = shipInfo || {}
        let percentDes = (shippingDayPercentDesc && ShippingLogisticsTime?.param?.LogisticsTime == 'B') ?
          `<p>${shippingDayPercentDesc}${hasDayPercents == 1 ?`<i aria-hidden='true' class='suiiconfont sui_icon_doubt_15px_2'></i>` : '' }</p>` :
          ''
        if (hasDayPercents) {
          hasDayPercentsAnalyze = {
            id: '1-6-4-59',
            data: {
              days: shipInfo?.shippingDays,
              shipping_methods: shipInfo?.transportType
            }
          }
        }

        if (isQuickShipAbtMatch && shipInfo?.hasQuickShipTime == 1) {
          if (skuInfo) {
            if (skuInfo.isSupportQuickShip == 1) {
              let { ndayDeliveryBusinessDayDesc, shippingTimeInformationOfQuickShip } = shipInfo || {}
              let result = ''
              if (shippingTimeInformationOfQuickShip) {
                result = shippingTimeInformationOfQuickShip
              }
              if (ndayDeliveryBusinessDayDesc) {
                if (result) return `${result}<p>${ndayDeliveryBusinessDayDesc}</p>` 
                return ndayDeliveryBusinessDayDesc
              }
              return result
            } else {
              if (skuLargeShip && !descQuickShipTimeHtml) {
                let largeRes = shipInfo?.largeShipInfoList?.find?.(item => item?.type == skuLargeShip)
                if (largeRes) {
                  let { timeInfo, dayPercentDesc } = largeRes || {}
                  if (timeInfo) {
                    return `${timeInfo}${dayPercentDesc ? `,<p>${dayPercentDesc}</p>` : ''}`
                  }
                }
              }
              if (shipInfo?.quickShipDesc && threePsellerdelivery == 'show' && shipInfo?.isHideForLocalShop == 1) return ''
              return `${shipInfo?.shipping_time_information}${percentDes}`
            }
          } else {
            let supportQuickShipSkus = skuList.filter(item => item.isSupportQuickShip == 1)
            if (supportQuickShipSkus.length) {
              if (supportQuickShipSkus.length == skuList.length) {
                let { ndayDeliveryBusinessDayDesc, shippingTimeInformationOfQuickShip } = shipInfo || {}
                let result = ''
                if (shippingTimeInformationOfQuickShip) {
                  result = shippingTimeInformationOfQuickShip
                }
                if (ndayDeliveryBusinessDayDesc) {
                  if (result) return `${result}<p>${ndayDeliveryBusinessDayDesc}</p>` 
                  return ndayDeliveryBusinessDayDesc
                }
                return result
              } else {
                let { shippingContentOfQuickShip, shippingContentOfNormal, ndayDeliveryBusinessDayDesc } = shipInfo || {}
                let result = ''
                for (let i of [shippingContentOfQuickShip, shippingContentOfNormal, ndayDeliveryBusinessDayDesc]) {
                  if (i) result = result + `<p>${i}</p>` 
                }
                return result
              }
            } else {
              if (shipInfo?.quickShipDesc && threePsellerdelivery == 'show' && shipInfo?.isHideForLocalShop == 1) return ''
              return `${shipInfo?.shipping_time_information}${percentDes}`
            }
          }
        }

        if (skuLargeShip && !descQuickShipTimeHtml) {
          let largeRes = shipInfo?.largeShipInfoList?.find?.(item => item?.type == skuLargeShip)
          if (largeRes) {
            let { timeInfo, dayPercentDesc } = largeRes || {}
            if (timeInfo) {
              return `${timeInfo}${dayPercentDesc ? `,<p>${dayPercentDesc}</p>` : ''}`
            }
          }
        }

        // 普通时效
        if (shipInfo?.quickShipDesc && threePsellerdelivery == 'show' && shipInfo?.isHideForLocalShop == 1) return ''
        return `${shipping_time_information}${percentDes}`
      }
      let shipTimeHtml = getShipTimeHtml()
      /* 时效 */

      /* 延迟提醒 */
      let delayHtml = ['freeremind', 'remind'].includes(itemfreeshipping?.p?.itemfreeshipping) && shipInfo?.suppleDesc
      /* 延迟提醒 */


      /* shipping shein club */
      let sheinClubText = (() => {
        if (!rootGetters['newProductDetail/common/sheinClubShippingSiteConfig'].FREE_SHIPPING_TIMES) return null
        if (isPaidUser) return null
        if (sheinclubprice?.p !== 'exposure_prime_price') return null
        return rootGetters['newProductDetail/ShippingEnter/sheinClubShppingText']
      })()
      let sheinClubAnalysisData = null
      if (sheinClubText) {
        let { primeLevel, totalSaving } = sheinClubPromotionInfo?.aggregateMemberResult || {}
        let labeltype =
          !!rootGetters['newProductDetail/common/sheinClubContent']?.goodsDetail_shipping?.shipping_all?.fieldContent ?
          'goodsDetail_shipping|shipping_all' :
          ''
        sheinClubAnalysisData = {
          id: '1-6-1-64',
          data: {
            prime_level: primeLevel,
            total_saving: totalSaving,
            location: 'shipping',
            from: 2,
            labeltype
          }
        }
      }
      /* shipping shein club */
      return {
        icon,
        descQuickShipTimeHtml,
        shipPriceHtml,
        shipTimeHtml,
        delayHtml,
        sheinClubText,
        sheinClubAnalysisData,
        hasDayPercentsAnalyze
      }
    },


    /* 下方样式统一的项  */
    shipping_cod_policy(state, getters) {
      // 货到付款政策
      let { cod_policy_data } = state || {}
      if (!cod_policy_data || !cod_policy_data?.title) return null
      let { title, content } = cod_policy_data
      return { title, content, drawerTitle: title }
    },
    shipping_return_policy(state, getters) {
      let { return_policy_data } = state || {}
      let { language, isCustomization, fsAbt } = getters.common_info || {}
      if (!return_policy_data || !return_policy_data?.length) return null
      let item = return_policy_data?.find?.(item => item.type_id == 1) || {}
      let {is_return: isReturn, is_refund_able, is_new_out_title, refund_out_title, content} = item
      let title = (() => {
        if (isReturn == 1) return item.title
        if (fsAbt?.Returnorexchange?.p?.Returnorexchange === 'new' && isCustomization) return language.SHEIN_KEY_PWA_33016
        if (is_refund_able) return refund_out_title
        if (is_new_out_title) return language.SHEIN_KEY_PWA_31477
        if (fsAbt?.Returnorexchange?.p?.Returnorexchange === 'new') return item.is_return == 3 ? language.SHEIN_KEY_PWA_33017 : language.SHEIN_KEY_PWA_33018
        return language.SHEIN_KEY_PWA_20503
      })()
      return { title, isReturn, content, drawerTitle: item.title }
    },
    shipping_guarantee(state, getters) {
      // 购物保障
      const { fsAbt, language } = getters.common_info || {}
      const showShippingGuarantee = fsAbt?.Guarantee?.p?.Guarantee === 'show'
      if (!showShippingGuarantee) return null
      const { SHEIN_KEY_PWA_27597, SHEIN_KEY_PWA_27580, SHEIN_KEY_PWA_27410, SHEIN_KEY_PWA_27411, SHEIN_KEY_PWA_27412 } = language
      return {
        title: SHEIN_KEY_PWA_27597,
        content: [ SHEIN_KEY_PWA_27580, SHEIN_KEY_PWA_27410, SHEIN_KEY_PWA_27411, SHEIN_KEY_PWA_27412 ],
        language
      }
    },
    shipping_seller(state, getters) {
      let { detail_store_seller_info } = state || {}
      const { fsAbt, language, storeInfo, labelList, skuInfo, skuList, SiteUID, hasDetailStoreSellerInfo, goodsId, goodsSn, GB_cssRight_rp, langPath, businessModel } = getters.common_info || {}
      let { detailshowsellerinfo, localwarehouse, moresellerinfo, obligationsseller, ReportItem } = fsAbt || {}

      if (detailshowsellerinfo?.param?.detailshowsellerinfo != 'show') return null

      /* title */
      let { storeType, title, tocPerformParty } = storeInfo
      let hasLabel = labelList?.some(item => item.labelCode === 'sold_by_storename')
      let is1PStore = storeType === 6
      let isPlatform = storeType === 1
      let soldByVal = (isPlatform ? title : language.SHEIN_KEY_PWA_24376) || ''
      if(is1PStore && hasLabel){
        soldByVal = title || ''
      }
      let shipFromVal = (isPlatform ? (tocPerformParty === 1 ? language.SHEIN_KEY_PWA_24376 : title) : language.SHEIN_KEY_PWA_24376) || ''
      if (!shipFromVal && !soldByVal) return null
      let isSameVal = soldByVal === shipFromVal
      let sellerTitle = template(shipFromVal, language.SHEIN_KEY_PWA_24372)
      if (!isSameVal) {
        sellerTitle = `${language.SHEIN_KEY_PWA_27405} ${soldByVal} ${shipFromVal && '&'} ` + `${language.SHEIN_KEY_PWA_27406} ${shipFromVal}`
      }
      /* title */

      let localWarehouseText = (() => {
        if (localwarehouse?.param?.localwarehouse != 'show') return ''
        if (skuInfo ? skuInfo.isSupportQuickShip != 1 : !skuList.find(item => item.isSupportQuickShip == 1)) return ''
        let isEur = ['pwfr', 'pwuk', 'pwde', 'pwes', 'pwit', 'pwnl', 'mse', 'meur', 'mpl', 'mpt', 'mch', 'mroe', 'meuqs'].includes(SiteUID)
        let isUs = !!~SiteUID.indexOf('us')
        let isUk = !!~SiteUID.indexOf('uk')
        if (isUs) return language.SHEIN_KEY_PWA_31569
        if (isUk) return language.SHEIN_KEY_PWA_31571
        if (isEur) return language.SHEIN_KEY_PWA_31569
        return ''
      })()

      let showSellerIcon = hasDetailStoreSellerInfo && moresellerinfo?.p?.moresellerinfo === 'show'

      let sellerConnectionInfo = (() => {
        if (!showSellerIcon) return null
        if (!detail_store_seller_info) return null
        const { businessNameValue, businessAddressValue, fieldValues = [], isDirectSaleStore, codeRegisterNo, taxInfoList, tradeRegisterNumberValue, vatNumberValue } = detail_store_seller_info || {}
        let { SHEIN_KEY_PWA_24725, SHEIN_KEY_PWA_24721, SHEIN_KEY_PWA_24727, SHEIN_KEY_PWA_24729, SHEIN_KEY_PWA_24730, SHEIN_KEY_PWA_24731 } = language
        let businessName
        let businessAddress
        let tradeRegisterNumber
        let vatNumber
        if (isDirectSaleStore) {
          businessName = businessNameValue
          businessAddress = businessAddressValue
          tradeRegisterNumber = tradeRegisterNumberValue
          vatNumber = vatNumberValue
        } else {
          let item3 = null, item4 = null, item6 = null
          for (let item of fieldValues) {
            if (item.fieldType === 3) {
              item3 = item
            } else if (item.fieldType === 4) {
              item4 = item
            } else if (item.fieldType === 6) {
              item6 = item
            }
          }
          businessName = item3?.fieldValue || item4?.fieldValue || SHEIN_KEY_PWA_24730
          businessAddress = item6?.fieldValue || SHEIN_KEY_PWA_24730
          tradeRegisterNumber = codeRegisterNo || SHEIN_KEY_PWA_24730
          if(taxInfoList?.length) {
            if(taxInfoList.length == 1) {
              vatNumber = taxInfoList[0].supplierTaxNumber
            } else {
              let vatInfo = taxInfoList.find(item => item.saleCountry == 'FR' && item.supplierTaxNumber) || null
              vatInfo = vatInfo ? vatInfo : taxInfoList.find(item => item.saleCountry == 'DE' && item.supplierTaxNumber) || null
              vatInfo = vatInfo ? vatInfo : taxInfoList.find(item => item.supplierTaxNumber) || null
              vatNumber = vatInfo ? vatInfo.supplierTaxNumber : ''
            }
          } else {
            vatNumber = SHEIN_KEY_PWA_24731
          }
        }
        return [{
          name: SHEIN_KEY_PWA_24725,
          value: businessName
        }, {
          name: SHEIN_KEY_PWA_24721,
          value: businessAddress
        }, {
          name: SHEIN_KEY_PWA_24727,
          value: tradeRegisterNumber
        }, {
          name: SHEIN_KEY_PWA_24729,
          value: vatNumber
        }]
      })()
      

      let showReport = false
      let { Reportshow, limitstore } = ReportItem?.p || {}
      if (Reportshow === 'show') {
        showReport = limitstore === 'no' || (limitstore === 'yes' && storeInfo.storeType == 1)
      }

      let reportData = {
        language,
        goodsSn,
        hasCall: ReportItem?.p?.call === 'yes',
        storeStoreType: storeInfo.storeStoreType,
        storeCode: storeInfo.storeCode,
        businessModel
      }

      return {
        sellerTitle,
        localWarehouseText,
        showSellerIcon,
        sellerAnalysisData: { goods_id: goodsId, store_code: storeInfo.storeCode },
        sellerConnectionInfo,
        language,
        goodsId,
        showObligationsseller: obligationsseller?.p?.obligationsseller === 'show',
        GB_cssRight_rp,
        showReport,
        langPath,
        isUs: !!~SiteUID.indexOf('us'),
        reportData,
      }
    }
  },
}
