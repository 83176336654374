
const mutations = {
  assignState (state, payload) {
    Object.assign(state, payload)
  },
  updateNecessaryData (state, payload) {
    Object.assign(state.necessaryData, payload)
  },
  setPageType(state, payload) {
    state.pageType = payload
  }
}

export default mutations
