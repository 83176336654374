export default {
  namespaced: true,
  state: {
    last_click_color: '',
  },
  getters: {
    main_sale_attr_init(state, getters, rootState, rootGetters) {
      const findMyShadeH5Url = rootState.productDetail.coldModules?.saleAttr?.findMyShadeH5Url || ''
      const cache_screen_data = rootGetters['productDetail/common/cache_screen_data']
      const main_sale_attribute = rootGetters['productDetail/common/main_sale_attribute']
      const cur_main_sale_attribute = rootGetters['productDetail/common/cur_main_sale_attribute']
      const main_sale_attr_desc = rootGetters['productDetail/common/main_sale_attr_desc']
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const language_v2 = rootGetters['productDetail/common/language_v2']
      const { selectcolor, goodsMainAttPicShow } = fs_abt || {}
      const { mainSaleAttrShowMode, canShowLargeImg } = main_sale_attribute || {}
      const { attr_name, attr_value, attr_id, attr_value_id, attrImg } = cur_main_sale_attribute || {}
      const { attr_image } = attrImg || {}
      // 展示大图icon
      const isShowColorMoreIcon = mainSaleAttrShowMode === '2'
      // 标题
      const colorTitleDesc = (mainSaleAttrShowMode !== '1' && attr_value) ? `: ${attr_value}` : ''
      const colorBlockText = cache_screen_data ? 'Color' : `${attr_name || ''}${colorTitleDesc || ''}`

      // 是否支持大图
      const supportLargeImageList = canShowLargeImg === '1' && selectcolor?.param?.largeimage === 'on'

      // 是否展示FindMyShade入口
      const isShowFindMyShadeEntry = !!findMyShadeH5Url
      const findMyShadeParams = {
        ruleId: 'rec_ver:S93V1.0',
        urlQuery: 'main_attr=' + (attr_value_id ? attr_id + '_' + attr_value_id : ''),
        urlFilter: /&?attr_ids=(\d|_)+/g,
      }

      // 是否展示色块上的tag
      const showColorsTag = !!rootState.productDetail.hotModules?.productInfo?.goods_id

      // 主销售属性描述样式
      const abtInfo = rootGetters['productDetail/product_detail_abt_info']
      const mainSaleAttrDesc = abtInfo?.goodssaleattribute?.param?.color_details === 'on' && main_sale_attr_desc ? `<span style="color: #A86104;">${main_sale_attr_desc}</span>` : main_sale_attr_desc
      const mainSaleAttrDescText = main_sale_attr_desc ? `${attr_value}: ${mainSaleAttrDesc}` : ''

      const showMainAttrPic = !mainSaleAttrDescText && attr_image && goodsMainAttPicShow?.p?.goodsMainAttPicShow === 'on'
      const mainAttrPicInfo = showMainAttrPic ? {
        textDetail: `${language_v2?.SHEIN_KEY_PWA_24732}: `,
        attr_value,
        attr_image,
      } : null
      
      return {
        isShowColorMoreIcon,
        colorBlockText,
        supportLargeImageList,
        isShowFindMyShadeEntry,
        findMyShadeParams,
        mainSaleAttrDescText,
        mainAttrPicInfo,
        showColorsTag,
      }
    },
    is_show_attrnewicon(state, getters, rootState, rootGetters) {
      const abtInfo = rootGetters['productDetail/product_detail_abt_info']
      const attrnewiconAb = abtInfo?.goodssaleattribute?.param?.attrnewicon
      if(attrnewiconAb === 'A') {
        // 满足A，全是新品则不展示
        return !getters.colors_info?.every?.(item => item.isNew == '1')
      }
      return attrnewiconAb === 'B'
    },

    is_show_colors(state, getters, rootState, rootGetters) {
      // 性能预取模式
      const { colors_info, main_sale_attr_init } = getters
      const cache_screen_data = rootGetters['productDetail/common/cache_screen_data']
      const main_sale_attribute = rootGetters['productDetail/common/main_sale_attribute']
      const main_sale_attr_desc = rootGetters['productDetail/common/main_sale_attr_desc']
      const { mainSaleAttrShowMode } = main_sale_attribute || {}
      const { mainAttrPicInfo } = main_sale_attr_init || {}
      if (cache_screen_data) {
        return !!cache_screen_data?.relatedColor?.length
      }
      // --end
      return (
        (mainSaleAttrShowMode === '1' ? colors_info?.length > 0 : colors_info?.length > 1)
        || !!mainAttrPicInfo
        || !!main_sale_attr_desc
      )
    },

    is_show_Main_Attr(state, getters, rootState, rootGetters) {
      const { is_skc_prepose, is_show_colors } = getters
      const show_sale_attr_fold_entry = rootGetters['productDetail/SizeAttrFold/show_sale_attr_fold_entry']
      // skc前置 + 销售属性折叠不展示
      if (is_skc_prepose || show_sale_attr_fold_entry) return false
      return is_show_colors
    },
    skc_prepose_val(state, getters, rootState, rootGetters) {
      const cache_screen_fs_abt = rootState.productDetail.cache_screen_fs_abt
      const goodsdetailsSKCprefix = cache_screen_fs_abt?.goodsdetailsSKCprefix?.p?.goodsdetailsSKCprefix

      //TODO: 性能预取模式，看起来是不会走到这里的，前置有骨架屏判断
      // 性能预取应该也迁移到java接口，这样不用维护两套逻辑
      const cache_screen_data = rootGetters['productDetail/common/cache_screen_data']
      if (cache_screen_data) {
        const { isCccSkcPrepose, relatedColor, main_sale_attr_show_mode } = cache_screen_data || {}
        return (
          (main_sale_attr_show_mode == 2 
          && relatedColor?.length > 1 
          && !!isCccSkcPrepose 
          && goodsdetailsSKCprefix) || ''
        )
      }
      // --end
      const main_sale_attribute = rootGetters['productDetail/common/main_sale_attribute']
      if (main_sale_attribute?.skcPrepose === '0') { 
        return '' 
      }
      return goodsdetailsSKCprefix
    },
    is_skc_prepose(state, getters) {
      return getters.skc_prepose_val === 'A' || getters.skc_prepose_val === 'C'
    },
    color_type(state, getters, rootState, rootGetters) {
      const cccDetailsTemplate = rootState.productDetail.coldModules.productInfo.cccDetailsTemplate || {}
      const main_sale_attribute = rootGetters['productDetail/common/main_sale_attribute']
      if (getters.is_skc_prepose) return 'prepose'
      if (main_sale_attribute?.mainSaleAttrShowMode == '1') return 'text'
      if(['A'].includes(getters.colorpickAb)) return 'default'
      if(['B'].includes(getters.colorpickAb)) return 'img'
      if (cccDetailsTemplate?.tempType == 1 && cccDetailsTemplate.content?.[0]?.colorType == 2) {
        return 'img'
      } 
      return 'default'

    },
    colorpickAb(state, getters, rootState, rootGetters){
      const abtInfo = rootGetters['productDetail/product_detail_abt_info']
      return abtInfo?.colorpick?.param?.colorpick || ''
    },
    prepose_have_scroll(state, getters){
      if(getters.skc_prepose_val === 'C'){
        if(getters.prepose_color_type === 'Thumbnail' && getters.colors_info?.length > 7){ // Thumbnail 7
          return true
        }else if(getters.prepose_color_type === 'colorBlock' && getters.colors_info?.length > 9){ // colorBlock 9
          return true
        }
      }
      return false
    },
    prepose_color_type(state, getters, rootState){
      if(getters.skc_prepose_val === 'C'){
        getters.skc_prepose_val
        const cccDetailsTemplate = rootState.productDetail.coldModules.productInfo.cccDetailsTemplate || {}
        if (cccDetailsTemplate?.tempType == 1 && cccDetailsTemplate.content?.[0]?.colorType == 2) {
          return 'Thumbnail'
        } 
        return 'colorBlock' 
      }
      return 'Thumbnail'
    },
    more_skc_price_infos_map(state, getters, rootState) {
      return rootState.productDetail.hotModules?.saleAttr?.moreSkcPriceInfos?.reduce((res, item) => {
        const { goodsId, lowerPriceFlag } = item || {}
        if (goodsId) {
          res[goodsId] = !!+lowerPriceFlag
        }
        return res
      }, {})
    },
    colors_info(state, getters, rootState, rootGetters) {
      // 性能预取模式
      const cache_screen_data = rootGetters['productDetail/common/cache_screen_data']
      if (cache_screen_data && typeof gbCommonInfo !== 'undefined') {
        const { relatedColor } = cache_screen_data || {}
        const { LAZY_IMG } = gbCommonInfo || {}
        return relatedColor?.map?.(item => {
          return {
            isSoldoutColor: false,
            goods_id: item?.goods_id,
            goods_color_image: item?.color_img,
            goods_image: item.thumbnail || LAZY_IMG,
          }
        }) || []
      }
      // --end
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const main_sale_attribute_list = rootGetters['productDetail/common/main_sale_attribute_list']
      // let moreSkcPriceInfos = rootState.hotModules?.saleAttr?.multiLevelSaleAttribute?.moreSkcPriceInfos || []
      // let lowestSkcsGoodsIds = []
      // for(let i of moreSkcPriceInfos) {
      //   if (i.lowerPriceFlag == 1) lowestSkcsGoodsIds.push(i.goodsId)
      // }
      return main_sale_attribute_list?.map?.(item => {
        const { isEco } = item || {}
        return {
          ...item,
          lowPrice: !!getters.more_skc_price_infos_map?.[item.goods_id],
          isEco: fs_abt?.evolushein?.param?.evolushein === 'show' ? isEco : undefined
        }
      }) || []
    },
    hot_goods_sn_list(state, getters) {
      return getters.colors_info?.reduce((res, item) => {
        if (item.hot_color === '1' && !+item.isEco) {
          res.push(item?.goods_sn)
        }
        return res
      }, []) || []
    },
  },
  mutations: {
    update_last_click_color(state, payload) {
      state.last_click_color = payload
    },
  }
}
  
