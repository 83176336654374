
import Vue from 'vue'

const mutations = {
  SET_DATA (state, payload) {
    state.context = payload
  },
  assignState (state, payload) {
    Object.assign(state, payload)
  },
  assignList(state, payload) {
    if (!payload || !payload.channelId) return

    const updateFlag = (
      !state.list?.hasOwnProperty(payload.channelId) ||
      !Object.keys(state.list?.[payload.channelId] || {}).length ||
      payload.clientRefresh
    )

    if (updateFlag) {
      Vue.set(state.list, payload.channelId, payload.content)
    }
  },
}

export default mutations
