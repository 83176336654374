import { isSwitchBffDetail } from 'public/src/pages/common/bff-new/index.js'
import pageContextConstant from 'public/src/pages/goods_detail/utils/pageContextConstant.js'
import { 
  PRODUCT_INFO_INIT,
  SALE_ATTR_INIT,
  STORE_INFO_INIT,
  PRODUCT_LABEL_INIT,
  BRAND_DETAIL_INFO_INIT,
} from './constants.js'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'

const mutations = {
  init_cold(state, payload) {
    const {
      productInfo = {},
      saleAttr = {},
      storeInfo = {},
      productLabel = {},
      mallInfo = {},
      ssrPageInfo,
      brandDetailInfo = {},
    } = payload || {}
    
    PRODUCT_INFO_INIT.forEach(key => {
      state.coldModules.productInfo[key] = productInfo?.[key]
    })
    SALE_ATTR_INIT.forEach(key => {
      state.coldModules.saleAttr[key] = saleAttr?.[key]
    })
    STORE_INFO_INIT.forEach(key => {
      state.coldModules.storeInfo[key] = storeInfo?.[key]
    })
    PRODUCT_LABEL_INIT.forEach(key => {
      state.coldModules.productLabel[key] = productLabel?.[key]
    })
    BRAND_DETAIL_INFO_INIT.forEach(key => {
      state.coldModules.brandDetailInfo[key] = brandDetailInfo?.[key]
    })
    state.coldModules.mallInfo = mallInfo
    this.commit('productDetail/update_common_page_info', ssrPageInfo || null)
    this.commit('productDetail/init_async_cold', payload)
    if (!payload?.productInfo && state.MAIN_BFF_APOLLO?.v1) {
      pageContextConstant.set('closeBff', true)
      this.commit('productDetail/update_main_bff_apollo')
      if (typeof window !== 'undefined' && typeof gbCommonInfo !== 'undefined' && gbCommonInfo?.NODE_SERVER_ENV !== 'production') {
        console.log(
          `%c🚀 BFF接口异常无数据, 降级处理 🚀 `, 
          'font-family:sans-serif;font-size:1.2em; color:white; font-weight:bold; background: linear-gradient(#E47AFF, 26%, #FF0080); border-radius: 4px; padding: 4px',
          '开关: ',
          state.MAIN_BFF_APOLLO,
        )
      }
    }
    // const timer = setTimeout(() => {
    //   clearTimeout(timer)
    //   this.commit('productDetail/init_async_cold', payload)
    // })
  },
  init_async_cold(state, payload) {
    const { 
      productInfo = {},
      productLabel = {},
      saleAttr = {},
      priceInfo = {},
      storeInfo = {},
      brandDetailInfo = {},
      seriesDetailInfo = {},
      mallInfo = {},
      recShopProductReqInfo = {},
      configInfo = {}
    } = payload || {}
    state.coldModules.productInfo = productInfo
    state.coldModules.saleAttr = saleAttr
    state.coldModules.storeInfo = storeInfo
    state.coldModules.productLabel = productLabel
    state.coldModules.priceInfo = priceInfo
    state.coldModules.brandDetailInfo = brandDetailInfo
    state.coldModules.seriesDetailInfo = seriesDetailInfo
    state.coldModules.mallInfo = mallInfo
    state.coldModules.recShopProductReqInfo = recShopProductReqInfo
    state.coldModules.configInfo = configInfo
  },
  update_main_bff_apollo(state, payload) {
    const MAIN_BFF_APOLLO = state.MAIN_BFF_APOLLO || {}
    const mainBffApolloKeys = Object.keys(MAIN_BFF_APOLLO) || []
    const mainBffApolloValues = payload?.apolloConfig || isSwitchBffDetail(mainBffApolloKeys)
    const closeBff = pageContextConstant.get('closeBff')
    mainBffApolloKeys.forEach(key => {
      state.MAIN_BFF_APOLLO[key] = !!(!closeBff && mainBffApolloValues[key])
    })
    // @@sebmock
    // state.MAIN_BFF_APOLLO.v5 = true
    if (state.MAIN_BFF_APOLLO?.v1 && typeof window !== 'undefined' && typeof gbCommonInfo !== 'undefined' && gbCommonInfo?.NODE_SERVER_ENV !== 'production') {
      console.log(
        `%c🚀 GOODS_DETAIL_BFFF 🚀 `, 
        'font-family:sans-serif;font-size:1.2em; color:white; font-weight:bold; background: linear-gradient(#E47AFF, 26%, #FF0080); border-radius: 4px; padding: 4px',
        '开关: ',
        state.MAIN_BFF_APOLLO,
      )
    }
  },
  update_hot(state, payload) {
    const { 
      productInfo,
      store,
      beltInfo,
      storeInfo,
      saleAttr,
      priceInfo,
      detailPromotionFloor,
      userInfo,
      productLabel,
      comment,
      completeCouponInfo,
      shipInfo,
      multiPropertyProducts,
      webProductDetailAbtInfo,
      brandDetailInfo,
      configInfo
    } = payload || {}
    state.hotModules.storeInfo = storeInfo || {}
    state.hotModules.productInfo = productInfo || {}
    state.hotModules.store = store || {}
    state.hotModules.beltInfo = beltInfo || {}
    state.hotModules.saleAttr = saleAttr || {}
    state.hotModules.priceInfo = priceInfo || {}
    state.hotModules.detailPromotionFloor = detailPromotionFloor || {}
    state.hotModules.userInfo = userInfo || {}
    state.hotModules.comment = comment || {}
    state.hotModules.completeCouponInfo = completeCouponInfo || {}
    state.hotModules.productLabel = productLabel || {}
    state.hotModules.shipInfo = shipInfo || {}
    state.hotModules.brandDetailInfo = brandDetailInfo || {}
    state.hotModules.multiPropertyProducts = multiPropertyProducts || {}
    state.hotModules.webProductDetailAbtInfo = webProductDetailAbtInfo || {}
    state.hotModules.configInfo = configInfo || {}


    Vue.nextTick(() => {

      // NEW BFF 第二部分数据
      this.dispatch('productDetail/get_product_second_info')
      
      this.dispatch('productDetail/gift/get_gift_list', productInfo?.giftGoodsSelectId || '')

      setTimeout(() => {
        // 新搭配数据
        this.dispatch('productDetail/get_new_companion_module')
      }, 100)

      setTimeout(() => {
        // NEW BFF 第三部分数据
        this.dispatch('productDetail/get_product_third_info')
        // 更新店铺关注等信息
        this.dispatch('productDetail/DetailSignBoard/init_store_follow_info')
      }, 500)

    })
      

  },
  update_async(state, payload) {
    const {
      currentSkcImgInfo,
      topRanking,
      getTheLookRelatedIdSeries,
      shopRecProducts, 
      multiPropertyProducts,
      machingStyleProducts
    } = payload || {}
    state.asyncModules.currentSkcImgInfo = currentSkcImgInfo                  // 更新细节图
    state.asyncModules.topRanking = topRanking                                // 榜单数据
    state.asyncModules.getTheLookRelatedIdSeries = getTheLookRelatedIdSeries  // 亲子推荐
    state.asyncModules.shopRecProducts = shopRecProducts                      // 店铺&品牌&系列商品推荐
    state.asyncModules.multiPropertyProducts = multiPropertyProducts // 多属性套装
    state.asyncModules.machingStyleProducts = machingStyleProducts  // machingStyle 模块
  },
  update_common_page_info(state, payload) {
    const { 
      PUBLIC_CDN = '',
      LAZY_IMG = '',
      lang = '',
      GB_cssRight = false,
      appLanguage = '',
      GOODS_DETAIL_NEW_COMPANION_LIMIT = {},
    } = payload || gbCommonInfo || {}
    // 仅SSR组件使用, 其他的直接组件内部使用gbCommonInfo
    state.coldModules.commonPageInfo = {
      PUBLIC_CDN,
      LAZY_IMG,
      lang,
      GB_cssRight,
      appLanguage,
      GOODS_DETAIL_NEW_COMPANION_LIMIT,
    }
  },
  async update_cache_screen_fs_abt(state, payload) {
    let abtInfo = payload
    if (!abtInfo && typeof window !== 'undefined') {
      abtInfo = await getUserAbtData()
    }
    const { goodsdetailsSKCprefix, goodssaleattributefold } = abtInfo || {}
    state.cache_screen_fs_abt = {
      goodsdetailsSKCprefix,
      goodssaleattributefold,
    }
  },
  /** TODO 更新搭配数据 */
  update_new_companion_info(state, payload) {
    state.asyncModules.newCompanionInfo = payload
  },
}

export default mutations
