import { transformImg } from '@shein/common-function'

// 轮播类型
const CAROUSELS_TYPE = {
  'GOODS_IMAGE': 'goodsImage',            // 商品图片
  'GOODS_OUTFITS': 'goodsOutfits',        // 搭配套装
  'GOODS_INTEREST': 'goodsInterest',      // 权益保障利益点
  'GOODS_SIZE_BEFORE_OUTFIT': 'goodsSizeBeforeOutfit',            // sizeguide在outfit前面
  'GOODS_SIZE_AFTER_OUTFIT': 'goodsSizeAfterOutfit',              // sizeguide在outfit后面
}

export default {
  namespaced: true,
  state: {
    current_image_id: '',                     // 当前图片ID
    is_show_big_image: false,                // 是否展示大图
    show_outfit_ymal: false  // 是否在ymal展示outfit的插坑
  },
  mutations: {
    // 更新当前页码
    update_current_image_id(state, payload = '') {
      state.current_image_id = payload
    },
    // 是否展示大图
    update_is_show_big_image(state, payload = false) {
      state.is_show_big_image = payload
    },
    // 是否在ymal展示outfit的插坑
    update_show_outfit_ymal(state, payload = false) {
      state.show_outfit_ymal = payload
    },
  },
  getters: {
    // 商品权益保障利益点
    pic_interest_info(state, getters, rootState, rootGetters) {
      const { pictureinterest, Guarantee } = rootGetters['productDetail/common/fs_abt'] || {}
      const { PUBLIC_CDN = '' } = rootGetters['productDetail/common/common_page_info']
      const goods_id = rootGetters['productDetail/common/goods_id']

      // 免费退货
      const freeReturnPolicy = rootGetters['productDetail/common/free_return_policy'] || false
      // 排行榜
      const rankingListInfo = rootGetters['productDetail/common/ranking_list_info'] || {}
      const { score, rankingTypeText, composeIdText } = rankingListInfo ?? {}
      const showRankingInfoLink = score && rankingTypeText && composeIdText
      //品牌
      const brandMapInfo = rootState.productDetail.coldModules.brandDetailInfo
      const isBrandHasTargetTag = brandMapInfo?.authenticBrand == 1
      // 安全购
      const showShippingGuarantee = Guarantee?.p?.Guarantee === 'show'

      // 是否展示权益图
      let picInterestAbt = pictureinterest?.p?.pictureinterest
      let showPicInterest = ['newlast', 'newfour', 'outfitlast'].includes(picInterestAbt)
      
      let data = [
        { key: 'ranking', value: showRankingInfoLink, type: 'rank' },
        { key: 'brand', value: isBrandHasTargetTag, type: 'brand' },
        { key: 'green', value: showShippingGuarantee, type: 'security' },
        { key: 'green', value: freeReturnPolicy, type: 'return' },
      ]
      const interestList = data.filter(item => item.value) || []
      // 主题色
      const pictureTheme = interestList[0]?.key || ''
      const cardCount = interestList.length || 0

      // 权益卡片类型
      let cardType = ''
      switch (cardCount) {
        case 3:
        case 4: 
          cardType = 'little'
          break
        case 2: 
          cardType = 'medium'
          break
        case 1: 
          cardType = 'large'
          break
        default:
          showPicInterest = false
          break
      }

      const pictureLogo = showRankingInfoLink && (cardCount === 4 ? 'picture-interest-star-8e25e0eaed' : 'picture-interest-vector-2cb542bbd9')

      // 商详页埋点设置page_name
      if(typeof window !== 'undefined' && window.SaPageInfo && window.SaPageInfo.page_param) {
        window.SaPageInfo.page_param.is_show_pictureinterest = interestList?.length ? 1 : 0 
      }
      
      return {
        showPicInterest,
        picInterestAbt,
        showRankingInfoLink,
        rankingListInfo,
        isBrandHasTargetTag,
        brandMapInfo,
        showShippingGuarantee,
        freeReturnPolicy,
        pictureTheme,
        cardType,
        pictureLogo,
        PUBLIC_CDN,
        interestList,
        key: `${goods_id}_picInter`,
      }
    },
    // 大图数据
    big_image_info(state, getters, rootState, rootGetters) {
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const goods_id = rootGetters['productDetail/common/goods_id']
      const product_imgs = rootGetters['productDetail/common/product_imgs']
      const mall_stock = rootGetters['productDetail/common/mall_stock']
      const is_sold_out = rootGetters['productDetail/common/is_sold_out']
      const sku_code = rootGetters['productDetail/common/sku_code']
      const sale_attr_list = rootGetters['productDetail/common/main_sale_attribute_list']
      const is_customization = rootGetters['productDetail/common/is_customization']
      const mall_code = rootGetters['productDetail/common/mall_code']
      const add_to_bag_form = rootGetters['productDetail/common/add_to_bag_form']
      const external_size_info_index = rootGetters['productDetail/common/external_size_info_index']
      const fixed_ratio = rootGetters['productDetail/common/fixed_ratio']
      const size_guide_data = getters.size_guide_data || {}
      // 是否展示大图快速加车模块
      const abtPicNewCart = fs_abt?.picnewcart?.param?.picnewcart
      const isShowQuickAddBtn = () => {
        if (is_sold_out || mall_stock === 0) return false
        return ['A', 'C'].includes(abtPicNewCart)
      }
      // 是否打开加车弹窗
      const hasAttrs = sale_attr_list?.length
      const isOpenQuickView = (hasAttrs && !sku_code)
        
      // 是否显示以图搜图
      const abtPicSearchImage = fs_abt?.bigpicturesearch?.p?.bigpicturesearch === 'show'
        
      // 图搜时传的推荐规则
      const detailPicStrategy = fs_abt?.PicSearchUpgrade?.p?.PicSearchSave3 ?? ''
      let abtDetailPicStrategyRule = detailPicStrategy.includes('goodsid') ? detailPicStrategy.replace('goodsid', goods_id) : detailPicStrategy
      if(size_guide_data.isHasSizeGuide) {
        const sizeGuideInfo = {
          img_id: 'size_guide',
          is_skc_image: false,
          origin_image: size_guide_data.sizeGuideInfo.imgUrl,
        }
        product_imgs.push(sizeGuideInfo)
      }
      return {
        isShowQuickAddBtn: isShowQuickAddBtn(),
        isOpenQuickView,
        abtPicSearchImage,
        abtDetailPicStrategyRule,
        abtPicNewCart,
        is_customization,
        product_imgs,
        goods_id,
        mall_code,
        quantity: add_to_bag_form?.quantity || 1,
        external_size_info_index,
        fixed_ratio,
      }
    },
    // 主图角标（wiki/pageId=1091700053）
    locate_labels_data(state, getters, rootState) {
      return rootState.productDetail.hotModules.productLabel.premiumFlagList || []
    },
    // 页面基础数据 
    locals(state, getters, rootState, rootGetters) {
      const language = rootGetters['productDetail/common/language_v2']
      const mallCode = rootGetters['productDetail/common/mall_code']
      const pageInfo = rootGetters['productDetail/common/common_page_info']
      const isSsr = rootGetters['productDetail/common/is_ssr']

      return {
        language,
        mallCode,
        isSsr,
        ...pageInfo
      }
    },
    // 视频数据
    video_play_info(state, getters, rootState, rootGetters) {
      const videoDuration = rootState.productDetail.common.video_duration
      const isEvolushein = rootState.productDetail.coldModules.productLabel.isEvolushein
      const cccVideoPosition = rootState.productDetail.coldModules.productInfo.videoSort
      //是否展示视频按钮
      const showVimeoPlayer = rootGetters['productDetail/Main/show_native_player']
      const showNativePlayer = rootGetters['productDetail/Main/show_vimeo_player']
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const hasVideo = !!videoDuration && (showVimeoPlayer || showNativePlayer)

      const showEvolushein = fs_abt?.evolushein?.p?.evolushein === 'show'
      const checkEvolushein = isEvolushein !== '1' || (isEvolushein === '1' && showEvolushein)
      const isShowVideo = checkEvolushein && hasVideo
      
      return {
        isShowVideo,
        videoDuration,
        cccVideoPosition
      }
    },
    // 首个sku code --- 取 common sku_list ?
    first_sku_code(state, getters, rootState) {
      const sku_list = rootState.productDetail.coldModules?.saleAttr?.multiLevelSaleAttribute?.sku_list || []
      return sku_list?.[0]?.sku_code
    },
    // 是否展示 ar 试装
    show_ar_entry(state, getters, rootState, rootGetters) {
      const second_info_ready = rootState.productDetail.common.second_info_ready
      if (second_info_ready) {
        const arEntrance = rootState.productDetail.hotModules?.saleAttr?.arEntrance || false
        const isSkcSoldOut = rootGetters['productDetail/common/is_skc_sold_out']
        return !isSkcSoldOut && arEntrance
      }
      return false
    },
    // outfits | gtl
    outfits_info(state, getters, rootState, rootGetters) {
      const newCompanionInfo = rootGetters['productDetail/common/new_companion_info']
      const { GoodsdetailToppicOutfitRecommend: outfitsAbt } = rootGetters['productDetail/common/fs_abt'] || {}

      const { productRecommendDetailLabel, productNewCompanionList, ...args } = newCompanionInfo
      let outfitsInfo = productRecommendDetailLabel || {}
      // 是否存在 outfits
      let isHasOutfits = !!outfitsInfo?.url
      // 是否存在 主图展示的 gtl
      const gtlInfo = productNewCompanionList?.find(item => item.isProductDetailShow === '1') || null

      // outfits不存在时候取 gtl 图片
      if (!isHasOutfits && !!gtlInfo?.companionImageUrl) {
        outfitsInfo.url = gtlInfo?.companionImageUrl
        isHasOutfits = true
      }

      // 外露的套装是pdc就不展示锚点
      if (isHasOutfits && gtlInfo?.detailCompanionDataType === '0') {
        outfitsInfo.labels = []
      }

      const { child = [], posKey } = outfitsAbt || {}
      const { bid = '', eid = '' } = child[0] || {}
      const outfitsAbTest = bid && eid ? `${posKey}\`${eid}\`${bid}` : ''
      const allGtlProducts = []
      for (let [index, item] of productNewCompanionList?.entries?.() || []) {
        for (let productInfo of item?.productInfoList || []) {
          if(!allGtlProducts.some(it => it.goods_id === productInfo.goods_id)){
            allGtlProducts.push({ ...productInfo, swiperIndex: index, companionItem: item})
            if(allGtlProducts?.length >= 5) break
          }
        }
        if(allGtlProducts?.length >= 5) break
      }

      return {
        outfitsAbTest,
        isHasOutfits,
        outfitsInfo,
        gtlInfo: {
          ...gtlInfo,
          ...args
        },
        allGtlProducts
      }
    },
    // sizeguide数据
    size_guide_data(state, getters, rootState, rootGetters) {
      const { sizeGuide } = rootState.productDetail.coldModules.productInfo.currentSkcImgInfo || {}
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const Sizepic = fs_abt?.Sizepic?.p?.Sizepic
      const sizeGuideInfo = sizeGuide
      //       || {
      //         "imgUrl": "https://img.ltwebstatic.com/images3_ccc/2024/09/06/58/17256121713129eb7f4bb83cc6b87bb6cfc11cff3e.png",
      //             "width": 100,
      //             "height": 100,
      //             "mainTitle": "Size Guide",
      //             "subTitle": "The following size data: weight in kg, height in cm"
      //       }
      // 是否存在 sizeguide
      const isHasSizeGuide = !!sizeGuideInfo?.imgUrl && (['A', 'B'].includes(Sizepic))
      const isSizeGuideBeforeOutfit = isHasSizeGuide && Sizepic === 'A'
      const isSizeGuideAfterOutfit = isHasSizeGuide && Sizepic === 'B'
      if(!!sizeGuideInfo?.imgUrl) {
        sizeGuideInfo.imgUrl = transformImg({ img: sizeGuideInfo.imgUrl })
      }
      return {
        isHasSizeGuide,
        isSizeGuideBeforeOutfit,
        isSizeGuideAfterOutfit,
        sizeGuideInfo
      }
    },
    // 主图数据 ｜ 权益保障利益点 ｜ outfits数据
    carouse_data(state, getters, rootState, rootGetters) {
      const { newoutfit } = rootGetters['productDetail/common/fs_abt'] || {}
      const second_info_ready = rootState.productDetail.common.second_info_ready
      // 主图数据
      const product_imgs = rootGetters['productDetail/common/product_imgs']
      // 最后插入sizeguide 一定要在主图后，outfits前或者后
      const size_guide_data = getters.size_guide_data || {}

      const carouseData = product_imgs?.map((item, index) => {
        // 兼容图片非720x场景
        if (item?.origin_image?.indexOf('_thumbnail') === -1) {
          item.origin_image = item?.origin_image.replace('.webp', '_thumbnail_720x.webp')
        }
        return {
          type: CAROUSELS_TYPE.GOODS_IMAGE,
          key: Math.random().toString(36).substr(2, 9),
          data: item
        }
      })

      if (second_info_ready) {
        // 添加outfits数据
        const outfits_info = getters.outfits_info || {}
        if (outfits_info?.isHasOutfits && newoutfit?.p?.newoutfit === 'new') {
          carouseData.push({
            type: CAROUSELS_TYPE.GOODS_OUTFITS,
            key: Math.random().toString(36).substr(2, 9),
            data: outfits_info,
          })
        }

        // 是否展示权益保障利益点
        const pic_interest_info = getters.pic_interest_info || {}
        const { showPicInterest, picInterestAbt } = pic_interest_info
        if(showPicInterest && !!product_imgs.length) {
          const interestData = {
            type: CAROUSELS_TYPE.GOODS_INTEREST,
            key: Math.random().toString(36).substr(2, 9),
            data: pic_interest_info
          }
          
          // 根据 abt 插入利益点
          if (picInterestAbt === 'newfour') {
            carouseData.splice(3, 0, interestData)
          } else if(size_guide_data.isHasSizeGuide) { // sizeguide存在时，需保证利益点在sizeguide前面
            if (outfits_info?.isHasOutfits) {
              carouseData.splice(carouseData.length - 1, 0, interestData)
            } else {
              carouseData.push(interestData)
            }
          } else if (outfits_info?.isHasOutfits && picInterestAbt === 'newlast' ) {
            carouseData.splice(carouseData.length - 1, 0, interestData)
          } else {
            carouseData.push(interestData)
          }
        }

        if(size_guide_data.isHasSizeGuide && !!product_imgs.length) {
          const sizeGuideData = {
            type: size_guide_data.isSizeGuideBeforeOutfit ? CAROUSELS_TYPE.GOODS_SIZE_BEFORE_OUTFIT : CAROUSELS_TYPE.GOODS_SIZE_AFTER_OUTFIT,
            key: Math.random().toString(36).substr(2, 9),
            data: size_guide_data
          }
          if (outfits_info?.isHasOutfits && size_guide_data.isSizeGuideBeforeOutfit) {
            carouseData.splice(carouseData.length - 1, 0, sizeGuideData)
          } else {
            carouseData.push(sizeGuideData)
          }
        }
      }

      // 图片类型
      const picType = new Set(['skc'])
      product_imgs?.forEach((item) => {
        if (item?.is_spu_image) picType.add('spu')
        if (item?.is_sku_image) picType.add('sku')
      })

      return {
        carouseData,
        CAROUSELS_TYPE,
        picType,
      }
    },
  }
}
