const state = {
  isSSR: false,
  locals: null,
  noticeText: '',
  abtInfo: {},
  language: {},
  trackInfo: {},
  orderTrackInfo: {},
  currentPackage: [],
  shippingTime: {},
  cspNotice: [],
  saleTypeInfo: {},
  trackBanner: {},
  customsInterception: [],
  trackBannerNew: {},
  updateShippedAddress: {},
  packageCommentList: [],
  apolloInfo: {},
  insuranceInfo: {},
  showPrivew: false,
  imageList: [], // 预览图片列表
  previewInitIndex: 0, // 预览图片初始索引
  podImgPopBox: {
    contentType: '',
    podImgPop: false
  },
  bothTypePod: '', // 有签收和异常两种pod
}

export default state
