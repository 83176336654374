import getters from './getters'
import actions from './actions'
export default {
  namespaced: true,
  state: {
    switch_mall_code: '',
    is_out_site: false, // 是否为站外详情页 可以替代旧链路 outSiteGoodsDetail
    detail_page_onload_status: false,
    hide_strange_belt: false,           // 隐藏异形腰带 
    // current_local_country: null,     // 当前国家
    cache_goods_imgs: null,             // 列表跳转缓存的图片
    fixed_ratio: '3-4',                 // 补图首图比例 
    is_play_vimeo: false,               // 是否播放vimeo状态
    video_duration: '',                 // 视频播放时长
    second_info_ready: false,           // 第二部分数据完成
    third_info_ready: false,            // 第三部分数据完成
  },
  getters,
  actions,
  mutations: {
    update_switch_mall_code(state, payload) {
      state.switch_mall_code = payload
    },
    update_is_out_site(state, payload) {
      state.is_out_site = payload
    },
    update_detail_page_onload_status(state, payload) {
      state.detailPageOnloadStatus = payload
    },
    update_hide_strange_belt(state, payload) { // todo 后序迁移状态
      state.hide_strange_belt = payload
    },
    // update_current_local_country(state, payload) {
    //   state.current_local_country = payload
    // },
    update_cache_goods_imgs(state, payload) {
      state.cache_goods_imgs = payload
    },
    update_fixed_ratio(state, payload) {
      state.fixed_ratio = payload
    },
    update_play_vimeo_status(state, payload) {
      state.is_play_vimeo = payload
    },
    update_video_duration(state, payload) {
      state.video_duration = payload
    },
    update_second_info_ready(state, payload) {
      state.second_info_ready = payload
    },
    update_third_info_ready(state, payload) {
      state.third_info_ready = payload
    },
  },
}
