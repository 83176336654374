export default {
  namespaced: true,
  state: {
    realSaleAttrList: null,
    realSkuAllInfo: null,
    realDataMap: null,
    showHeelHeight: false, // 展示跟高强化信息，涉及到description、tips、detail外露场景
    heelHeight: '', // 鞋跟高度
    cmInchInfo: '', // 因为在ar站阿拉伯语和英文+数字+特殊字符展示时，顺序会乱掉，所以拆开
    realMallStock: null, // 当前 mall 库存
    globalPerfectFitShow: false, // sizegroup 关联商品商详和快速加车弹窗的联动状态控制
    switchMallCode: '' // 记录更新后的 mallCode
  },
  getters: {
    currentUnit(state, getters, rootState) {
      const { currentLocalUnit } = rootState.newProductDetail.common
      const sizeUnit = getters?.sizeComposeData?.sizeInfoDes?.sizeUnit != '1' ? 'cm' : 'inch'
      return currentLocalUnit || sizeUnit
    },
    recommendMySize(state, getters, rootState) {
      const { currentMySize } = rootState.newProductDetail.common
      return currentMySize
    },
    recommendMyBraSize(state, getters, rootState) {
      const { currentMyBraSize } = rootState.newProductDetail.common
      return currentMyBraSize
    },
    recommendCombo(state, getters, rootState) {
      const { recommend = {} } = rootState.newProductDetail.coldModules
      const { recommendMySize, recommendMyBraSize } = getters
      const { shoesRuleInfo = {} } = recommend
      const { rule_id = 0, rule_type } = shoesRuleInfo
      const recommendData = {
        ruleId: rule_id,
        ruleType: rule_type,
        recommendMySize,
        recommendMyBraSize,
      }
      return recommendData
    },
    tipsCombo(state, getters, rootState, rootGetters) {
      const { ccc, productInfo, commonInfo } = rootState.newProductDetail.coldModules
      const { comment } = rootState.newProductDetail.hotModules
      const { commentOverView } = comment
      const { isReady = true, tips } = ccc?.cccConfig || {}
      const { sizeTipsConfig } = commonInfo
      const { showHeelHeight, heelHeight, cmInchInfo } = state
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const deliveryFloorQuickshipTitle = rootGetters['newProductDetail/ShippingEnter/deliveryFloorQuickshipTitle'] || ''
      // const isHitSizeTipsExperiment = fsAbt?.sizeTips?.param?.SizeTips == 'A'
      const showTrueToSizeStrongAbt = fsAbt?.truetosizetips?.param?.truetosizetips === 'A'
      const { productDetails = [] } = productInfo.detail || {}
      const defaultTrueToSizeText =
        tips || productDetails?.find?.((item) => item.attr_id == '146')?.attr_value || ''
      const soldoutTipsLimitNum = Number(fsAbt?.outofstocktips?.p?.detailsonlynleft) || 10
      // 是否隐藏跟高显示的 abt
      const hiddenHeelHeightTipsAbt = fsAbt?.goodsdetailTips?.param?.showheel === 'off'
      const cccTrueToSizeText = tips || ''
      return {
        // sizeTipsBold,
        defaultTrueToSizeText,
        sizeTipsConfig,
        commentOverView,
        isReady,
        deliveryFloorQuickshipTitle,
        // isHitSizeTipsExperiment,
        soldoutTipsLimitNum,
        showHeelHeight,
        heelHeight,
        showTrueToSizeStrongAbt,
        cmInchInfo,
        cccTrueToSizeText,
        hiddenHeelHeightTipsAbt
      }
    },
    sizeConfig(state, getters, rootState, rootGetters) {
      const { globalPerfectFitShow } = state
      const { ccc, productInfo, commonInfo, recommend } = rootState.newProductDetail.coldModules || {}
      const { country_code } = recommend?.sizeRule || {}
      const { sizeTipsConfig } = commonInfo
      const { pageComponents } = ccc
      const { siteMallInfo } = productInfo
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const goodsSizeGroupRelated = fsAbt.goodsSizeGroupRelated?.p?.goodsSizeGroupRelated
      const showSizeGroupPosition = ['mid', 'bottom', 'fold', 'size'].includes(goodsSizeGroupRelated) && goodsSizeGroupRelated
      const showSizeGuide = rootGetters['newProductDetail/common/showSizeGuide']
      const showMultiLayer = rootGetters['newProductDetail/common/showMultiLayer']
      const isNewSizeLocal = rootGetters['newProductDetail/common/isNewSizeLocal']
      const detailConfig = rootGetters['newProductDetail/common/detailConfig']
      const isHitComplianceMode = rootGetters['newProductDetail/common/isHitComplianceMode']
      const getSkcEstimatedInfo = rootGetters['newProductDetail/common/getSkcEstimatedInfo']
      const longSizeAbt = fsAbt?.longsize?.p?.longsize || ''
      const from = 'detailPage'
      const showFeedBack = from == 'detailPage'

      const showNewSizeGuide = !!showSizeGuide
      const showOnlyOneLocalSize = fsAbt?.defaultlocalsize?.param?.onlyonelocalsize === 'on' && !!country_code
      const showSizeAttributeEntrance = fsAbt?.SizeAttributeEntrance?.p?.SizeAttributeEntrance === 'on' && fsAbt?.sizeguideshow?.param?.sizeguideshow === 'B'
      const isNoSkuClick = fsAbt?.NoSkuClick?.['param'] === 'type=2'
      // 一行不折叠 oneunfold / 两行不折叠 twounfold / abt 没有值
      const sizeUnFold = !!['oneunfold', 'twounfold', ''].includes(longSizeAbt)
      const longSizeTowLine = !!['twofold', 'twounfold'].includes(longSizeAbt)
      const showTaxation = !!sizeTipsConfig?.showTaxation
      const sizepriority = fsAbt?.sizepriority?.p?.sizepriority || 'off'
      const defaultlocalsize = fsAbt?.defaultlocalsize?.p?.defaultlocalsize || 'old'
      const sizeguidefit = fsAbt?.sizeguidefit?.p?.sizeguidefit || 'off'
      // 隐藏部分 quickship 场景的显示
      const hiddenPartialQuickShip = fsAbt?.goodsdetailTips?.param?.quickshipshow === 'off'
      // 当用户选择本地化尺码，且同时展示默认尺码时，弱化括号内默认尺码展示, 默认不是弱化
      const shouldWeakenDefaultSize = fsAbt?.defaultlocalsize?.p?.localstrong === 'on'
      const isAutoSwitchMall = fsAbt?.goodsdetailmall?.param?.mallautoswitch === 'on'
      const sizeBubblePlan = fsAbt?.goodsdetailTips?.param?.sizebubble || 'default'
      // 次级销售属性的低库存提示方案（商详不开启）
      const attrLowStockTipsPlan = 'off'

      return {
        qty: pageComponents?.qtyConf,
        isNewSizeLocal,
        showSizeGroupPosition,
        showNewSizeGuide,
        showSizeAttributeEntrance,
        showMallSite: siteMallInfo?.length > 1,
        showQuickShip: true,
        showSizeRecommend: true,
        showFeedBack,
        isNoSkuClick,
        longSizeTowLine,
        sizeUnFold,
        showTaxation,
        showMultiLayer,
        from,
        globalPerfectFitShow,
        showOnlyOneLocalSize,
        sizeRule: recommend?.sizeRule || {},
        hideOneSize: getters.hideOneSize,
        openAutoCheckMall: fsAbt?.mallchange?.param?.mallshow === 'auto',
        isShowAttrPicInfo: detailConfig.isShowAttrPicInfo,
        isHitComplianceMode,
        sizepriority,
        defaultlocalsize,
        getSkcEstimatedInfo,
        sizeguidefit,
        hiddenPartialQuickShip,
        shouldWeakenDefaultSize,
        isAutoSwitchMall,
        sizeBubblePlan,
        attrLowStockTipsPlan
      }
    },
    hideOneSize(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const { category } = rootState.newProductDetail.coldModules
      const { parentCats } = category || {}

      if (fsAbt?.Onesizeno?.param?.Onesizeno === 'noshow') {
        // 屏蔽one-size的商城ID
        const shieldOneSizeCatId = [1864, 2032, 2273, 3195, 3634, 3637, 3657]
        const arr = [parentCats]
        for(let i = 0; i < arr.length; i++) {
          const { cat_id, children } = arr[i] || {}
          if (children?.length) arr.push(...children)
          const catId = +cat_id
          if (shieldOneSizeCatId.includes(catId)){ 
            return true
          }
        }
      }
      return false
    },
    isOneSize(state, getters, rootState) {
      const { productInfo } = rootState.newProductDetail.coldModules
      const { sizeFormatData = {} } = productInfo
      return !!sizeFormatData?.oneSizeStatus
    },
    dataMapCompose(state, getters, rootState) {
      const { realDataMap } = state
      const { dataMapCompose } = rootState.newProductDetail.coldModules?.productInfo?.sizeFormatData || {}
      const mixData = dataMapCompose

      if (realDataMap) {
        mixData.dataMap = realDataMap
      }

      return mixData
    },
    saleAttrList(state, getters, rootState) {
      const { productInfo } = rootState.newProductDetail.coldModules
      const { sizeFormatData = {} } = productInfo
      return sizeFormatData.saleAttrList
    },
    sizeComposeData(state, getters, rootState, rootGetters) {
      const currentCountry = rootGetters['newProductDetail/common/currentCountry']
      const { productInfo, pageInfo, category, ccc } = rootState.newProductDetail.coldModules
      const { sizeFormatData = {}, sizeInfoDes, localsize, detail, siteMallInfo } = productInfo
      const { parentCats } = category
      const { language } = pageInfo || {}
      const { cccAttrGroups = [] } = ccc || {}
      const { dataMapCompose, saleAttrList } = getters
      const {
        relatedLocalSize,
        heightSizeMap,
        sizeTitle,
        tackInfoDes,
        // saleAttrGroups,
      } = sizeFormatData

      const country = currentCountry
      const localSize = localsize
      const baseInfo = { ...detail }
      const mall_info = baseInfo?.mall_info || []
      mall_info?.forEach(item => {
        const siteMall = siteMallInfo?.find?.(site => site.mall_code === item.mall_code)
        // 混入mall tag，用于判断是本地仓还是跨境仓，最后拿来上报
        if (siteMall) {
          item.mall_tags = siteMall.mall_tags
          item.mall_sort = siteMall.mall_sort
        }
      })

      return {
        sizeInfoDes,
        dataMapCompose,
        // saleAttrGroups,
        saleAttrList,
        heightSizeMap,
        country,
        localSize,
        relatedLocalSize,
        baseInfo,
        language,
        sizeTitle,
        parentCats,
        tackInfoDes,
        cccAttrGroups
      }
    },
    sizeConstant(state, getters, rootState) {
      const { commonInfo } = rootState.newProductDetail.coldModules
      const { IS_RW, SiteUID, WEB_CLIENT, GB_cssRight_rp, langPath_rp } = commonInfo

      return {
        IS_RW,
        SiteUID,
        WEB_CLIENT,
        cssRight: GB_cssRight_rp,
        langPath: langPath_rp,
      }
    },
    skuMapCompose(state, getters, rootState) {
      const { productInfo: coldProduct } = rootState.newProductDetail.coldModules
      const { productInfo: hotProduct } = rootState.newProductDetail.hotModules
      const { skuMap = {}, skuIdMap } = coldProduct.skuMapCompose || {}
      const { skuAllInfo = {} } = hotProduct.skuMapCompose || {}
      return {
        skuMap,
        skuIdMap,
        skuAllInfo,
      }
    },
    showRecommendMySize(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const { sizeConfig, isOneSize } = getters
      const { showMultiLayer } = sizeConfig
      const { recommend = {} } = rootState.newProductDetail.coldModules
      const { shoesRuleInfo = {} } = recommend
      const { rule_id = 0 } = shoesRuleInfo
      const isSelfSizeRecommend = fsAbt?.Selfsizerecommended?.param === 'type=A' && rule_id != 0
      if (!isSelfSizeRecommend) return false
      return !isOneSize && showMultiLayer
    },
    // 是否展示尺码推荐和尺码引导的混合弹窗
    showCheckSizeAndSizeGuide(state, getters) {
      const { showNewSizeGuide } = getters.sizeConfig
      return getters.showRecommendMySize && showNewSizeGuide
    },
  },
  mutations: {
    updateRealSkuAllInfo(state, payload) {
      state.realSkuAllInfo = payload
    },
    updateRealDataMap(state, payload) {
      state.realDataMap = payload
    },
    updateHeelHeightStatus(state, payload) {
      state.showHeelHeight = payload
    },
    updateHeelHeight(state, payload) {
      state.heelHeight = payload
    },
    updateCmInchInfo(state, payload) {
      state.cmInchInfo = payload
    },
    updateGlobalPerfectFitShow(state, payload) {
      state.globalPerfectFitShow = payload
    },
    updateRealMallStock(state, payload) {
      state.realMallStock = payload
    },
    updateSwitchMallCode(state, payload) {
      state.switchMallCode = payload
    },
  },
  actions: {
    initSizeBoxState({ commit, state, rootState }) {
      if (state.showHeelHeight) commit('updateHeelHeightStatus', false)
      if (!rootState.newProductDetail.common.skuInfo?.sku_code) return
      commit('updateRealSkuAllInfo', null)
      commit('updateRealDataMap', null)
      commit('updateRealMallStock', null)
      commit('updateSwitchMallCode', null)
      commit('newProductDetail/common/updateSkuInfo', {}, { root: true })
    },
    updateSizeStock({ commit, getters, rootState }, { skuList = [] }) {
      if (!skuList?.length) return
      const { skuMapCompose } = rootState.newProductDetail.coldModules?.productInfo
      const { dataMapCompose } = getters
      const realSkuAllInfo = {}
      const { skuAllInfoMap } = skuMapCompose
      const realDataMap = dataMapCompose.dataMap

      skuList?.forEach(skuInfo => {
        const { sku_code, price, mall_price, stock } = skuInfo
        realDataMap[skuAllInfoMap[sku_code]?.key] = skuInfo.stock
        realSkuAllInfo[sku_code] = { price, mall_price, stock, key: skuAllInfoMap[sku_code]?.key }
      })

      commit('updateRealDataMap', realDataMap)
      commit('updateRealSkuAllInfo', realSkuAllInfo)
    }
  }
}
