export default {
  namespaced: true,
  state: {},
  getters: {
    ingredient_text(state, getters, rootState) {
      return rootState.productDetail.coldModules.productInfo.beauty_ingredients
    },
    models_info(state, getters, rootState) {
      return rootState.productDetail.coldModules?.productInfo?.models_info || {}
    },
    show_size_guide_enter(state, getters, rootState, rootGetters) {
      const abtInfo = rootGetters['productDetail/product_detail_abt_info']
      const { content = [] } = rootState.productDetail.hotModules.productInfo?.cccDetailsTemplate || {}
      const sizeChart = content?.[0]?.sizeChart || 1
      const sizeguideshow = abtInfo?.sizeguideshow?.param?.sizeguideshow || ''
      const isCccTemplate = !['A', 'B'].includes(sizeguideshow)
      const size_guide_url = rootState.productDetail.coldModules?.saleAttr?.size_guide_url
      return !!size_guide_url && (sizeguideshow === 'B' || (isCccTemplate && sizeChart == 1))
    }
  },
}
