export const EWishsimilarType = {
  /**
   * 原来的大半屏弹窗
   * */
  big: 'big',
  /**
   * 一行商卡样式
   */
  medium: 'medium',
  /**
   * 一行小图样式
   */
  small: 'small'
}

/** 
 * 1-similarItem 
 * 待添加: 2-组合购 3-oftenBoughtWith 4-quickShip推荐
 */
export const RecQueryType = {
  'similarItem': '1',
}

