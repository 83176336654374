export default {
  price_info(state, getters, rootState, rootGetters) {
    return state.hotModules.priceInfo || state.coldModules.priceInfo
  },
  detail_promotion_floor(state, getters, rootState, rootGetters) {
    return state.hotModules.detailPromotionFloor || state.coldModules.detailPromotionFloor
  },
  sku_list(state, getters, rootState, rootGetters) {
    return state.hotModules?.saleAttr?.multiLevelSaleAttribute?.sku_list || state.coldModules?.saleAttr?.multiLevelSaleAttribute?.sku_list
  },
  skc_stock(state, getters, rootState) {
    return rootState.productDetail.hotModules.productInfo.stock || rootState.productDetail.coldModules.productInfo.stock
  },
  user_info(state, getters, rootState) {
    return rootState.productDetail?.hotModules?.userInfo || {}
  },
  product_detail_abt_info(state) {
    return state.hotModules?.webProductDetailAbtInfo || {}
  },
  ship_info(state, getters, rootState) {
    return rootState.productDetail?.hotModules?.shipInfo || {}
  }
}
