import { isLogin } from 'public/src/pages/common/utils/index.js'
import { getUnreadCount } from '@customerService/BFF-Robot/index.js'

// 获取客服未读chat消息
export const getInitCsChatMsgNum = async () => {
  if (isLogin()) {
    const res = await getUnreadCount({ params: { types: 'chat' } })
    if (res?.code == '0') {
      return res.info?.chat_unread_count
    }
  }
  return 0
}

export const IS_IOS  = typeof navigator != 'undefined' && !!navigator?.userAgent?.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

export const useShowPrice = (order, showAvgPrice = false) => {
  const { avgPrice, totalPrice, currencyTotalPrice } = order || {}
  const price = totalPrice?.amountWithSymbol || currencyTotalPrice?.amountWithSymbol || ''
  if (showAvgPrice) {
    return avgPrice?.amountWithSymbol || price
  }else {
    return price
  }
}
