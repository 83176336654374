
export default {
  namespaced: true,
  state: {
    attrs_fold_quick_add_state: false, // 属性折叠快速加车打开状态
  },
  getters: {
    sale_attrs_arr(state, getters, rootState, rootGetters) {
      // 销售属性集合
      const externalSizeInfoIndex = rootState.newProductDetail.common.externalSizeInfoIndex || {}
      const cur_main_sale_attribute = rootGetters['productDetail/common/cur_main_sale_attribute']
      const skc_sale_attr = rootGetters['productDetail/common/skc_sale_attr']
      const hide_one_size_module = rootGetters['productDetail/SizeBox/hide_one_size_module']
      const is_show_colors = rootGetters['productDetail/MainSaleAttr/is_show_colors']
      let saleAttrsArr = []
      if (is_show_colors) saleAttrsArr.push({ ...cur_main_sale_attribute, isMainAttr: true })
      if (skc_sale_attr?.length && !hide_one_size_module) saleAttrsArr.push(...skc_sale_attr)
      return saleAttrsArr.sort((a, b) => {
        const sortA = (externalSizeInfoIndex[a.attr_id]?.attr_value_id || a.isMainAttr) ? 1 : 0
        const sortB = (externalSizeInfoIndex[b.attr_id]?.attr_value_id || b.isMainAttr) ? 1 : 0
        return sortB - sortA
      }).filter(item => !!item?.attr_name)
    },

    skc_images(state, getters, rootState, rootGetters) {
      let skcImages = []
      const is_show_colors = rootGetters['productDetail/MainSaleAttr/is_show_colors']
      const main_sale_attribute_list = rootGetters['productDetail/common/main_sale_attribute_list']
      const color_type = rootGetters['productDetail/MainSaleAttr/color_type']
      if (is_show_colors && main_sale_attribute_list.length > 1) {
        // 最多只显示三个 skc 图
        skcImages = main_sale_attribute_list.slice(0, 3).reduce((memo, cur) => {
          const { goods_color_image, goods_image } = cur
          if (color_type === 'img') memo.push(goods_image)
          else memo.push(goods_color_image)
          return memo 
        }, [])
      }

      return skcImages
    },

    sale_attr_count_text(state, getters, rootState, rootGetters) {
      const { sale_attrs_arr } = getters
      const main_sale_attribute_list = rootGetters['productDetail/common/main_sale_attribute_list']
      // 销售属性数量 str
      const saleAttrArrLen = sale_attrs_arr.length
      const saleAttrCountText = sale_attrs_arr.reduce((memo, cur, idx) => {
        if (cur.isMainAttr && main_sale_attribute_list?.length === 1) return memo
        const itemText = `${cur.isMainAttr ? main_sale_attribute_list.length : cur.attr_value_list.length} ${cur['attr_name']}`
        memo += `${itemText}${idx !== saleAttrArrLen - 1 ? ', ' : ''}`
        return memo
      }, '')

      return saleAttrCountText
    },

    should_default_select_attr(state, getters, rootState, rootGetters) {
      const main_sale_attribute_list = rootGetters['productDetail/common/main_sale_attribute_list']
      const skc_sale_attr = rootGetters['productDetail/common/skc_sale_attr']
      const skuList = rootGetters['productDetail/common/cache_sku_list']
      const { skuInfo } = rootState.newProductDetail?.common || {}
      const isOnlyOneSku = skc_sale_attr?.every?.(item => item?.attr_value_list?.length === 1) || skuList.length === 1 
      const isOnlyOneMainAttr = main_sale_attribute_list?.length === 1
      return isOnlyOneMainAttr && isOnlyOneSku && skuInfo?.sku_code
    },

    show_sale_attr_fold_entry(state, getters, rootState, rootGetters) {
      const { sale_attrs_arr } = getters
      const sale_attrs_fold_ab = rootGetters['productDetail/common/sale_attrs_fold_ab']
      // 有次级销售属性
      const hasSecondAttr = sale_attrs_arr?.filter?.(item => !item.isMainAttr)?.length

      return sale_attrs_fold_ab === 'fold_A' && hasSecondAttr
    },
  },
  mutations: {
    update_attrs_fold_quick_add_state(state, payload) {
      state.attrs_fold_quick_add_state = payload
    },
  }
}
