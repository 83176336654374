export default {
  namespaced: true,
  getters: {
    topOtherBase(state, getters, rootState) {
      const { detail = {} } = rootState.newProductDetail.coldModules.productInfo
      const { language } = rootState.newProductDetail.coldModules.pageInfo
      const { is_include_critical_css, IS_RW, IMG_LINK } =
        rootState.newProductDetail.coldModules.commonInfo

      return {
        goods_id: detail.goods_id,
        language,
        is_include_critical_css,
        IS_RW,
        IMG_LINK,
      }
    },

    /**卖点数据 */
    sellingPoints(state, getters, rootState, rootGetters) {
      return rootGetters['newProductDetail/common/sellingPoints']
    },
    /** 是否展示卖点 */
    // showSellingPoints(state, getters, rootState, rootGetters) {
    //   const fsAbt = rootGetters['newProductDetail/fsAbt']
    //   const { SellPoint } = fsAbt || {}
    //   const sellingPointsHitAbt = SellPoint?.p?.SellPoint && SellPoint?.p?.SellPoint !== 'A'
    //   if (!sellingPointsHitAbt) {
    //     return false
    //   }
    //   return getters.sellingPoints && getters.sellingPoints.length > 0
    // },
    /** 榜单相关 */
    // RankAggrega(state, getters, rootState, rootGetters) {
    //   const fsAbt = rootGetters['newProductDetail/fsAbt']
    //   return fsAbt?.RankAggrega || {}
    // },
    // abtRankAggrega(state, getters) {
    //   return getters.RankAggrega?.p?.RankAggrega || null
    // },
    topRankingInfo(state, getters, rootState) {
      return rootState.newProductDetail.hotModules.recommend?.topRankingInfo
    },
    isForwardCategory(state, getters, rootState) {
      return rootState.newProductDetail.hotModules.recommend?.isForwardCategory
    },
    rankingListInfo(state, getters, rootState, rootGetters) {
      const cacheScreenData = rootGetters['newProductDetail/cacheScreenData']
      if (cacheScreenData) {
        const { rankingList } = cacheScreenData
        return rankingList
      }
      return getters.topRankingInfo?.productDetailsPage?.rankingList ?? null
    },
    showRankingInfoLink(state, getters, rootState, rootGetters) {
      const cacheScreenData = rootGetters['newProductDetail/cacheScreenData']
      if (cacheScreenData) return true
      // 仅当链接有效才展示榜单排名，针对 cmp 异常数据的处理
      let { score, rankTypeText, composeIdText } = getters.rankingListInfo ?? {}
      return score && rankTypeText && composeIdText
    },
    rankingListIcon(state, getters) {
      const iconKey = 'ranking_default_medal'
      return getters.topOtherBase.IMG_LINK?.[iconKey] ?? ''
    },
    /** 是否展示Quantity */
    showQuantity(state, getters, rootState, rootGetters) {
      const abtInfo = rootGetters['productDetail/product_detail_abt_info']
      const sizechartshow = abtInfo?.sizechartshow?.param?.sizechartshow || ''
      const isCccTemplate = !['A', 'B'].includes(sizechartshow)

      const { pageComponents = {} } = rootState.newProductDetail.coldModules.ccc
      return sizechartshow === 'B' || (isCccTemplate && pageComponents.qtyConf?.isShow )
    },
    cccFlashZoneData (state, getters, rootState) {
      return rootState.newProductDetail.coldModules.ccc.cccFlashZoneData || {}
    },
    showSizePlaceHolder (state, getters, rootState, rootGetters) {
      const isPerformance = rootGetters['newProductDetail/isPerformance']
      const { goodsReady } = rootState.newProductDetail.common
      if (isPerformance && !goodsReady) return true
      return false
    },
    cacheSaleAttrList(state, getters, rootState, rootGetters) {
      const cacheScreenData = rootGetters['newProductDetail/cacheScreenData']
      if(cacheScreenData && cacheScreenData.skc_sale_attr) {
        return {
          skcSaleAttr: cacheScreenData.skc_sale_attr
        }
      }
    },
    showStoreBtn(state, getters, rootState, rootGetters) {
      const { IS_RW } = rootState.newProductDetail.coldModules.commonInfo
      if (IS_RW) return false
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      // storelefticon的值为( show || none) , default: all
      const storelefticon = fsAbt?.storelefticon?.param?.storelefticon === 'show'
      // iconrange的值为( all || onlyfashion) , default: all
      const iconrange = fsAbt?.storelefticon?.param?.iconrange || 'all'
      const isInFashionStore = rootGetters['newProductDetail/common/isInFashionStore']
      // 是否是品质店铺，品质店铺也会显示店铺icon
      const isQualityStore = rootGetters['newProductDetail/common/isQualityStore']
      // 店铺范围是否匹配
      const isMatchStoreRange = iconrange === 'all' || isInFashionStore || isQualityStore
      const { SiteUID = '' } = rootState.newProductDetail.coldModules.commonInfo
      const  excludeSiteUids = gbCommonInfo?.DETAIL_SECOND_CONFIG?.ADD_TO_BAG_ICON || []
      const { storeCode, storeStatus, link } = rootGetters['newProductDetail/common/localStoreInfo'] || {}
      // storeStatus === 1 表示店铺启用
      return storelefticon && isMatchStoreRange && !excludeSiteUids.includes(SiteUID) && storeCode !== 1 && storeStatus === 1 && link.indexOf('/store/home') > -1
    },
    showCatSelectionTagTop(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const cccCatSelectionTag = rootGetters['newProductDetail/cccCatSelectionTag']
      return cccCatSelectionTag?.descKeyList?.[0]?.langKeyEn && fsAbt.Qualityfloor?.p?.Qualityfloor === 'NEW'
    },
  },
}
