import schttp from 'public/src/services/schttp'
const { parseQueryString } = require('@shein/common-function')

const oldPosKeys = [
  'Orderlist',
  'IsInfoFront',
  'IsCanRefund',
  'orderResellEntrance',
  'ShopUrl',
  'OrderlistQueryRelatedAccounts',
  'OrderlistRelatedIcon',
  'TimeCanDelivery',
  'RejectedReason',
  'OrderlistOldFunc',
  'ReturnItem',
  'ThirtyLogin',
  'ConfirmDeliveryPage',
]

const newPosKeys = [
  'BinDiscountdiscount',
  'CODRiskOrder',
  'CustomerItemSyncToUserAddress',
  'SyncToUseraddress',
  'BatchModifyAddress',
  'Commentcoupon',
  'SpeedUp',
  'UrgeDelivery',
  'BinRandomDiscount',
  'returnStatusFront',
  'ExchangeFunction',
  'EditOrderAddress',
  'FindOrder',
  'MyPackage',
  'unpaidOrderTest',
  'ShowPriceGuarantee',
  'TrackPrepose',
  'ApplepayDropoffOptimization',
  'returnPackageNotice',
  'OrderPerfoOptimization',
  'storeiconchange',
  'ReturnStyle',
  'ReturnAndRefund',
  'returnUrgeFront',
  'SFSTipShow',
  'OrderRepurchase',
]

export const getOrderAbtInfos = async () => {
  const posKeys = [...oldPosKeys, ...newPosKeys].join(',')
  const data = await schttp({
    url: '/abt/merge/get_abt_by_poskey',
    params: {
      posKeys
    },
    useBffApi: true
  })
  const abtInfo = data?.info?.pos || {}

  const flags = {
    showEditOrderAddress: abtInfo?.EditOrderAddress?.param?.OrderListAddress == 'on',
    isShowUrgeDeliveryAbt: abtInfo?.UrgeDelivery?.param?.UrgeDelivery_index == 'on',
    showReturnPackageNoticeAbt: abtInfo?.returnPackageNotice?.param?.notice == 'on',
    isOpenOrdersListSsr: abtInfo?.OrderPerfoOptimization?.param?.OrdersListSsr == 'on',
    thirtyLoginAbt: Object.assign({}, parseQueryString(abtInfo?.ThirtyLogin?.p || '')),
    orderRepurchaseParam: abtInfo?.OrderRepurchase?.param || {}
  }
  
  return {
    ...abtInfo,
    ...flags
  }
}
