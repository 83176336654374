import { getGoodsUrl } from 'public/src/pages/common/utils/index.js'
import AddOnItem from 'public/src/pages/common/add-on/index.js'

export default {
  switch_color({ rootGetters, commit }, params) {
    const { 
      fromSizeGroup,
      goods_id: targetGoodsId, 
      jumpCallBack, 
      goods_url_name,
      cat_id: targetCatId,
    } = params || {}
    const main_sale_attribute_list = rootGetters['productDetail/common/main_sale_attribute_list']
    const cur_main_sale_attribute = rootGetters['productDetail/common/cur_main_sale_attribute']
    const goods_id = rootGetters['productDetail/common/goods_id']
    const mall_code = rootGetters['productDetail/common/mall_code']
    const mainAttribute = cur_main_sale_attribute?.attr_value_id ? (cur_main_sale_attribute?.attr_id + '_' + cur_main_sale_attribute?.attr_value_id) : ''
    const { langPath } = gbCommonInfo || {}
    const targetGoodsIdStr = targetGoodsId + '' 
    if (!fromSizeGroup && !main_sale_attribute_list?.length) return
    if (goods_id === targetGoodsIdStr) return

    commit('productDetail/MainSaleAttr/update_last_click_color', targetGoodsIdStr, { root: true })
    commit('productDetail/gift/update_gift_reserve_status', true, { root: true })
    
    // TODO: 更新旧数据源兼容旧组件，后续迁移
    // commit('newProductDetail/ColorBox/updateLastClickColor', targetGoodsIdStr, { root: true })
    commit('newProductDetail/common/updateFromSwitchColor', true, { root: true })
    commit('newProductDetail/common/updateAttriBute', '', { root: true })
    commit('newProductDetail/common/updateMainAttribute', mainAttribute, { root: true })



    //TODO: 溯源没用了，先删有问题再说
    // if (this.goodsImgMap[item.goods_id]) {
    //     goods_imgs.main_image = this.goodsImgMap[item.goods_id].main_image
    //     goods_imgs.detail_image = this.goodsImgMap[item.goods_id].detail_image
    //   } else {
    //     if (goods_imgs?.main_image) {
    //       goods_imgs.main_image.bfCache = ''
    //       goods_imgs.main_image.origin_image = LAZY_IMG
    //     }
    //     goods_imgs?.detail_image?.forEach?.(item => {
    //       item.origin_image = LAZY_IMG
    //     })
    //   }
    // }

    if (typeof gbAddBagTraceFrom === 'function') {
      try{
        main_sale_attribute_list?.forEach?.(goods => {
          const from = gbAddBagTraceFrom('getProductFrom', { goods_id: goods?.goods_id })
          if (from != 'other') gbAddBagTraceFrom('setProductFrom', { goods_id: targetGoodsIdStr + '', from: from })
        })
      }catch(e){
        console.warn('gbAddBagTraceFrom error:', e)
      }
    }
    let search = location.search.replace(/&?(attr_ids|main_attr|mallCode|isFromSwitchColor)=(\w|_)+/g, '')
    search = search + (search.indexOf('?') > -1 ? '&main_attr=' + mainAttribute : '?main_attr=' + mainAttribute)
    search = search + (search.indexOf('?') > -1 ? '&mallCode=' + mall_code : '?mallCode=' + mall_code)
    search = search + (search.indexOf('?') > -1 ? '&isFromSwitchColor=1' : '?isFromSwitchColor=1')
    let url = `${langPath}/${getGoodsUrl(goods_url_name, targetGoodsIdStr, targetCatId)}` + search
    jumpCallBack(url)
  },
  add_on_item_open({ rootGetters }, params) {
    let goodsId = rootGetters['productDetail/common/goods_id']
    let fsAbt = rootGetters['productDetail/common/fs_abt']
    let estimatedInfo = rootGetters['productDetail/price/estimated_info']
    let catId = rootGetters['productDetail/common/cat_id']
    let queryInfo = {
      addOnType: 2, // 问产品拿
      sceneId: 152, // 推荐场景id
      cateIds: [catId]
    }
    if(fsAbt?.Gatheringorders?.p?.Gatheringorders === 'Topping') {
      // 置顶主skc
      queryInfo.adp = [goodsId]
    }else{
      queryInfo.goodsIds = [goodsId]
    }
    if(fsAbt?.EstimatedNothreShowType?.p?.recommendtype == '1') {
      // 基于商详商品推出凑单商品
      queryInfo.mainGoodsIds = [goodsId]
    }
    AddOnItem.open({
      props: {
        type: 'coupon',
        coupon: params.coupon || estimatedInfo.couponCode, // **券码** 必传
        queryInfo,
        saInfo: { // 主要用于埋点
          activity_from: 'goods_detail_coupon_add',
          state: 'goods_detail_coupon_add',
        },
      },
      on: {
        close: () => {
          params?.onClose?.()
        },
        updateCart: (cartInfo) => {
          params?.onUpdateCart?.(cartInfo)
        }
      }
    })
  },
  // 打开登录弹窗
  show_login_modal({ dispatch }, payload) {
    if (typeof window !== 'undefined' && typeof SHEIN_LOGIN !== 'undefined') {
      const { params } = payload || {}
      return SHEIN_LOGIN.show({
        show: true,
        cb: (status) => {
          dispatch('newProductDetail/loginModalSucCb', { 
            // TODO loginModalSucCb内部依赖较多，需待依赖都迁移完再做进一步处理
            ...(payload || {}),
            status,
          }, { root: true })
        },
        ...(params || {}),
      })
    }
  },
}
