import { getColorsInfoForCacheScreenData, getColorsInfo, getHotGoodSnList } from '@/public/src/pages/goods_detail/utils/colorsInfo.js'

export default {
  namespaced: true,
  state: {
    /** 最后点击的skc */
    lastClickColor: '',
    goodsImgMap: {}
  },
  getters: {
    /** 色块容器信息（色块组件所需的数据一次全部返回）*/
    colorBoxInfo(state, getters, rootState, rootGetters) {
      const { productInfo, pageInfo, tsp, ccc, commonInfo } = rootState.newProductDetail.coldModules || {}
      const cacheScreenData = rootGetters['newProductDetail/cacheScreenData']
      const mallCode = rootGetters['newProductDetail/common/mallCode']
      const { hotColorList = {}, skcSort = {}, detail = {}, goods_imgs: goodsImgs = {} } = productInfo || {}
      const { language } = pageInfo || {}
      const { tspLabels } = tsp || {}
      const { pageComponents } = ccc || {}
      const mainSaleAttr = detail.mainSaleAttribute?.[0] || {}
      const { cccSupportLargeImageList } = getters
      let { attr_name = '', attr_value = '', attr_value_id = '', attr_id = '', attr_image = '' } = mainSaleAttr || {}
      const { LAZY_IMG, langPath_rp, WEB_CLIENT, IS_RW, PUBLIC_CDN_rp  } = commonInfo || {}
      
      // 色块相关ABT信息
      const {
        abtSupportLargeImageList,
        showGoodsDesc,
        ruleId,
        isGoodsMainAttPicShow
      } = getters.colorsAbtState
      // 色块列表数据
      const colorsInfo = getters.colorListData
      // 色块热门商品列表
      const hotGoodSnList = getters.colorHotGoodSnList
      // 色块配置信息
      const loadConf = getters.colorsConfig

      let colorType = getters.isSkcPrepose ? 'prepose' : (detail.mainSaleAttrShowMode == 1 ? 'text' : pageComponents?.colorConf?.isImgColorType ? 'img' : 'default')
      let colorBlockText = ''
      if (cacheScreenData) {
        colorBlockText = 'Color'
      } else {
        let colorTitleDesc = colorType !== 'text' ? `: ${attr_value}` : ''
        colorBlockText = `${attr_name}${colorTitleDesc}`
      }

      let goodsDesc = pageComponents?.descriptionConf?.goodsDesc || ''
      if (goodsDesc && attr_value)  goodsDesc = `${attr_value}: ${goodsDesc}`
      // skc 图片
      let goodsMainAttPicInfo = null
      let isShowGoodsMainAttPicInfo = isGoodsMainAttPicShow && !goodsDesc && !!attr_image
      if (isShowGoodsMainAttPicInfo) {
        let textDetail = `${language.SHEIN_KEY_PWA_24732}: `

        goodsMainAttPicInfo = {
          textDetail,
          attr_value,
          attr_image
        }
      }
      return {
        hotColorList,
        skcSort,
        detail,
        goodsImgs,
        language,
        colorBlockText,
        cccSupportLargeImageList,
        colorConf: pageComponents?.colorConf,
        goodsDesc,
        mainAttribute: attr_value_id ? attr_id + '_' + attr_value_id : '',
        supportLargeImageList: abtSupportLargeImageList && cccSupportLargeImageList,
        showGoodsDesc,
        findShadeRuleId: colorsInfo?.length >= 5 && ruleId,
        isShowFindMyShadeEntry: colorsInfo?.length >= 5 && tspLabels?.tag?.indexOf?.('60003778') > -1,
        colorType,
        colorsInfo,
        LAZY_IMG,
        IS_RW,
        PUBLIC_CDN_rp,
        langPath_rp,
        WEB_CLIENT,
        mallCode,
        hotGoodSnList,
        loadConf,
        goodsMainAttPicInfo
      }
    },
    /** 是否展示色块 */
    isShowColors(state, getters, rootState, rootGetters) {
      const { productInfo } = rootState.newProductDetail.coldModules || {}
      const { relationProducts = [], soldoutColor = [], detail = {} } = productInfo || {}
      const cacheScreenData = rootGetters['newProductDetail/cacheScreenData']
      const colorList = [detail, ...relationProducts, ...soldoutColor]
      if (cacheScreenData) {
        return !!cacheScreenData.relatedColor?.length
      } else {
        return (
          (detail.mainSaleAttrShowMode == 1 && detail.mainSaleAttribute.length > 0) ||
          colorList.length > 1 ||
          !!getters.colorBoxInfo.goodsMainAttPicInfo ||
          !!getters.colorBoxInfo.goodsDesc
        )
      }
    },
    /** 色块ABT */
    colorsAbtState(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const detailConfig = rootGetters['newProductDetail/common/detailConfig']
      const { selectcolor } = fsAbt || {}
      // 实验开关赋值计算
      const abtSupportLargeImageList = selectcolor?.param?.largeimage === 'on'
      const showGoodsDesc = selectcolor?.param?.colordesc === 'on'
      return {
        abtSupportLargeImageList,
        showGoodsDesc,
        ruleId: 'rec_ver:S93V1.0',
        isGoodsMainAttPicShow: detailConfig.isShowAttrPicInfo
      }
    },
    /** 色块配置信息 */
    colorsConfig() {
      return {
        size: '750x',
        num: 1
      }
    },
    /** 色块列表数据 */
    colorListData(state, getters, rootState, rootGetters) {
      const { productInfo, commonInfo } = rootState.newProductDetail.coldModules || {}
      const mallCode = rootGetters['newProductDetail/common/mallCode']
      const fsAbt = rootGetters['newProductDetail/fsAbt']
      const cacheScreenData = rootGetters['newProductDetail/cacheScreenData']
      const { relationProducts = [], hotColorList = {}, evoluColorList = {}, soldoutColor = [], skcSort = {}, detail = {} } = productInfo || {}
      const { WEB_CLIENT, LAZY_IMG  } = commonInfo || {}
      const loadConf = getters.colorsConfig
      const mallSoldoutAb = fsAbt?.mallchange?.param?.mallshow === 'auto'
      const showEvolushein = fsAbt?.evolushein?.param?.evolushein === 'show'
      let colorsInfo = {}

      if (cacheScreenData) {
        colorsInfo = getColorsInfoForCacheScreenData({
          relationProducts: cacheScreenData.relatedColor,
          LAZY_IMG
        })
      } else {
        colorsInfo = getColorsInfo({
          relationProducts: relationProducts,
          detail,
          soldoutColor,
          skcSort,
          loadConf,
          mallCode,
          webClient: WEB_CLIENT,
          hotColorList,
          evoluColorList: showEvolushein ? evoluColorList : {},
          mallSoldoutAb,
          LAZY_IMG
        })
      }

      return colorsInfo
    },
    /** 色块热门商品列表 */
    colorHotGoodSnList(state, getters) {
      const colorsInfo = getters.colorListData
      const hotGoodSnList = getHotGoodSnList(colorsInfo)

      return hotGoodSnList
    },
    /** ccc配置颜色选择内是否展示大图 */
    cccSupportLargeImageList(state, getters, rootState) {
      const { ccc } = rootState.newProductDetail.coldModules || {}
      const { cccSupportLargeImageList } = ccc || {}
      return cccSupportLargeImageList
    },
    isCccSkcPrepose(state, getters, rootState, rootGetters){
      const cacheScreenData = rootGetters['newProductDetail/cacheScreenData']
      if (cacheScreenData) {
        return cacheScreenData.isCccSkcPrepose
      }
      const { ccc } = rootState.newProductDetail.coldModules || {}
      const { cccSkcPreposeConfig } = ccc || {}
      const { isCccSkcPrepose } = cccSkcPreposeConfig || {}
      return isCccSkcPrepose
    },
    skcPreposeVal(state, getters, rootState, rootGetters) {
      const cacheFsAbt = rootState.newProductDetail.cacheFsAbt
      const skcAbtVal = cacheFsAbt?.goodsdetailsSKCprefix?.p?.goodsdetailsSKCprefix
      const isCccSkcPrepose = getters.isCccSkcPrepose
      const cacheScreenData = rootGetters['newProductDetail/cacheScreenData']
      let mainSaleAttrShowMode
      if (cacheScreenData) {
        mainSaleAttrShowMode = cacheScreenData.main_sale_attr_show_mode
      } else {
        const { productInfo } = rootState.newProductDetail.coldModules || {}
        const { detail = {} } = productInfo || {}
        mainSaleAttrShowMode = detail.mainSaleAttrShowMode
      }
      // 满足以下条件，才进行skc前置
      // 1.命中abt为 A 或者 B
      // 2.在cccx的配置范围内
      // 3.当前商品的主销售属性是颜色且数量大于1（等于1时隐藏）
      return (mainSaleAttrShowMode == 2 && getters.colorListData?.length > 1) 
        && isCccSkcPrepose
        && skcAbtVal
    },
    isSkcPrepose(state, getters){
      return getters.skcPreposeVal === 'A' || getters.skcPreposeVal === 'C'
    }
  },
  mutations: {
    updateLastClickColor(state, payload) {
      state.lastClickColor = payload ? String(payload) : ''
    },
    updateAllgoodsImg(state, payload) {
      state.goodsImgMap = payload
    },
  },
}
