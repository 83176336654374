// const COMMENT_TYPE_MAP = {
//   1: 'COMMENT',
//   2: 'REPORT',
//   3: 'OUT_STORE_COMMENT'
// }
import _ from 'lodash'

import {
  makeFreeList,
} from 'public/src/pages/product_app/store/modules/product_detail/components/other/CommentPopup/utils.js'
import { commentAdapter } from 'public/src/pages/goods_detail/utils/bffUtil/index.js'
import { template } from '@shein/common-function'

export default {
  namespaced: true,
  state: {
    detail_reviews_translate_info: [], // 详情页评论翻译信息
    single_reviews_translate_info: {}, // 详情单条评论翻译信息
  },
  getters: {
    /** 首屏评论 */
    comments(state, getters, rootState) {
      const data = commentAdapter.abcCommentAdapter({
        info: {
          comment_info: rootState.productDetail.hotModules.comment.product_comments 
        }
      }) || []
      return data.info.commentInfo || []
    },
    /** 首屏试用报告 */
    trail_data(state, getters, rootState) {
      const data = commentAdapter.freeTrailAdapter({
        info: {
          free_trial_list: rootState.productDetail.hotModules.comment.trail_data?.reportList 
        }
      }) || []
      return makeFreeList(data.info.free_trial_list) || []
    },
    /** 首屏站外评论 */
    store_product_comments(state, getters, rootState) {
      const store_product_comments = rootState.productDetail.hotModules.comment.store_product_comments || []
      const out_store_review_list = store_product_comments.map((comment, index) => ({
        ...(comment || {}),
        isOutStoreReview: true,
        isFirstOne: index === 0,
        comment_id: comment?.store_comment_id || 0,
        // page: Math.ceil((index + 1) / 20)
      }))

      return out_store_review_list || []
    },
    comment_overview(state, getters, rootState) {
      return rootState.productDetail.hotModules.comment.comments_overview || {}
    },
    comment_overview_local(state, getters, rootState) {
      const { comment_overview } = getters
      const { localSiteScore, localSiteNumShow, percent_overall_fit } = comment_overview
      const local_percent_overall_fit = rootState.newProductDetail.CommentPopup.local_percent_overall_fit || {}
      if(+localSiteNumShow == 0 && !localSiteScore) return null

      return {
        comment_num: localSiteNumShow,
        comment_rank_average: localSiteScore,
        percent_overall_fit: {
          ...percent_overall_fit,
          ...local_percent_overall_fit
        }
      }
    },
    commentPositiveRating(state, getters, rootState) {
      const commentPositiveRating = rootState.productDetail.hotModules.comment.commentPositiveRating || {}
      const { fiveStarRating = '', fourStarRating = '', threeStarRating = '', secondStarRating = '', oneStarRating = '' } = commentPositiveRating
      if(!fiveStarRating) return {}
      commentPositiveRating.fiveStarRatingNum = +fiveStarRating || 0
      commentPositiveRating.fourStarRatingNum = +fourStarRating || 0
      commentPositiveRating.threeStarRatingNum = +threeStarRating || 0
      commentPositiveRating.secondStarRatingNum = +secondStarRating || 0
      commentPositiveRating.oneStarRatingNum = +oneStarRating || 0
      return commentPositiveRating
    },
    showRatingDetailsAbt(state, getters, rootState, rootGetters) {
      const { RatingDetails = {} } = rootGetters['productDetail/common/fs_abt'] || {}
      const commentPositiveRating = getters.commentPositiveRating
      const fiveStarRating = commentPositiveRating?.fiveStarRating || '' // 五星好评率
      const fiveStarRatingNum = commentPositiveRating?.fiveStarRatingNum || 0
      const showRatingDetail = RatingDetails?.param?.Rating_Details === 'Yes' // 控制展示展示好评率，各个星级评分的占比
      const complianceTipsMode = rootGetters['productDetail/common/compliance_tips_mode']
      const language_v2 = rootGetters['productDetail/common/language_v2']
      let limitControl = false, showRatingDetailDisplay = false, ratingDetailText = template(`${fiveStarRating}%`, language_v2.SHEIN_KEY_PWA_33478 || '{0} 5-star Rating')
      const Rating_Details_limit = (RatingDetails?.param?.Rating_Details_limit || '').replace(/%/g, '')
      if(+Rating_Details_limit) {
        limitControl = fiveStarRatingNum >= Rating_Details_limit
      } else {
        limitControl = true
      }
      const showRatingDetailPage = fiveStarRating && showRatingDetail && limitControl
      const Rating_Details_Display = (RatingDetails?.param?.Rating_Details_Display || '').replace(/%/g, '')
      if(+Rating_Details_Display) {
        showRatingDetailDisplay = fiveStarRatingNum >= Rating_Details_Display && showRatingDetailPage
      }
      return {
        showRatingDetailDisplay, // 商详评论楼层样式增强
        ratingDetailText, // 五星好评率文案
        showRatingDetailPage, // 好评率在商详楼层展示
        showRatingDetailPop: !complianceTipsMode && fiveStarRating && showRatingDetail, // 评论详情问号换箭头
      }
    },
    // 多维评分
    sel_tag_score(state, getters) {
      const { comment_overview } = getters
      return comment_overview?.sel_tag_score || []
    },
    rate_explanation_link() {
      const { SERVER_TYPE, langPath } = gbCommonInfo || {}
      // 中央
      let docId = '1510'
      // 美国
      if (SERVER_TYPE === 'usa') {
        docId = '1568'
      }
      // 欧洲
      if (SERVER_TYPE == 'eur') {
        docId = '1519'
      }
      return `${langPath}/Rating-Rules-a-${docId}.html`
    },
    // 商详情页评论，，目前需求取前四条
    detail_reviews(state, getters) {
      const { fs_comments } = getters || {}
      const { top4Comment } = fs_comments || {}
      
      // 混入翻译-批量情况
      // const top4CommentsTranslateInfo = rootState.productDetail.CommentPopup.top4_comments_translate_info
      const { detail_reviews_translate_info = [] } = state
      detail_reviews_translate_info && detail_reviews_translate_info.forEach(item => {
        const { comment_id } = item
        const index = top4Comment.findIndex(
          item => item.comment_id == comment_id || 'rp' + item.reportId == comment_id
        )
        top4Comment[index] = Object.assign({}, top4Comment[index], item)
      })
      // 混入翻译-单条情况
      const { single_reviews_translate_info = {} } = state
      const { comment_id } = single_reviews_translate_info || {}
      const index = top4Comment.findIndex(
        item => item.comment_id == comment_id || 'rp' + item.reportId == comment_id
      )
      index > -1 && (top4Comment[index] = Object.assign({}, top4Comment[index], single_reviews_translate_info))
      
      return top4Comment
    },
    // 是否展示评论时间新样式
    comment_date_display_new(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['productDetail/common/fs_abt']
      return fsAbt.Reviewchange?.param?.Datedisplay === 'New'
    },
    /**
     * @var LocalReviews
     * type=A | 不展示本地评论
     * type=B | 展示本地评论
     * type=C | 三期，展示本地评论，展示单条评论本地标识
     * type=D | 三期，展示本地评论，不展示单条评论本地标识，后端评论排序策略调整
     * type=E | 三期，同C，后端评论排序策略调整
     * 目前只有 A B 两种状态 cde 删除了
     */
    show_local_reviews(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['productDetail/common/fs_abt']
      return fsAbt?.LocalReviews?.param === 'type=B'
    },

    comment_tags(state, getters, rootState) {
      return rootState.productDetail.hotModules.comment.comment_tags || []
    },

    // 是否有评论分数 老链路 commentHide
    has_comment_average(state, getters) {
      const { comment_overview = {} } = getters
      const { comment_rank_average } = comment_overview
      if (!comment_rank_average || isNaN(comment_rank_average) || comment_rank_average <= 0) {
        return false
      }
      return true
    },
    // 商品评论数量模糊化展示  包含试用报告的数量
    comment_sum_show(state, getters) {
      const { comment_overview = {} } = getters
  
      return comment_overview.commentNumShow
    },
    // 商品评论数量模糊化展示 不包括试用报告
    only_comment_num_show(state, getters) {
      const { comment_overview = {} } = getters
  
      return comment_overview.onlyCommentNumShow
    },
    fs_comments(state, getters, rootState, rootGetters) {
      const {
        comment_overview,
        trail_data: freeTrailReports = [],
        comments,
        store_product_comments: outStoreCommentsInfo = [],
        comment_tags,
      } = getters
      const isMainGoodsLowestPrice = rootGetters['newProductDetail/common/isMainGoodsLowestPrice']
      const filterCommentList = comments?.filter?.(item => item.same_flag != 1 || (isMainGoodsLowestPrice == 1 && item.same_flag == 1)) || []
      const commentsResult = {
        // commentInfoTotal: commentInfo.commentInfoTotal,
        commentNumStr: comment_overview.commentNumShow,
        shopRank: comment_overview.comment_rank_average,
        spuTag: comment_tags,
        reportTotal: freeTrailReports.length,
      }

      // let commentType = COMMENT_TYPE_MAP['1']
      // let firstComment = {} // 商详页显示评论逻辑 先报告 后评论 后站外评论
      // if (freeTrailReports.length) {
      //   firstComment = freeTrailReports[0]
      // } else if (filterCommentList?.length) {
      //   firstComment = filterCommentList?.[0]
      //   commentType = COMMENT_TYPE_MAP['2']
      // } else if (outStoreCommentsInfo.data?.length) {
      //   firstComment = outStoreCommentsInfo.data[0]
      //   commentType = COMMENT_TYPE_MAP['3']
      //   commentsResult.outStoreCommentsTotal = outStoreCommentsInfo.meta?.count || 0
      // }

      // 商详页显示前四条评论 逻辑 先报告 后评论 后站外评论
      let top4Comment = []
      if (freeTrailReports.length) {
        top4Comment.push(...freeTrailReports.slice(0, 4))
      } 
      if (top4Comment.length < 4 && filterCommentList?.length) {
        top4Comment.push(...filterCommentList?.slice(0, 4 - top4Comment.length))
      } 
      if (top4Comment.length < 4 && outStoreCommentsInfo?.length) {
        top4Comment.push(...outStoreCommentsInfo.slice(0, 4 - top4Comment.length))
      }

      commentsResult.firstComment = top4Comment[0] // 第一条评论
      // commentsResult.firstCommentType = commentType

      commentsResult.top4Comment = top4Comment

      return commentsResult
    },
    show_reviews(state, getters) {
      const { comment_sum_show, store_product_comments } = getters
      return !!parseInt(comment_sum_show) || !!store_product_comments.length
    },
    // 评论会员尺码数据
    // commentMemberSizeConfig(state, getters, rootState) {
    //   const { memberSizeConfig } = rootState.newProductDetail.coldModules.comment || {}
    //   return (memberSizeConfig?.memberSize && memberSizeConfig?.memberSize?.result) || []
    // },
    // hasFit(state, getters, rootState) {
    //   const { memberSizeConfig } = rootState.newProductDetail.coldModules.comment || {}
    //   return (
    //     (memberSizeConfig.memberFit &&
    //       memberSizeConfig.memberFit[0] &&
    //       memberSizeConfig.memberFit[0].hasFit) ||
    //     0
    //   )
    // },
    translate_language(state, getters, rootState) {
      return rootState.productDetail.hotModules.comment.translate_language || []

    },
    // 是否显示付费评论
    show_point_program(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['productDetail/common/fs_abt']
      return fsAbt?.PointProgram?.param === 'type=A'
    },
    percent_overall_fit(state, getters) {
      const { comment_overview } = getters

      return comment_overview.percent_overall_fit
    },
    has_comments_to_size(state, getters) {
      const percentOverallFit = getters.percent_overall_fit
      const hasOverZeroPercent = _.find(percentOverallFit, item => {
        return item && item.replace('%', '') > 0
      })
      return Boolean(hasOverZeroPercent)
    },
    show_rate_panel(state, getters) {
      const { comment_sum_show, has_comment_average } = getters

      // 有评论 && 评分大于0 && 有尺码
      return parseInt(comment_sum_show) > 0 && has_comment_average && getters.has_comments_to_size
    },
    // 是否批量翻译
    is_batch_translate(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['productDetail/common/fs_abt']
      const isBatchTranslate = +rootState.productDetail.hotModules.comment.trans_mode === 1
      const isNotBatchTranslate = fsAbt?.Reviewchange?.param?.Batch_translate === 'No'
      return isBatchTranslate && !isNotBatchTranslate
    },
    comment_watermark(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['productDetail/common/fs_abt']
      const { Imagewatermark = {} } = fsAbt || {}
      const commentAbt = ['show'].includes(Imagewatermark?.p?.Imagewatermark || 'no')
      
      return {
        showCommentWatermark: commentAbt, // 评论区水印
      }
    },
    sort_list(state, getters, rootState, rootGetters) {
      const language_v2 = rootGetters['productDetail/common/language_v2']
      const { isAbtCommentAntiSpider } = getters
      const sDefault = {
        key: language_v2.SHEIN_KEY_PWA_15376,
        keyEn: 'Default',
        biName: 'click_sort_default',
        sort: 'default',
        type: 'Recommend',
        value: 0
      }
      const sOld = {
        key: language_v2.SHEIN_KEY_PWA_16475,
        keyEn: 'Oldest to Most Recent',
        biName: 'click_sort_old_to_recent',
        type: 'Oldest most recent',
        sort: 'time_asc',
        value: 2
      }

      const sRecent = {
        key: language_v2.SHEIN_KEY_PWA_16476,
        keyEn: 'Most Recent to Oldest',
        type: 'Most recent',
        biName: 'click_sort_recent_to_old',
        sort: 'time_desc',
        value: 3
      }
      if (isAbtCommentAntiSpider) return [sDefault, sRecent]
      return [sDefault, sOld, sRecent]
    },
    reviews_con(state, getters, rootState, rootGetters) {
      const language_v2 = rootGetters['productDetail/common/language_v2']
      return [
        {
          name: language_v2.SHEIN_KEY_PWA_16479,
          gaName: 'SelectWithPicture',
          biName: 'click_filter_with_picture',
          index: 0,
          value: 1
        }
      ]
    },
    is_retention_product(state, getters, rootState) {
      return rootState.productDetail.coldModules?.productLabel?.isRetentionProduct || ''
    },
  },
  mutations: {
    update_single_reviews_translate_info(state, payload) {
      state.single_reviews_translate_info = payload
    },
    update_detail_reviews_translate_info(state, payload) {
      state.detail_reviews_translate_info = payload
    },
  },
  actions: {
    async fetch_batch_translate_firstComment({
      getters,
      dispatch,
      commit,
      rootState
    }) {
      const detail_reviews = getters.detail_reviews
      const firstReviewWithBatchTranslate = await dispatch(
        'productDetail/CommentPopup/fetch_batch_translate',
        detail_reviews,
        { root: true }
      ) || []
      const current_lang = rootState.newProductDetail.CommentPopup.defaultLang
      firstReviewWithBatchTranslate.forEach(item => {
        item.translateLang = current_lang
      })
      commit('update_detail_reviews_translate_info', firstReviewWithBatchTranslate)
      // const {
      //   showOrigin,
      //   translateText,
      //   translateTags,
      //   translateSelectTags
      // } = firstReviewWithBatchTranslate[0] || {}
      // commit('update_detail_reviews_translate_info', {
      //   showOrigin,
      //   translateText,
      //   translateTags,
      //   translateSelectTags,
      //   translateLang: current_lang
      // })
    }
  }
}
