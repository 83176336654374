<template>
  <div
    v-if="inventoryInfo.sizeStockTips"
    class="inventory-information" 
    :style="styleObj"
    @click="goCheckout"
  >
    <span class="inventory-information__img">
      <img :src="mainImg" />
    </span>
    <span class="inventory-information__content">
      {{ inventoryInfo.soldOutTips ||'Almost sold out!' }}
    </span>
    <span>{{ language.SHEIN_KEY_PWA_33547 || 'Act fast' }}</span>
    <span class="inventory-information__icon"> 
      <i
        class="suiiconfont sui_icon_more_right_12px_2"
      ></i> 
    </span>
  </div>
</template>
<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '1-6-1' })

export default {
  name: 'InventoryInformation',
  props: {
    language: {
      type: Object,
      default: () => ({})
    },
    inventoryInfo: {
      type: Object,
      default: () => ({})
    },
    fsAbt: {
      type: Object,
      default: () => ({})
    },
    resetCountFn: {
      type: Function,
      default: () => {}
    },
    addSize: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      positionBottom: -50,
      quickAtomsZIndex: 200000,
      startShow: false
    }
  },
  computed: {
    mainImg() {
      return this.inventoryInfo?.carouseData?.carouseData[0]?.data?.origin_image || this.inventoryInfo?.carouselsInfo?.productImgs?.[0]?.origin_image || ''
    },
    showSoldOutTips() {
      return this.fsAbt.addbagsuccess?.p?.soldouttips === 'show'
    },
    styleObj() {
      if(this.inventoryInfo?.addbagRecStyleOne){
        return {
          bottom: '12px',
          zIndex: this.quickAtomsZIndex,
          transform: `translateZ(${this.quickAtomsZIndex}px)`,
          opacity: this.startShow ? 1 : 0,
          position: 'absolute'
        }
      }
      return {
        bottom: `${this.positionBottom}px`,
        zIndex: this.quickAtomsZIndex,
        transform: `translateZ(${this.quickAtomsZIndex}px)`,
        opacity: this.startShow ? 1 : 0,
        position: 'fixed'
      }
    }
  },
  watch: {
    addSize(v){
      if(v && this.showSoldOutTips){
        this.calcBottom()
      }
    }
  },
  mounted() {
    if(this.inventoryInfo.sizeStockTips){
      if(this.showSoldOutTips){
        const quickAtmosphereFlow = document.querySelector('.quick-atmosphere-flow')
        if(quickAtmosphereFlow){
          quickAtmosphereFlow.style.display = 'none'
        }
        setTimeout(() => {
          this.startShow = true
          this.calcBottom()
        }, 1500)
      }
      daEventCenter.triggerNotice({
        daId: '1-6-1-245',
        extraData: {
          addcart_lowstock_type: this.showSoldOutTips ? 1 : 0
        }
      })
    }
  },
  methods: {
    calcBottom() {
      const cartDom = document.querySelector('.addbag-container__drawer-body')
      const cartDomParent = cartDom?.parentElement || null
      this.quickAtomsZIndex = window.getComputedStyle(cartDomParent)?.getPropertyValue('z-index') + 1 || 200000
      const quickHeight = cartDom?.offsetHeight || 0
      if(quickHeight > 0) {
        this.positionBottom = quickHeight + 12
      }
    },
    goCheckout() {
      daEventCenter.triggerNotice({
        daId: '1-6-1-246'
      })
      this.resetCountFn?.()
      const { langPath } = gbCommonInfo
      window.location.href = `${langPath}/cart`
    },
  }
}
</script>

<style lang="less" scoped>
.inventory-information{
    left: 12px;
    max-width: calc(100% - 24px);
    opacity: 0;
    transition: opacity 1.5s ease-in-out;
    overflow: visible;
    border-radius: 20px;
    border: 0.5px solid rgba(255, 179, 179, 0.40);
    background: linear-gradient(90deg, rgba(255, 89, 66, 0.50) 0%, rgba(207, 68, 37, 0.45) 75.84%, rgba(208, 63, 43, 0.40) 100%);
    display: flex;
    height: 28px;
    padding: 6px 8px 6px 12px;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    color: #fff;
    &__img{
        width: 20px;
        height: 20px;
        border-radius: 2px;
        overflow: hidden;
        border: 1px solid rgba(255, 255, 255, 0.50);
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
    }
    &__content{
        margin-right: 16px;
        flex:1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis
    }
    &__icon{
       border-radius: 50%;
       border: 1px solid #fff;
       height: 12px;
       width: 12px;
       display: flex;
       align-items: center;
        justify-content: center;
      .sui_icon_more_right_12px_2{
        font-size: 9px;
        font-weight: 600;
      }
    }
}
</style>
