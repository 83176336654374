import { isCouponAbPrice } from 'public/src/pages/goods_detail_v2/utils/coupon'
import { LANGUAGE_KEYS  } from '@/public/src/pages/common/common-coupon/language.js'
import schttp from 'public/src/services/schttp'

export default {
  namespaced: true,
  state: {
    new_coupon_language: {},
    language_ready: false
  },
  getters: {
    // 原始券数据
    origin_coupon_list(state, getters, rootState, rootGetters) {
      return rootGetters['productDetail/detail_promotion_floor']?.couponInfo
        ?.couponInfoList
    },
    ab_price_coupon(state, getters) {
      return getters.origin_coupon_list?.find(d => isCouponAbPrice(d))
    },
    /**
     * 是否展示优惠券倒计时
     */
    is_show_coupon_count_down(state, getters, rootState, rootGetters) {
      const fsAbt = rootGetters['productDetail/common/fs_abt']
      return fsAbt?.EstimatedPrice?.p?.E_Time_Limit == 'Show'
    },

    // 优惠券组件数据
    cmp_coupon_info_list(state, getters, rootState) {
      return rootState.productDetail.hotModules?.completeCouponInfo?.cmpCouponInfo?.cmpCouponInfoList ?? []
    }
  },
  mutations: {
    update_new_coupon_language(state, payload) {
      state.new_coupon_language = payload
    },
    update_language_ready(state, payload) {
      state.language_ready = payload
    }
  },
  actions: {
    async getNewCouponLanguage({ state, commit }) {
      return new Promise((resolve, reject) => {
        if(state.new_coupon_language && Object.keys(state.new_coupon_language).length)  return resolve(state.new_coupon_language) 
           
        schttp({
          url: '/system/configs/multi_language',
          method: 'POST',
          data: {
            languageKeys: LANGUAGE_KEYS
          },
          useBffApi: true
        }).then(res => {
          commit('update_new_coupon_language', res.info?.result)
          commit('update_language_ready', true)
          resolve(res.info?.result || {})
        }).catch(e => {
          console.error(e)
          commit('update_language_ready', true)
          reject({})
        })
      })
    },
  }
}
