import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index.js'
import { useMapGetters } from './useVuex.js'
import {
  unref,
  onBeforeUnmount,
  onMounted,
  getCurrentInstance
} from 'vue'
import { Expose } from '@shein-aidc/basis-expose'
import { getImageRatio } from 'public/src/pages/goods_detail/utils/common.js'

/**
 * 获取商品信息转换工具
 * @param {Object} options
 * @param {Ref<string>} options.language_v2
 * @param {Ref<boolean>} options.is_paid_user
 * @returns {Object}
 * @returns {Function} goodsTransformationWithSetup
 * @returns {Function} goodsTransformationProductsWithSetup
 */
export const useProductCardUtils = (product_bff_depend_data = {}) => {

  const goodsTransformationWithSetup = item => {
    return goodsTransformation(item, unref(product_bff_depend_data))
  }
  const goodsTransformationProductsWithSetup = products => {
    return products.map(goodsTransformationWithSetup)
  }

  return {
    goodsTransformationWithSetup,
    goodsTransformationProductsWithSetup
  }
}

export const useProductCardUtilsWithSetup = () => {
  const { product_bff_depend_data } = useMapGetters('productDetail/Recommend', [
    'product_bff_depend_data',
  ])

  return useProductCardUtils(product_bff_depend_data)
}

/**
 * 注册dom曝光回调
 */
export const useRegisterDomExpose = ({ $el, callback }) => {
  if (!$el || typeof callback !== 'function') throw 'invalid params'

  let exposeInstance = new Expose()

  const unWatch = () => {
    exposeInstance?.destroy()
    exposeInstance = null
  }
  exposeInstance.observe(
    {
      elements: $el
    },
    () => {
      // 曝光之后当前这个实例不需要了
      unWatch()
      callback()
    }
  )

  onBeforeUnmount(() => {
    unWatch()
  })

  return {
    unWatch,
  }
}

export const useRegisterDomExposeWithComponentMounted = ({ callback }) => {
  onMounted(() => {
    const vm = getCurrentInstance()
    useRegisterDomExpose({
      $el: vm?.proxy?.$el,
      callback
    })
  })
}

/**
 * 商卡调用快速加车弹窗通用Hook
 */
export const useOpenQuickAddWithProductCart = ({ $quickAdd } = {}) => {

  let $quickAddInstance = null
  if (!$quickAdd) {
    const vm = getCurrentInstance()
    $quickAddInstance = vm?.proxy?.$quickAdd
  }
  if (!$quickAddInstance) throw 'quickAdd not found'

  const { is_hit_compliance_mode } = useMapGetters('productDetail/common', [
    'is_hit_compliance_mode'
  ])

  const openQuickAddWithProductCart = async (payload, options = {}) => {
    const { item, index } = payload || {}
    const { goods_id, mall_code: mallCode, reducePrice } = item || {}
    const imgRatio = await getImageRatio(item?.goods_img)
    if (!goods_id) return

    const { _useUptFloatCart = true } = options
    const clickCallBack = options?.clickCallBack || {}

    $quickAddInstance.open({
      goods_id,
      imgRatio,
      mallCode,
      index,
      reducePrice,
      addSource: 'detailPage',
      showBestDealLabel: true,
      showFollowBeltByOrigin: true,
      showEstimatedPrice: !unref(is_hit_compliance_mode),
      // analysisConfig: {
      //   sourceTarget: target,
      //   sa: {
      //     activity_from: 'newoutfit_moreoutfit',
      //     location: '-',
      //   },
      // },
      ...options,
      clickCallBack: {
        // 加车成功后的回调
        complete: () => {
          clickCallBack?.complete?.()

          if (_useUptFloatCart && window.vBus) {
            setTimeout(() => {
              window.vBus.$emit('triggerAddCompletedFloatCart', { animation: false })
            }, 2000)
          }
        },
        ...clickCallBack,
      },
    })
  }

  return {
    openQuickAddWithProductCart,
  }
}

/**
 * 获取商品项列表埋点基础数据模版
 */
export const getAnalysisBoxBindDataTemplate = ({ from, module, code, posKey, ...otherOptions  } = {}) => {

  return {
    'active-from': from,
    module,
    code,
    posKey,
    ...(otherOptions || {})
  }
}
