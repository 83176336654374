
import AddOnItem from 'public/src/pages/common/add-on/index.js'
import Vue from 'vue'
import InventoryInformation from './components/InventoryInformation.vue'

export const couponProductCardConfig = {
  showAddBagBtn: false,
  useOwnClickExposeAnalysis: false, // 是否使用自己的点击，曝光埋点
  showEstimateTag: false, // 无到手价
  showEstimatedPriceReachThreshold: false, // 无到手价
  showEstimatedPriceOnSale: false, // 无到手价
  showEstimatedPrice: false, // 无到手价
  showABPriceOnSale: true, // 显示AB测试价格
  showDiscountLabelAfterPrice: false, // 隐藏价格右边折扣标签
  hideFlashSaleCountDown: true, // 隐藏闪购倒计时
  showSheinClubLabelInServicesLabel: false, // 付费会员标签
  disableMainimgJump: true, // 禁止跳转到商详，PC会屏蔽右键菜单
  showBeltLabel: false, // 腰带
  showTitle: false,
  showDiscountLabelBeforeGoodsName: false,
  showDiscountLabelBeforeGoodsNameHighPriority: false,
  showStarComment: false,
  showDecisionAttr: false,
  showSellingPoint: false,
  showQuickShip: false,
  showLocalSeller: false,
  showBadge: false,
  showPriceDiscountDesc: false,  // 历史低价标签、保留款跟价款标签
  showUserBehaviorLabel: false, // 用户行为标签
  showUserCommentKeywordsLabel: false, //用户评论关键词标签
  showRankingList: false, // 榜单
  showPromoLabel: false, // 促销标签
  showBestDealLabel: false,
  showHorseRaceLabel: false,
  addRankingLabelToSwiper: false,
  showSalesLabel: false,
  showPropertyUpsell: false // 一行多图时不支持右下角新增属性类卖点
}

export const openAddonItemDrawer = (props) => {
  const { mainCouponCode = '', isReview = false, drawerConfig = {}, secondaryCoupon = [], queryInfo, cbSetGoodsDetailScrollFn, resetCountFn } = props
  const _KEY = 'productDetail_addBagRecCount'
  const count = sessionStorage.getItem(_KEY) || 0
  AddOnItem.open({
    props: {
      type: 'coupon',
      coupon: mainCouponCode || '', // **券码** 必传！！！
      secondaryCoupon, // 多券的副券码
      queryInfo,
      drawerConfig,
      saInfo: {
        // 主要用于埋点
        activity_from: 'detail_rec_coupon_add',
        state: 'detail_rec_coupon_add'
      },
      config: {
        useRouterPush: true,
        isClickToDetail: !isReview,
      },
    },
    on: {
      close: () => { // 关闭回调
        cbSetGoodsDetailScrollFn && cbSetGoodsDetailScrollFn()
      },
      viewCart: () => { // 重置次数
        resetCountFn && resetCountFn()
      },
      viewTap: () => { // 重置次数
        resetCountFn && resetCountFn()
      },
      open: (payload) => { // 打开回调
        if (payload?.topFloatEl) {
          new Vue({
            render: (h) => h(InventoryInformation, {
              props: {
                language: props.language,
                fsAbt: props.fsAbt,
                resetCountFn: props.resetCountFn,
                inventoryInfo: props.inventoryInfo,
                key: count
              }
            }),
          }).$mount(payload.topFloatEl)
        }
      }
    }
  })
}
