/**
 * 商详所有接口路由文件
 */
const DETAIL_API_LOCATION = {
  PRODUCT_DETAIL_DETAIL_IMAGE: '/product/get_goods_detail_image', // 细节图
  STORE_UPDATE_FOLLOWING: '/product/store/update_following', // 店铺关注
  PRODUCT_RANKING_INFO: '/ccc/rec/goods', // 榜单信息
  PRODUCT_DETAIL_RECOMMEND_INFO: '/product/detail/recommend/info', // 商详推荐信息
  PRODUCT_GET_NEW_COMPANION: '/product/get_new_companion_module', // 新搭配数据
  CCC_FEED_BACK: '/ccc/communal/switch', // ccc负反馈配置
  RECOMMEND_COLLECT_SIMILAR: '/product/detail/recommend/collect_similar', // 商详收藏后弹出similar推荐
}


export default DETAIL_API_LOCATION
