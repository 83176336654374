import {
  ESTIMATED_DATA_TYPES,
  ESTIMATED_STYLE_TYPES
} from 'public/src/pages/product_app/store/modules/product_detail/utils/price.js'

export default {
  namespaced: true,
  state: {},
  getters: {
    /**
     * skc或sku纬度的价格信息
     */
    price_info_common(state, getters, rootState, rootGetters) {
      return rootGetters['productDetail/price/price_common_infos']?.price || {}
    },
    is_ab(state, getters) {
      const price_info = getters.price_info_common
      const { estimatedPriceInfo } = price_info || {}
      return (
        String(estimatedPriceInfo?.convertCouponPromotionInfo?.typeId) === '32'
      )
    },
    is_ab_coexist(state, getters) {
      // ab叠加到手价券
      const price_info = getters.price_info_common
      const { estimatedPriceInfo } = price_info || {}
      // 券后价类型 1 - 叠加AB价未满足门槛券后价，商详价格/计算过程展示的是满足门槛，计算过程中的不满足门槛多语言get this item for xxx over order xxx 需要展示 2-叠加AB价满足门槛
      return (
        estimatedPriceInfo?.estimatedPriceOverlayAbTestPrm
          ?.estimatedPriceType == 2
      )
    },
    is_hit_ab(state, getters) {
      return getters.is_ab || getters.is_ab_coexist
    },
    abt_e_price_cal(state, getters, rootState, rootGetters) {
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      return fs_abt?.EstimatedPrice?.p?.E_Price_Cal // "Have_Threshold", //Non_Threshold // None
    },
    is_show_estimated_style(state, getters) {
      // 命中了到手价实验，不一定返回了有效到手价
      const abtValue = getters.abt_e_price_cal
      return (
        ['Have_Threshold', 'Non_Threshold'].includes(abtValue) || getters.is_ab
      )
    },
    show_cal_info(state, getters) {
      const price_info = getters.price_info_common
      const { estimatedPriceCalculateProcess, npcsEstimatedPriceCalProcess } =
        price_info
      return !!(
        estimatedPriceCalculateProcess?.estimatedFinalPrice ||
        npcsEstimatedPriceCalProcess
      )
    },
    drawer_activity_list(state, getters, rootState, rootGetters) {
      const only_activity_data_list =
        rootGetters['productDetail/promotionEnter/only_activity_data_list']
      return only_activity_data_list?.filter(d => !!d.label)
    },
    show_drawer_promotion(state, getters, rootState, rootGetters) {
      const paymend_infos_list =
        rootGetters['productDetail/promotionEnter/paymend_infos_list']
      return !!(
        paymend_infos_list?.length || getters.drawer_activity_list?.length
      )
    },
    optimal_coupon(state, getters, rootState, rootGetters) {
      const estimated_info = rootGetters['productDetail/price/estimated_info']
      const coupon_list =
        rootGetters['productDetail/coupon/origin_coupon_list'] || []
      if (!estimated_info?.couponCode) return
      return coupon_list?.find(d => d.couponCode == estimated_info.couponCode)
    },
    /**
     * 是否N件到手价
     */
    is_npsc(state, getters) {
      const price_info = getters.price_info_common
      return !!price_info?.npcsEstimatedPriceCalProcess
    },
    /**
     * 是否满足门槛
     */
    is_satisfied_threshold(state, getters) {
      const price_info = getters.price_info_common
      return price_info?.estimatedPriceCalculateProcess?.isSatisfied == 1
    },
    is_show_add_more_bottom(state, getters, rootState, rootGetters) {
      const estimated_info = rootGetters['productDetail/price/estimated_info']
      return (
        ESTIMATED_DATA_TYPES.AB_OVERLAP_NO_SATISFY == estimated_info?.dataType
      )
    },
    is_show_add_more_top(state, getters, rootState, rootGetters) {
      const estimated_info = rootGetters['productDetail/price/estimated_info']
      return ESTIMATED_DATA_TYPES.NO_SATISFY == estimated_info?.dataType
    },
    is_show_add_more(state, getters) {
      const price_info = getters.price_info_common
      const { estimatedPriceCalculateProcess } = price_info

      return estimatedPriceCalculateProcess?.isShowAddMoreEntrance === '1'
    },
    is_show_discount(state, getters, rootState, rootGetters) {
      const estimated_info = rootGetters['productDetail/price/estimated_info']
      return [
        ESTIMATED_STYLE_TYPES.NEW,
        ESTIMATED_STYLE_TYPES.NEW_AND_NO_THRESHOLD_FLOOR
      ].includes(estimated_info?.styleType)
    },
    /**
     * 加车相关埋点数据复用
     */
    add_cart_expose_data(state, getters, rootState, rootGetters) {
      const estimated_info = rootGetters['productDetail/price/estimated_info']
      const threshold = estimated_info?.dataType
      return {
        threshold
      }
    },
    /**
     * 单件到手价价格标签
     */
    final_price_label(state, getters, rootState, rootGetters) {
      const price_info = getters.price_info_common
      const { estimatedPriceCalculateProcess } = price_info
      const fs_abt = rootGetters['productDetail/common/fs_abt']
      const isMiddleastNew = fs_abt?.middleast?.p?.middleast == 'new'

      if (isMiddleastNew) return estimatedPriceCalculateProcess?.middleEastEstimatedFinalPriceMultiLang
      return estimatedPriceCalculateProcess?.estimatedFinalPriceMultiLang
    }
  }
}
