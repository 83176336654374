import { SIMetric } from 'public/src/pages/common/monitor/index.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import DETAIL_API_LOCATION from '../api'
import schttp from 'public/src/services/schttp'
import qs from 'qs'
import { getNewCompanionConfig } from 'public/src/pages/product_app/store/modules/product_detail/utils/getNewCompanionConfig'
/**
 * 首屏相关无法区分模型的 actions
 */
export default {
  get_product_second_info: async ({
    commit,
    rootState,
    dispatch
  }) => {
    // 第二部分数据
    const [
      detailImage = [],
      topRanking = {},
    ] = await Promise.all([
      dispatch('get_product_image'),
      dispatch('get_top_ranking_info'),
    ])

    commit('productDetail/update_async', {
      ...rootState.productDetail.asyncModules,
      currentSkcImgInfo: { detailImage },
      topRanking,
    }, { root: true })
  },
  get_product_third_info: async ({
    commit,
    rootState,
    rootGetters,
    dispatch
  }) => {
    // 第三部分数据
    const goodsId = rootGetters['productDetail/common/goods_id'] || ''
    const cateId = rootGetters['productDetail/common/cat_id'] || ''
    const goodsSn = rootGetters['productDetail/common/goods_sn'] || ''

    const [
      recommendInfo = {},
    ] = await Promise.all([
      dispatch('get_recommend_info', { goodsId, cateId, goodsSn }),
    ])

    const { 
      getTheLookRelatedIdSeries = [],       // 亲子推荐
      shopRecProducts = [],                 // 店铺&品牌&系列商品推荐
      multiPropertyProducts = [],
      machingStyleProducts = []
    } = recommendInfo
    commit('productDetail/update_async', {
      ...rootState.productDetail.asyncModules,
      getTheLookRelatedIdSeries,
      shopRecProducts,
      multiPropertyProducts,
      machingStyleProducts
    }, { root: true })
    
    commit('common/update_third_info_ready', true)
  },
  get_product_image: ({ rootGetters }) => {
    const goods_id = rootGetters['productDetail/common/goods_id'] || ''
    if (!goods_id) return Promise.resolve([])
    // 获取细节图
    return new Promise(resolve => {
      schttp({
        url: DETAIL_API_LOCATION.PRODUCT_DETAIL_DETAIL_IMAGE,
        params: { goods_id },
        useBffApi: true
      }).then(({ info = {} }) => {
        const { goods_images = [] } = info
        return resolve(goods_images)
      })
    })
  },
  get_top_ranking_info: ({ state, rootGetters }) => {
    // 获取榜单信息
    return new Promise(resolve => {
      const MAIN_BFF_APOLLO = state.MAIN_BFF_APOLLO || {}
      if (!MAIN_BFF_APOLLO.v4) return resolve({})

      const goods_id = rootGetters['productDetail/common/goods_id'] || ''
      const fs_abt = rootGetters['productDetail/common/fs_abt'] || ''
      const cat_id = rootGetters['productDetail/common/cat_id'] || ''
      const goods_sn = rootGetters['productDetail/common/goods_sn'] || ''

      const ruleId = fs_abt?.ProductDetailBelowPolicyFloor?.p ||  ''
      if (!ruleId) return resolve({})

      const params = {
        abt_branch: '',
        belowModelFloor: '',
        goods_ids: goods_id,
        cate_ids: cat_id,
        goods_sns: goods_sn,
        belowPolicyFloor: ruleId
      }

      schttp({
        url: DETAIL_API_LOCATION.PRODUCT_RANKING_INFO,
        method: 'POST',
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: qs.stringify(params),
        useBffApi: true
      }).then(({ info = {} }) => {
        const { content = [] } = info || {}
        return resolve(content?.[0]?.content?.props?.items?.[0]?.rank_of_goods?.list?.[0] || {})
      })
    })
  },
  get_recommend_info: ({ rootState, rootGetters }, { cateId, goodsId, goodsSn }) => {
    // 获取榜单信息
    return new Promise(resolve => {

      const params = {
        goodsId,
        cateId,
        goodsSn,
      }

      // 店铺&品牌&系列推荐参数
      const {
        recBrandId = '',
        recSeriesId = '',
        storeCode = '',
        productRecommendByGroup = 0,
        secondSeriesId = '',
      } = rootState.productDetail.coldModules.recShopProductReqInfo || {}

      const {
        isBrandStore = '',                  // 是否品牌店铺 0-否 1-是
        isFashionLabel = '',                // 是否时尚标签 0-否 1-是 
        showChoiceStoreLabel = '',          // 是否品质店铺 0-否 1-是
      } = rootState.productDetail.coldModules.storeInfo || {}

      const signData = rootGetters['productDetail/DetailSignBoard/sign_data'] || {}
      const showTypeObj = {
        'store': '2',
        'series': '1',
        'brand': '0',
      }
      const storeRecParams = {
        brandId: recBrandId,
        seriesId: recSeriesId,
        storeCode,
        productRecommendByGroup,
        secondSeriesId,
        showType: showTypeObj[`${signData?.type}`] || '',
        isBrandStore,
        isFashionLabel,
        isChoiceStore: showChoiceStoreLabel,
        isAddCart: 0,
      }

      Object.assign(params, storeRecParams)

      schttp({
        url: DETAIL_API_LOCATION.PRODUCT_DETAIL_RECOMMEND_INFO,
        method: 'POST',
        data: params,
        useBffApi: true
      }).then(res => {
        resolve(res?.info || {})
      })
    })
  },
  get_new_companion_module: async ({ state, rootState, rootGetters, commit }, args) => {
    // 获取新搭配数据
    const MAIN_BFF_APOLLO = state.MAIN_BFF_APOLLO || {}
    if (!MAIN_BFF_APOLLO.v4) return 
    const { commonPageInfo } = rootState.productDetail.coldModules
    // 搭配套装数限制
    const companionLimitNum = getNewCompanionConfig(commonPageInfo.GOODS_DETAIL_NEW_COMPANION_LIMIT)
    const goods_id = rootGetters['productDetail/common/goods_id'] || ''
    const cat_id = rootGetters['productDetail/common/cat_id'] || ''
    const goods_sn = rootGetters['productDetail/common/goods_sn'] || ''
    const is_paid_user = rootGetters['productDetail/common/is_paid_user'] || ''
    const mall_code = rootGetters['productDetail/common/mall_code'] || ''
    const is_sold_out = rootGetters['productDetail/common/is_sold_out']
    const fs_abt = rootGetters['productDetail/common/fs_abt'] || ''
    const outfitAbt = fs_abt?.newoutfit?.p?.newoutfit ||  ''
    const reqSource = outfitAbt === 'addbagalone' ? 3 : (outfitAbt === 'addbaginymal' ? 4 : 1)  // gtld单独楼层 或 嵌入ymal 才需要

    const { 
      attribute = '', 
      isAddCart = '0',
      isEnterDetailCall = '1',
      isSelectCollocationGood,
      relationCatId,
      relationGoodsId,
      selectedGoodsIdSkus,
      updateGoodsId
    } = args || {}

    const params = {
      goodsId: goods_id,
      catId: cat_id,
      goodsSn: goods_sn,
      isPaidMember: !!is_paid_user ? '1' : '0',
      isMainGoodsSoldOut: !!is_sold_out ? '1' : '0',
      mallCode: mall_code,
      attribute,
      isAddCart,
      isEnterDetailCall,
      isSelectCollocationGood,
      relationCatId,
      relationGoodsId,
      selectedGoodsIdSkus,
      updateGoodsId,
      companionLimitNum,
      reqSource
    }

    const { info = {} } = await schttp({
      url: DETAIL_API_LOCATION.PRODUCT_GET_NEW_COMPANION,
      params: params,
      useBffApi: true
    })

    const { productNewCompanionList = [] } = info || {}

    // 每次请求需要上报是否有outfits数据
    if (!!productNewCompanionList.length) {
      daEventCenter.triggerNotice({
        daId: '1-6-1-237',
      })
    }

    // 更新新搭配数据
    commit('productDetail/update_new_companion_info', info || {}, { root: true })
    // 更新状态
    commit('common/update_second_info_ready', true)
  },
}
